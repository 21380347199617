<template>
  <div class="ui-to-contact">
    <p class="note">
      まずはお電話<br class="sp-kaigyo" />またはお問い合わせフォームから<br
        class="sp-kaigyo"
      />ご相談のご予約をお願いいたします。
    </p>
    <div class="contact-bar">
      <div class="web">
        <p class="small">Webでのお問い合わせ</p>
        <router-link to="/contact">
          <p class="main">お問い合わせフォーム</p></router-link
        >
      </div>
      <div class="tel">
        <p class="small">お電話でのお問い合わせ</p>
        <a href="tel:0527003238">
          <div class="cover">
            <p class="main">052-700-3238</p>
            <p class="sub">(平日：9時～17時)</p>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiToContact",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-to-contact {
  margin: 100px 0 50px 0;
  @media screen and (max-width: 767px){
    margin-top: 50px;
    margin-bottom: 0;
  }
  .note {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      padding: 0 20px;
      line-height: 1.5em;
      font-size: 17px;
    }
  }
  .contact-bar {
    display: flex;
    height: 150px;
    color: white;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: auto;
    }

    .small {
      font-size: 20px;
      margin-bottom: 15px;
    }
    a {
      text-decoration: none;
    }
    .main {
      font-size: 38px;
      text-decoration: none;
      color: white;
      @media screen and (min-width: 768px) and (max-width: 979px){
        font-size: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
    .web,
    .tel {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 155px;
      }
    }
    .web {
      background-color: var(--green);
      padding-right: 50px;
      align-items: flex-end;

      @media screen and (max-width: 767px) {
        padding: 0;
        align-items: center;
      }
      a {
        &:hover {
          filter: opacity(85%);
          cursor: pointer;
        }
      }
    }
    .tel {
      background-color: var(--blue);
      padding-left: 50px;
      align-items: flex-start;

      @media screen and (max-width: 767px) {
        padding: 0;
        align-items: center;
        padding: 30px 0;
      }
      .cover {
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) and (max-width: 979px){
          justify-content: center;
        }
        @media screen and (max-width: 767px) {
        flex-wrap: wrap
        }
        .main{
          @media screen and (max-width: 767px){
            font-size: 34px;
          }
        }

        .sub {
          font-size: 25px;
          color: white;
          margin-left: 5px;
          @media screen and (min-width: 768px) and (max-width: 979px){
            font-size: 14px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 751px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  text-decoration: none;
  }
}
</style>