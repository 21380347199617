<template>
  <div class="box3plus">
    <!-- <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    > -->
    <div class="box3plus-cover">
      <div class="row-1">
        <div class="theme">
          <p>{{ theme }}</p>
        </div>
        <div class="title">
          <p v-html="title"></p>
          <p class="industry" v-html="industry"></p>
        </div>
      </div>
      <div class="two-box-cover">
        <div class="before">
          <p class="sub">旧税理士への不満や悩み</p>
          <p v-html="before"></p>
        </div>
        <div class="kigou"><span>▶</span></div>
        <div class="after">
          <p class="sub">税理士の切り替え後</p>
          <p v-html="after"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBox3Plus",
  components: {
    // UiDetailButton,
  },
  props: {
    theme: String,
    title: String,
    industry: String,
    before: String,
    after: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box3plus-cover {
  margin: 50px 0 100px 0;
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
  span {
    color: var(--green);
    margin-left: 20px;
  }
  .row-1 {
    display: flex;
      @media screen and (max-width: 767px){
        display: block;
      }

    .theme {
      width: 100px;
      height: 100px;
      background-color: var(--green);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
           @media screen and (max-width: 767px){
             width: 100%;
             height: auto;
             padding: 10px 0;
           }
    }
    .title {
      width: calc(100% - 100px);
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-bottom: 2px dotted var(--green);
      font-size: 20px;
           @media screen and (max-width: 767px){
             flex-direction: column;
            //  align-items: flex-start;
             width: 100%;
           }
      p {
        word-break: break-all;
      }
      .industry {
        color: var(--green);
             @media screen and (max-width: 767px){
               font-size: 16px;
             }
      }
    }
  }
  .two-box-cover {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 25px 0;
    @media screen and (max-width: 767px){
      display: block;
    }
    .before,
    .after {
      min-height: 156px;
      width: 47%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media screen and (max-width: 767px){
        width: 100%;
      }
      /deep/span {
        color: var(--green);
      }
    }
    .sub {
      color: var(--green);
      margin-bottom: 10px;
    }
    .kigou {
      font-size: 30px;
      color: var(--green);
      min-width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 0;
        @media screen and (max-width: 767px){
           transform: rotate( 90deg );
           margin: 5px 0;
        }
      }
    }
  }
}
</style>