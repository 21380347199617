<template>
  <div class="ui-box2">
    <!-- <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    > -->
    <div class="box3-cover">
      <div class="row-1">
        <div class="theme">
          <p>{{ theme }}</p>
        </div>
        <div class="inner-box">
          <div class="title"><p v-html="title"></p></div>

          <div class="target">
            <p class="sub">{{sub}}</p>
            <p class="sub-detail" v-html="target"></p>
          </div>

          <div class="price">
            <p class="sub">金額</p>
            <p class="sub-detail" v-html="price"></p>
          </div>
        </div>
      </div>
      <div class="row-2">
        <span>内容</span>
        <p v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBox3",
  components: {
    // UiDetailButton,
  },
  props: {
    theme: String,
    title: String,
    target: String,
    price: String,
    description: String,
    sub: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box3-cover {
  margin: 50px 0;
  // border-top: 1px solid var(--green);
  // border-bottom: 1px solid var(--green);
  border: 1px solid var(--green);
  .row-1 {
    display: flex;
    max-height: 128px;
     @media screen and (max-width: 767px){
       max-height: initial;
     }
    .theme {
      width: 128px;
      height: 128px;
      background-color: var(--green);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
       @media screen and (max-width: 767px){
         width: 80px;
         height: auto;
       }
    }
    .inner-box {
      display: flex;
      width: calc(100% - 128px);
       @media screen and (max-width: 767px){
         flex-direction: column;
         width: calc(100% - 80px);
       }

      .title {
        color: var(--green);
        font-size: 18px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        width: 25%;
        border-bottom: 2px dotted var(--green);
        border-right: 1px solid var(--green);
         @media screen and (max-width: 767px){
           width: 100%;
           border-right: none;
           padding-left: 10px;
           min-height:66px;

         }
      }
      .target,
      .price {
        border-bottom: 2px dotted var(--green);
        padding: 5px 10px;

        .sub-detail {
          line-height: 1.6em;
           @media screen and (max-width: 1024px){
             font-size: 14px;
           }
        }
        .sub {
          color: var(--green);
        }
      }
      .target {
        width: 28%;
        border-right: 1px solid var(--green);
         @media screen and (max-width: 767px){
           width: 100%;
           border-right: none;

         }
      }
      .price {
        width: 47%;
         @media screen and (max-width: 767px){
           width: 100%;
         }
      }
    }
  }

  .row-2 {
    padding: 20px 30px;
     @media screen and (max-width: 767px){
       padding: 10px 30px;
     }

    p {
      padding-left: 20px;
    }
    span {
      color: var(--green);
    }
  }
}
</style>