<template>
  <div class="zeimukomon-price">
    <h3>個人事業主の顧問契約</h3>
    <p class="price-note">料金は全て消費税抜表示です。<br class="sp-kaigyo">別途消費税がかかります。</p>
    <table>
      <tr>
        <th class="top">対象の方</th>
        <td class="sub">事業の形態</td>
        <td>
          <ul>
            <li>事業所得のある方</li>
            <li>不動産所得のある方</li>
          </ul>
        </td>
        <td class="sub-3 kara"></td>
      </tr>
      <tr>
        <th rowspan="3">顧問契約に含まれるもの<br />（当事務所が行うこと）</th>
        <td class="sub">税務</td>
        <td>
          <ul>
            <li>所得税の確定申告書の作成</li>
            <li>収支内訳書</li>
            <li>青色申告決算書の作成</li>
            <li>上記申告関係書類の電子申告</li>
            <li>その他所得税の手続きに係る書類の作成、提出</li>
            <li>年末調整業務全般</li>
            <li>税務相談</li>
            <li>税務署対応</li>
            <li>税務署への同行</li>
            <li>簡易な納税のシミュレーション</li>
          </ul>
        </td>
        <td class="sub-3 kara"></td>
      </tr>
      <tr>
        <td class="sub">記帳</td>
        <td>
          <ul>
            <li>記帳代行</li>
            <li>試算表の作成</li>
          </ul>
        </td>
        <td class="sub-3 kara"></td>
      </tr>
      <tr>
        <td class="sub">その他</td>
        <td>
          <ul>
            <li>希望時の対面での打ち合わせ（定期訪問無し）</li>
            <li>お金全般の相談</li>
            <li>提携する専門家の紹介</li>
          </ul>
        </td>
        <td class="sub-3 kara"></td>
      </tr>
      <tr>
        <th rowspan="15">料金（全て税別）</th>
        <td class="sub" rowspan="8">
          月額顧問料<br /><span class="green">（目安になります）</span>
        </td>
        <td>年間売上が500万円以下</td>
        <td class="sub-3">12,000円</td>
      </tr>
      <tr>
        <td>年間売上が1,000万円以下</td>
        <td class="sub-3">15,000円</td>
      </tr>
      <tr>
        <td>年間売上が2,000万円以下</td>
        <td class="sub-3">20,000円</td>
      </tr>
      <tr>
        <td>年間売上が3,000万円以下</td>
        <td class="sub-3">25,000円</td>
      </tr>
      <tr>
        <td>年間売上が5,000万円以下</td>
        <td class="sub-3">30,000円</td>
      </tr>
      <tr>
        <td>年間売上が1億円以下</td>
        <td class="sub-3">35,000円</td>
      </tr>
      <tr>
        <td>年間売上が1億円超</td>
        <td class="sub-3">別途見積もり</td>
      </tr>
      <tr>
        <td>事業所得と不動産所得の両方がある場合</td>
        <td class="sub-3">別途見積もり</td>
      </tr>
      <tr>
        <td class="sub" rowspan="2">確定申告料金<br />（決算料）</td>
        <td>所得税の確定申告書の作成料</td>
        <td class="sub-3">月額顧問料の5か月分</td>
      </tr>
      <tr>
        <td>消費税の確定申告書の作成料</td>
        <td class="sub-3">月額顧問料の1か月分</td>
      </tr>
      <tr>
        <td class="sub">年末調整</td>
        <td>
          年末調整業務全般<br /><span class="small"
            >（源泉徴収票・給与支払報告書・支払調書・法定調書合計表の作成と提出）</span
          >
        </td>
        <td class="sub-3">
          基本料金 10,000円<br>対象者一人につき 3,000円
        </td>
      </tr>
      <tr>
        <td class="sub" rowspan="4">値引き</td>
        <td>資料を全てデータでいただく場合</td>
        <td class="sub-3">別途見積もりの上値引き</td>
      </tr>
      <tr>
        <td>クラウド会計の導入</td>
        <td class="sub-3">別途見積もりの上値引き</td>
      </tr>
      <tr>
        <td>自社で記帳を行う場合</td>
        <td class="sub-3">別途見積もりの上値引き</td>
      </tr>
      <tr>
        <td>その他申告・記帳の省力化にご協力いただける場合</td>
        <td class="sub-3">別途見積もりの上値引き</td>
      </tr>
      <tr>
        <th rowspan="12" class="gray">
          顧問契約に含まれないもの<br />（希望の方のみ）
        </th>
        <td rowspan="7" class="sub">税務</td>
      <td>青色事業専従者の追加</td>
      <td class="sub-3">月額顧問料を3,000円加算</td>
      </tr>
      <tr>
        <td>税務調査立ち合い</td>
        <td class="sub-3">1日につき 50,000円</td>
      </tr>
      <tr>
        <td>修正申告（確定申告のやり直し）</td>
        <td class="sub-3">
          月額顧問料の3か月分<span class="small">（所得税）</span
          ><br />月額顧問料の1か月分<span class="small">（消費税）</span
          ><br /><span class="small">※当事務所の事情によるものは無料</span>
        </td>
      </tr>
      <tr>
        <td>更正の請求（確定申告のやり直し）</td>
        <td class="sub-3">
          還付金額の30％<br /><span class="small"
            >※当事務所の事情によるものは無料</span
          >
        </td>
      </tr>
      <tr>
        <td>税理士法33条2項の書面添付</td>
        <td class="sub-3">月額顧問料の2か月分</td>
      </tr>
      <tr>
        <td>仮決算に基づく中間申告</td>
        <td class="sub-3">確定申告書の作成料と同額</td>
      </tr>
      <tr>
        <td>償却資産税の申告書の作成</td>
        <td class="sub-3">月額顧問料の1か月分</td>
      </tr>
      <tr>
        <td rowspan="2" class="sub">記帳</td>
        <td>試算表の早期作成</td>
        <td class="sub-3">該当月の月額顧問料を50％加算</td>
      </tr>
      <tr>
        <td>総勘定元帳の印刷・製本</td>
        <td class="sub-3">5,000円</td>
      </tr>
      <tr>
        <td rowspan="3" class="sub">その他</td>
        <td>定期訪問（1カ月に一度）</td>
        <td class="sub-3">月額10,000円</td>
      </tr>
      <tr>
        <td>銀行融資のサポート（試算表の作成、事業計画書の作成等）</td>
        <td class="sub-3">融資額の3％</td>
      </tr>
      <tr>
        <td>各種助成金・補助金申請</td>
        <td class="sub-3">別途見積もり</td>
      </tr>
      <tr>
         <th rowspan="3" class="blue">確定申告料金に含まれないもの<br>
（臨時の所得・控除等）</th>
         <td rowspan="2" class="sub">事業・不動産以外の所得</td>
         <td>土地・建物の譲渡所得</td>
         <td class="sub-3">通常の譲渡 譲渡所得金額の1％＋100,000円<br>
特例適用 別途追加料金</td>
      </tr>
      <tr>
        <td>ＦＸ・仮想通貨等による所得</td>
        <td class="sub-3">別途見積もり</td>
      </tr>
      <tr>
        <td class="sub">臨時の控除</td>
        <td>住宅借入金等（住宅ローン）特別控除を受けたい方の初年度</td>
        <td class="sub-3">50,000円</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "zeimukomon-price-proprietorship-price",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--green);
  font-size: 28px;
  margin-bottom: 20px;
  @media screen and (max-width: 767px){
    margin-bottom: 0;
    text-align: center;
  }
}
.price-note{
  font-size: 16px;
  text-align: right;
  @media screen and (max-width: 767px){
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 20px;
  }
}
table {
  width: 100%;
  @media screen and (max-width: 767px) {
    border-collapse: separate;
    border-spacing: 0px 15px;
  }
  tr {
    th,
    td {
      border: 1px solid black;
      padding: 10px;
      text-align: left;
      vertical-align: middle;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    th {
      background-color: #cbe9e9;
      width: 220px;
      text-align: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 50px;
        border: none;
        font-size: 20px;
      }
    }
    .top{
      @media screen and (max-width: 767px){
        margin-top: 10px;
      }
    }
    .gray {
      background-color: #dfdfdf;
    }
    .blue{
      background-color: #d2e1f5;
    }
    td {
      .green {
        color: var(--green);
      }
      .small {
        font-size: 14px;
      }
      ul {
        list-style-type: disc;
        li {
          margin-left: 25px;
        }
      }
    }
    .sub {
      width: 170px;
      text-align: center;
      @media screen and (max-width: 767px) {
        color: var(--green);
        width: 100%;
border: none;
padding-top: 20px;
      font-size: 20px;
      }
    }
    .sub-3 {
      width: 22%;
      @media screen and (max-width: 767px) {
        width: 100%;
        border-top: none;
      }
    }
    .kara {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
</style>
