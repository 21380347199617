<template>
  <div class="home-aboutchief">
    <UiHeading :h="'2'" :type="'A'" :text="'所長税理士紹介'" />
    <UiLine />
    <div class="about-us-box">
      <div class="left">
        <div class="square">
          <img
            :src="`${rootUrl}/img/yamada-yudai-top02.jpg`"
            alt="事務所について"
          />
        </div>
      </div>
      <div class="right">
        <div class="hello">
          <h4>あいさつ</h4>
          <p class="hello-text">
            当事務所のホームページをご覧いただきありがとうございます。愛知県稲沢市で税理士事務所を営んでいる税理士の山田雄大と申します。古いスタイルの税理士事務所と差別化し、若さとITを用いて小規模事業者の皆様のサポートをさせていただいております。人と話すことが大好きなので、自分の知識と経験を生かして皆様のサポートができる税理士という仕事は天職だと感じています。皆様にお会いできることを楽しみにしています。
          </p>
        </div>
        <div class="history">
          <h5>■経歴</h5>
          <p>
            1983年愛知県生まれ。立教大学経済学部を卒業後、名古屋国税局の法人課税部門に配属。国税局を退職後、税理士を志し、愛知学院大学大学院で租税法の研究に従事し修士号を取得。<br>3つの税理士事務所、税理士法人に勤務後、2015年に税理士登録。<br>同年名古屋市中村区にて山田雄大税理士事務所を開業。<br>2017年に愛知県稲沢市に事務所を移転。東海税理士会津島支部所属。<br>2021年に経営革新等支援機関に登録。<br>2023年 愛知県あま市に事務所を移転。
          </p>
        </div>
      </div>
    </div>
    <div class="button" style="padding: 30px 0">
      <UiDetailButton :type="'B'" :path="'company/'" :text="'詳細を見てみる'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";

export default {
  name: "HomeAboutchief",
  components: {
    UiHeading,
    UiLine,
    UiDetailButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.about-us-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 767px){
    flex-direction: column;
  }

  .left {
    width: 48%;
@media screen and (max-width: 767px){
  width: 100%;
}

    .square {
      background-color: var(--green);
      width: 100%;
      padding-top: 100%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }
  }
  .right {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 767px){
  width: 100%;
  margin-top: 30px;
}

    .hello {
      height: 50%;
      border-bottom: 1px solid var(--black);
 @media screen and (max-width: 767px){
   padding-bottom: 30px;
 }

      h4 {
        color: var(--green);
        font-size: 22px;
        font-weight: 500;
      }
      .hello-text{
        padding-bottom: 13px;
      }
    }
    .history {
      height: 50%;
      h5 {
        color: var(--green);
        font-weight: 500;
        font-size: 18px;
        margin-top: 7px;
      }
    }

    p {
      line-height: 2.3em;
    }
  }
}
.button {
  margin: 20px 0;
}
</style>
