<template>
  <div class="home-flow">
    <UiHeading :h="'2'" :type="'A'" :text="'ご相談の流れ'" />
    <UiLine />
<UiBar2 :type="'A'" :titleBox="true" :barNo="'1'" :barTitle="'お問い合わせフォームからお問い合わせ'" :barText="`<a href='/contact/'>お問い合わせフォーム</a>からお問い合わせをお願いします。`"/>
<UiBar2 :type="'A'" :titleBox="true" :barNo="'2'" :barTitle="'お問い合わせ内容のご記入'" :barText="'お問い合わせフォームの内容にそってご連絡をお願いします。'"/>
<UiBar2 :type="'A'" :titleBox="true" :barNo="'3'" :barTitle="'こちらから返信'" :barText="'お問い合わせ内容を確認し、こちらからご返信をいたします。<br>電話番号の記載がある場合は、お電話をおかけすることもあります。'"/>
<UiBar2 :type="'A'" :titleBox="true" :barNo="'4'" :barTitle="'初回面談（無料）の日時の決定'" :barText="'ご面談となった場合、日時と場所を決めます。初回面談は無料です。'"/>
<UiBar2 :type="'B'" :titleBox="true" :barNo="'5'" :barTitle="'面談'" :barText="'面談にて、実際にお会いしてご相談ください。'"/>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBar2 from "@/components/Ui/Bar2.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiBar2,
  },
  data() {
    return { };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
/deep/a{
  text-decoration: none;
  color: var(--green);
  &:hover{
    opacity: 0.8;
  }
}

</style>
