<template>
  <div class="table2">
    <table>
      <tr>
        <th class="title" colspan="2">{{ tableTitle }}</th>
      </tr>
      <tr>
        <th class="gray">対象の方・<br class="pc-kaigyo" />お仕事の詳細</th>
        <td v-html="target" class="target"></td>
      </tr>
      <tr>
        <th class="gray">料金（全て税別）</th>
        <td class="condition" v-html="price"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Uitable",
  components: {},
  props: {
    tableTitle: String,
    target: String,
    price: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 50px;
  border-collapse: collapse;
  width: 100%;
  tr {
    th,
    td {
      border: var(--green) solid 1px;
      width: 100%;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    td,
    .target {
      padding: 10px 0 10px 15px;
      width: 78%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .gray {
    background-color: rgb(223, 223, 223);
    color: var(--green);
    text-align: center;
    vertical-align: middle;
    width: 100px;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .title {
    background-color: var(--green);
    width: 100%;
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .target {
    padding: 20px;
  }
  .condition {
    @media screen and (max-width: 767px) {
      // text-align: center;
    }
  }
}
</style>