<template>
  <div class="ctrl-login">

    <div class="wrap">
    <CtrlChangePassword />
    </div>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlChangePassword from "@/components/Ctrl/ChangePassword.vue";
export default {
  name: 'ctrl-login',
  components: {
    CtrlChangePassword,
  },
data() {
    return {};
  },
  computed: {
  ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
