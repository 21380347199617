<template>
  <div class="zeimukomon-troubles">
    <UiHeading :h="'2'" :type="'A'" :text="'サポート内容'" />
    <UiLine />

    <UiBox3
      v-for="boxData in boxList"
      :key="boxData.id"
      :theme="boxData.theme"
      :title="boxData.title"
      :target="boxData.target"
      :price="boxData.price"
      :sub="boxData.sub"
      :description="boxData.description"
    />

    <UiHeading
      :h="'2'"
      :type="'A'"
      :text="'料金体系・顧問料について'"
      class="space-200"
    />
    <UiLine />

    <p>
      山田雄大税理士事務所では、一律の顧問料という料金体系を取っていません。
      （公開している料金表は、参考になるようお客様の判断の目安を示すものです）
      その理由は、年商以外にも事業内容・経理の知識・ITやDCの知識の有無といった個別の事情や、それぞれのご希望があるからです。<br />しっかりとヒアリングを行い、最適な料金プランをご提案いたします。<br />最終的な顧問料は料金表とは異なるものになる可能性がありますのでご了承ください。このように、それぞれの事業のステージに合った、納得できる料金プランは事業をされているお客様だけでなく、開業して間もない事業主様にとっても必須であると考えています。
    </p>

    <h3>■料金の算出方法</h3>
    <div class="cover">
      <div class="block1">
        <p>毎月発生する顧問料</p>
        <p class="note">※税務顧問＋記帳代行</p>
      </div>
      <div class="symbol"><p>+</p></div>
      <div class="block1">
        <p>確定申告料</p>
        <p class="note">（所得税・消費税・法人税）</p>
      </div>
      <div class="symbol"><p>+</p></div>
      <div class="block2">
        <p>必要に応じて<br class="sp-kaigyo" />都度発生するサポート料</p>
        <p class="note">（年末調整等）</p>
      </div>
      <div class="symbol"><p>=</p></div>
      <div class="block3"><p>料金</p></div>
    </div>

    <p class="kome">
      上記のとおり、毎月の顧問料と確定申告料、必要に応じて都度発生するサポート料という料金の算出方法となっています。
    </p>

    <h3>■山田雄大税理士事務所のクライアントの顧問料実績</h3>
      <img
        :src="`${rootUrl}/img/yamada-yudai-zeimukomon001.jpg`"
        alt="顧問料実績"
        class="zisseki pc-only"
      />
      <img
        :src="`${rootUrl}/img/yamada-yudai-zeimukomon-sp001.jpg`"
        alt="顧問料実績"
        class="zisseki sp-only"
      />
    <img
      :src="`${rootUrl}/img/yamada-yudai-zeimukomon002.jpg`"
      alt="顧問料実績２"
      class="pc-only"
    />
    <img
      :src="`${rootUrl}/img/yamada-yudai-zeimukomon-sp002.jpg`"
      alt="顧問料実績２"
      class="sp-only"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBox3 from "@/components/Ui/Box3.vue";

export default {
  name: "zeimukomon-support",
  components: {
    UiHeading,
    UiLine,
    UiBox3,
  },
  data() {
    return {
      boxList: [
        {
          id: 1,
          theme: "案件１",
          title: "法人の顧問契約",
          sub: "法人の種類",
          target: "株式会社・合同会社・<br>有限会社・その他各種法人",
          price:
            "月額顧問料　20,000円～<br>確定申告書の作成料:<br>月額顧問料の5か月分（100,000円～）",
          description:
            "・法人税の確定申告書の作成・提出（決算書・勘定科目内訳書・法人事業概況説明書等を含む）<br>・記帳代行<br>・その他税務手続きに係る書類の作成・提出<br>・税務相談",
        },
        {
          id: 2,
          theme: "案件２",
          title: "個人事業主の顧問契約",
          sub: "対象",
          target: "個人事業主<br>（事業所得・不動産所得）",
          price:
            "月額顧問料　15,000円～<br>確定申告書の作成料　月額顧問料の5か月分（75,000円～）<br>その他の所得・住宅ローン控除等は別途",
          description:
            "・所得税の確定申告書の作成・提出<br>・記帳代行<br>・その他税務手続きに係る書類の作成<br>・提出<br>・税務相談",
        },
        {
          id: 3,
          theme: "案件３",
          title: "消費税の確定申告書の作成",
          sub: "対象",
          target: "消費税の納税義務者<br>（適格請求書発行事業者を含む）",
          price:
            "50,000円～（原則課税・簡易課税とも同額）<br>顧問契約を結んだ場合　月額顧問料の１か月分",
          description: "法人、個人事業主の消費税の確定申告書の作成・提出",
        },
        {
          id: 4,
          theme: "案件４",
          title: "年末調整",
          sub: "対象",
          target: "従業員を雇用している<br>法人・個人事業主",
          price: "基本料金　10,000円<br>対象者一人につき　3,000円",
          description:
            "年末調整業務全般（源泉徴収票・給与支払報告書・支払調書・法定調書合計表の作成と提出）",
        },
        {
          id: 5,
          theme: "案件５",
          title: "税務調査",
          sub: "対象",
          target: "税務調査において<br>税理士の立ち合いを希望する方",
          price: "1日につき　50,000円",
          description: "税務調査の立ち合い",
        },
        {
          id: 6,
          theme: "案件６",
          title: "修正申告<br><span>（確定申告のやり直し）</span>",
          sub: "対象",
          target: "税務調査で指摘を受けた事業者",
          price:
            "顧問契約でない方はお受けしておりません。<br>顧問契約をされた場合の料金は料金表を参照ください。",
          description:
            "申告済みの申告書についての修正申告（法人税・所得税・消費税）",
        },
        {
          id: 7,
          theme: "案件７",
          title: "更正の請求<br><span>（確定申告のやり直し）</span>",
          sub: "対象",
          target: "資料の提出漏れなどにより<br>税金を払いすぎていた事業者",
          price:
            "顧問契約でない方はお受けしておりません。<br>顧問契約をされた場合の料金は料金表を参照ください。",
          description:
            "申告済みの申告書についての更正の請求（法人税・所得税・消費税）",
        },
        {
          id: 8,
          theme: "案件８",
          title: "仮決算に基づく中間申告",
          sub: "対象",
          target: "仮決算を希望する方",
          price:
            "顧問契約でない方はお受けしておりません。<br>顧問契約をされた場合の料金は料金表を参照ください。",
          description: "法人税・消費税の仮決算に基づく中間申告書の作成・提出",
        },
        {
          id: 9,
          theme: "案件９",
          title: "償却資産申告",
          sub: "対象",
          target: "不動産以外の事業用資産を保有している事業者",
          price: "30,000円～<br>顧問契約をされた場合　月額顧問料の１か月分",
          description: "償却資産税の申告書の作成・提出",
        },
        {
          id: 10,
          theme: "案件10",
          title: "贈与税申告",
          sub: "対象",
          target: "他人から金銭や不動産等の<br>贈与を受けた方",
          price: "スポットの料金表を参照ください",
          description: "贈与税の申告書の作成・提出",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--green);
  font-size: 24px;
  margin-top: 100px;
  margin-bottom: 20px;
}
.cover {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 979px) {
    flex-direction: column;
  }
  .note {
    font-size: 16px;
  }
  .block1,
  .block2 {
    border: 1px solid var(--green);
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    font-size: 20px;
    @media screen and (max-width: 979px) {
      width: 100%;
    }
  }
  .block2 {
    min-width: 355px;
    @media screen and (max-width: 979px) {
      width: 100% !important;
      min-width: initial;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
  }
  .block3 {
    background-color: var(--green);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    @media screen and (max-width: 979px) {
      width: 100%;
      padding: 30px 0;
      font-size: 20px;
    }
  }
  .symbol {
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--green);

    @media screen and (max-width: 979px) {
      margin: 20px 0;
      transform: rotate(-90deg);
    }
  }
}
.kome {
  margin-top: 30px;
}
.zisseki {
  margin: 50px 0;
}
.title {
  color: var(--green);
  font-size: 20px;
  margin-bottom: 10px;
}
.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial;
  }
}
</style>
