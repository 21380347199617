<template>
  <div class="ui-box">
    <!-- <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    > -->
    <div class="cover">
      <img
        :src="`${rootUrl}/img/${imgpath}`"
        :alt="{ imgName }"
        class="photo"
      />
      <h3 v-html="subTitle"></h3>
      <p v-html="text"></p>
      <div class="category-cover">
        <div class="cat">{{ category }}</div>
        <div class="cat-text" v-html="categoryText"></div>
      </div>
    </div>
    <UiDetailButton
      :type="'B'"
      :path="`${buttonData.path}`"
      :text="'詳細を見てみる'"
    />
  </div>

  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBox",
  components: {
    UiDetailButton,
  },
  props: {
    imgpath: String,
    imgName: String,
    subTitle: String,
    text: String,
    category: String,
    categoryText: String,
    buttonData: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-box {
  padding: 30px 0;
  .cover {
    display: flex;
    flex-direction: column;
    .photo {
      width: 100%;
    }
    h3 {
      color: var(--green);
      font-weight: 500;
      font-size: 22px;
      text-align: center;
      margin: 20px 0;
    }
    p {
      word-wrap: break-word;
    }
    .category-cover {
      display: flex;
      // justify-content: space-between;
      align-items: flex-start;
      padding: 20px 0;
      .cat {
        // padding: 20px 0;
        width: 70px;
        height: 70px;
        background-color: var(--green);
        color: white;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
        margin-top: 10px;
        @media screen and (max-width: 767px) {
          width: 60px;
          height: 60px;
        }
      }
      .cat-text {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
      ul {
        color: var(--black);
        list-style-type: disc;

          }
      }
    }
  }
}
</style>