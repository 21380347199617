<template>
  <div class="ui-two-box">
    <!-- <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    > -->
    <div class="two-box-cover">
<div class="before"><p>{{before}}</p></div>
<div class="kigou"><span>▶</span></div>
<div class="after"><p v-html="after"></p></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBox3",
  components: {
    // UiDetailButton,
  },
  props: {
    before: String,
    after: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.two-box-cover{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 25px 0;
       @media screen and (max-width: 767px){
         flex-direction: column;
         margin: 50px 0;
       }
  .before,.after{
    min-height: 156px;
    width: 47%;
    font-size: 20px;
    padding: 30px;
        display: flex;
    align-items: center;
justify-content: center;
     @media screen and (max-width: 767px){
       width: 100%;
     }
  }
  .before{
    background-color: #DFDFDF;
    }
  .after{
    background-color: #CBE9E9;
  }
  .kigou{
    font-size:30px;
    color: var(--green);
    min-width: 60px;
    display: flex;
    align-items: center;
justify-content: center;
     @media screen and (max-width: 767px){
        transform: rotate( 90deg );
        margin: 5px 0;
     }
    
}}
</style>