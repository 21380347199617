<template>
  <div class="ctrl-post space-100 wrap">
    <div>
      <el-row>
        <el-col><h1>お役立ち情報投稿</h1></el-col>
      </el-row>

      <el-row type="flex" justify="space-between" class="top-bar">
        <el-col :span="4"
          ><el-button type="warning" plain @click="back"
            >戻る</el-button
          ></el-col
        >
        <div class="">
        <el-col :span="12"
          ><router-link :to="'/ctrl/post-detail/0'"
            ><el-button type="danger" plain style="width:150px;"
              >新規作成</el-button
            ></router-link
          ></el-col
        >
        <el-col :span="10" 
          ><router-link :to="'/category/blog/'" target="_blank"
            >
            <el-button type="primary" plain style="width:150px;margin-left:10px;"
              >プレビュー</el-button
            ></router-link
          ></el-col
        >
</div>
      </el-row>
      <el-row>
        <el-col>
          <table class="typeA stripe">
            <thead>
              <tr>
                <th class="title">TITLE</th>
                <th class="category">CATEGORY</th>
                <!-- <th class="sort">SORT</th> -->
                <th class="edit" colspan="2">EDIT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="postData in postList" :key="postData.postId">
                <td>
                  {{ postData.title }}
                </td>
                <td class="category">
                  <!-- {{ postData.categoryId }} -->
                  {{getCategoryName(postData.categoryId)}}
                </td>
                <!-- <td class="sort">
                  {{ postData.sort }}
                </td> -->
                <td class="btn edit">
                  <router-link :to="`/ctrl/post-detail/${postData.postId}`">
                    <el-button type="success" plain>編集</el-button>
                  </router-link>
                </td>
                <td class="btn edit">
                  <el-button plain @click="deletePost(postData.postId)"
                    >削除</el-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrlpost",
  components: {},
  props: {
    postId: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "postList", "categoryList"]),
  },
  watch: {
  },
  methods: {
    getCategoryName(categoryId){
      let categoryData = this.$store.getters["categoryData"](categoryId);
    return categoryData.catName;
    },
    back() {
      this.$router.go(-1);
    },
    deletePost(postId) {
      if (confirm("本当に削除してもいいですか？")) {
        let params = new URLSearchParams();
        params.append("post_id", postId);
        this.$axios
          .post(`${this.rootUrl}/api/del-post.php`, params)
          .then((response) => {
            console.log(response);
            // alert(response.data.status);
            if (response.data.status === 200) {
              this.$store.dispatch("setPostList");
              alert(response.data.message);
              // console.log('ココok')
              return;
            } else if (
              response.data.status === 401 ||
              response.data.status === 400
            ) {
              alert(response.data.message);
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
.el-col {
  text-align: left;
}
.top-bar {
  margin: 20px;
}
table.typeA {
  //一覧などに使うテーブル。ヘッダー有色。
  width: 100%;
  border-collapse: collapse;
  color: #4d4d4d;
  margin-bottom: 100px;
  tr {
    border-top: solid 1px #f0f0f0;
    border-bottom: solid 1px #f0f0f0;
    th,
    td {
      padding: 10px 20px;
    }
  }
  thead {
    tr {
      background: #f0f0f0;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      th {
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        &.btn {
          width: 80px;
          text-align: center;
          padding: 10px 0;
        }
      }
    }
  }
}
table.stripe {
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: #f8f8f8;
      }
    }
  }
}
.title {
  width: 50%;
}
.category {
  width: 25%;
  text-align: center !important;
}
.sort {
  width: 15%;
  text-align: center !important;
}
.edit {
  width: 20%;
  text-align: center !important;
}
</style>