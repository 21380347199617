<template>
  <div class="company-vision-reason">
   
    <UiBar
      :barNo="'05'"
      :type="'A'"
      :barText="'開業、設立、税理士変更などを全面的にバックアップし、本業に集中できるサポート力'"
      class="space-100"
    />
        <div class="description-area wrap">
          <p>「開業してすぐに本業に集中して売上を上げてもらいたいから」これが、当事務所が開業や設立のサポートに力を入れている目的です。開業や会社設立にともなう時間やストレスを削減しましょう。必要な書類の提出、会社設立の手続は丸投げしてください。開業時の方針、悩みをすべて解決します。当事務所は万全のサポート体制を整え、皆様をお待ちしております。</p>
          <h3 class="blue">開業時から当事務所と付きあうべき理由</h3>
          <p>開業のサポートを売りにしている税理士は数多くいますが、ほとんどの税理士事務所はお客様が開業届の作成や会社設立手続きの代行、その後の税理士事務所との付き合い方の説明のみ行って終わりです。</p>
          <p>当事務所は開業や法人設立の全面的なサポートをしています。全面的とは、「皆様が自分でやると決めたこと」以外全てです。
ここが他の税理士事務所との違いです。</p><p>開業される際にはやらなければならないこととはどんなものがあるでしょうか。公的機関への手続き、経理の準備、事務所の場所の選定、請求書や契約書等の書類の準備、銀行口座開設やクレジットカードの作成、パソコンや机といったの備品や機器の導入はもちろんのこと、中にはホームページや名刺の作成、銀行融資の交渉、広告媒体の選定といったことをされる方もいらっしゃるでしょう。この全てを皆さまがミスなく行わなければなりません。</p>
          <p>私は今まで多くのお客様の開業のサポートをしてきました。開業を考えるだけあってモチベーションも意識も高い方ばかりですから、自分で調べ、勉強して準備を進めてらっしゃいます。ただその中で、
  <ul class="none">
          <li>その経理のやり方では無茶苦茶になる…</li>
          <li>全く業務のスタイルに合っていない…</li>
          <li>無駄なお金を使っている…</li>
          <li>騙されている…</li>
  </ul>
    こんな姿を何度も見てきました。<br>
    ご自身で完璧な開業準備をすることは不可能です。なぜなら知識も時間も開業時には圧倒的に不足しているからです。</p>

    <p>その中で税理士が一番怖いと感じているのは公的機関（役所）への手続きと経理です。

    <ul class="none">
    <li>税務署に出す書類を一枚だけ提出し忘れていた…</li>
    <li>資本金の額や事業年度を適当に決めてしまった…</li>
    <li>経理が適当なので営業成績がよくわからない…</li>
    </ul>
    変なパソコンを買ってしまった、効果のないホームページの契約をしてしまった、といったこととは違い、これらは間違えると経営そのものに影響を及ぼしかねません。例えば、「個人事業主の青色申告の承認申請書」という書類がありますが、これ一枚を出すか出さないかで税金等が少なくとも15万円程度変わります。紙を1枚出すか、出さないか、だけの違いです。</p>

      <p>また、経理とは経営の土台になるものです。経理がいい加減ということはお金の管理がいい加減ということです。経営の分析はおろか、事業の方針など決められるわけがありません。経理がいい加減だと、いい加減な確定申告しかできません。社会的な信用が得られるはずがありません。</p>
      <p>こういったリスクは税理士事務所と開業時から関われば防ぐことができます。当事務所は顧問契約いただいたお客様の開業時の公的機関への手続きに関わる書類の作成とお客さまに合った経理の方法を無料で提案しています。また、開業時の相談やサポートを何度でも無料で行っています。
      <ul class="none">
        <li>「どんなパソコンを買ったらいいですか？」</li>
        <li>「おすすめの銀行はどこですか？」</li>
      </ul>
      こんな質問もどんどんしてください。</p>
      <p>私は多くのお客様の開業や法人設立のサポートをしてきました。自身も税理士事務所とコンサルティング会社の開業を経験しています。また、当事務所は経営のバックグラウンドをサポートする国の認定支援機関である経営革新等支援機関です。本業に集中できる完璧な体制作りをサポートすることをお約束します。</p>

      <h3 class="blue">本業に集中することの大切さ</h3>
      <p>
      私自身、税理士事務所を開業した時は相当苦労しました。意気込みだけはあったもののやらなければならないこと（と思い込んでいたこと）があまりに多く、日々苦悩の連続でした。必要もないのに名古屋の一等地に事務所を構えたり、ホームページを自作したものの、全く問い合わせがなく、電話営業の業者に全く効果のない高いリニューアル代を払ったり、安いパソコンを買ってすぐに買い替えたり、名刺を自作して何度も作り直したり…今考えたら恥ずかしいかぎりです。</p>
      <p>
      正しい知識がないのにそれを自覚せず、自分の判断が正しいと思い込んでいたことが原因です。本当に集中してやらなければならないことは、税理士として売上を上げることでした。断言できることは、開業したら本業の売上を上げることに全精力を傾けなければなりません。そして売上を上げるためには羽ばたける土台がなくてはなりません。</p>
      <p>
     税理士は経営コンサルタントではありませんが、売上を上げるための環境を作ることはできます。当事務所は開業の際のストレスを極力減らし、土台作りをお手伝いします。皆様にしかできないことは何か考えてください。無駄な作業に時間を取らないでください。経理や税金対策は税理士に任せてください。</p>

      <h3 class="blue">経理を自分でやることのリスクとは</h3>
      <p>
        開業時は、せっかく独立したのだから経理を自分でやってみよう！と考える方が多いです。とても素晴らしい姿勢や考え方だと思います。経理とはお金の管理と経営の分析には欠かせないものです。少なくとも経営をする上ではある程度理解しなければならないものですから、自分でチャレンジすることで理解が早まるでしょう。
      </p>
      <p>
        最近は安価で「簡単に経理ができます！」、「決算書が作れます！」といった謳い文句の経理ソフトやアプリが数多く発売されています。実際、使いこなせばとても便利なものです。
        </p>
      <p>
        ただ、実はこのような謳い文句には「簿記を理解している人は」という前提が抜け落ちています。簿記というのは経理のルールのことで、世界中の会社がこのルールに基づいて経理をしています。いくら安いソフトやアプリを使っていても簿記のルールを理解することなく正しい経理はすることはできません。
        </p>
      <p>当事務所にも「経理はクラウドソフトで自分でちゃんとやっているので、確定申告書だけ作ってください」というご相談があります。さて、中身を見てみると、ぐちゃぐちゃで経理の体をなしていない…。結局かなりの部分をやり直さなくてはならず、その分料金をいただかないとできません、ということがよくあるのです。ソフト代を払い、間違った経理を労力と時間を使い律儀に続けていたという結果です。簿記の知識がないのにできると思い込んでいたことが原因です。</p>

      <p>さて、この問題に対する当事務所の提案は、
        <ul>
          <li>開業時からできもしない経理に無駄な労力を使い、間違ったお金の使い方をやめる。</li>
          <li>経理を専門家に任せ、正しい経営の成績表（試算表や決算書）を作ってもらう。</li>
          <li>その成績表からお金の管理と経営の分析に必要な事項だけピックアップして理解する。</li>
        です。
        </ul></p>
      <p>
        独立したらは何でも自分でやってみたくなることでしょう。ただ、経理については一定の知識を得るまでは専門家に依頼することをおすすめします。業務に致命的な影響を及ぼす可能性が高いからです。売上、経費、利益といった情報は正しい経理を行って初めてわかることです。ご自身が頭の中で思い描いていたこれらの数字と実際に正しい経理をした結果の数字はまず間違いなくズレます。
      </p>
      <p>
       開業時から経理の重要性を理解し、正しい数字を把握することで、今後の方針や対策も現実的な判断から正確に立てることができ、必ず大きく成長します。皆様の状況に合わせた正しい経理を行う方法を提案しますので是非ご相談ください。
      </p>

      <h3 class="blue">専門家との提携</h3>
      <p>
        当事務所は各専門家と提携し、税理士事務所で対応できない分野のサポートをしています。<br>
会社（法人）を設立する際には司法書士に設立の登記を依頼する必要があります。例えば飲食業をするのであれば行政書士に依頼して許可を取らなければなりません。
      </p>
      <p>
        当事務所は各種専門家と提携しております。
        <ul class="blue">
          <li>会社設立のプロである司法書士（会社設立の登記は司法書士しかできません）</li>
          <li>各種許認可や契約書作成のプロである行政書士</li>
          <li>社会保険や給与計算のプロである社会保険労務士</li>
          <li>助成金や補助金のプロである中小企業診断士</li>
          <li>家計やライフプランニングのプロであるファイナンシャルプランナー（1級）</li>
          <li>ＷＥＢマーケティングやホームページ作成の専門家</li>
          <li>パソコンなどのIT機器の導入のプロ</li>
          <li>融資の際の金融機関</li>
        </ul>
      </p>

      <p>
        多くの専門家と付きあう中で、実績があり信頼できる方のみ提携しています。これらの専門家と提携することで皆様の開業に必要なサポートを漏れなく提供することができます。開業される皆さまは予算や業種も違えば年齢も得意分野も違います。提携している専門家と協力し、トータルでサポートすることをお約束します。
      </p>

    </div>
      <UiMiniToTopButton :type="'C'" :text="'上に戻る'" />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
