<template>
  <div class="ui-bar-prus">

    <div class="overall" @click="openContent = !openContent" >
      <UiBar0 :barNo="barNo" :type="type" :barText="barText" :openContent="openContent" id="top"/>
    </div>
    <transition>
      <div class="wrap" v-show="openContent">
        <p v-html="barAnswer"></p>
        <div class="cover">
          <div class="button" @click="openContent = !openContent"
          v-scroll-to="'#top'"
          >
            <p>閉じる</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar0 from "@/components/Ui/Bar0.vue";
export default {
  name: "UiBarPlus",
  components: {
    UiBar0,
  },
  props: {
    barNo: String,
    barText: String,
    type: String,
    barAnswer: String,
  },
  data() {
    return {
      openContent: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

/deep/h3{
font-weight: 500;
font-size: 20px;
margin: 40px 0 10px 0 ;
color: var(--green);
}

// .outer {
//   margin: 15px 0;
//   height: 120px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   .bar-box {
//     display: flex;

//     .bar-no {
//       width: 100px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border-right: 1px solid white;
//       border-left: 1px solid white;
//       p {
//         font-size: 34px;
//         color: white;
//       }
//     }
//     .bar-text {
//       font-size: 26px;
//       color: white;
//       padding-left: 30px;
//     }
//   }
// }
.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 50px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 50px;
    border: 2px solid var(--green);
    color: var(--green);

    p {
      font-weight: 400;
    }

    &:hover {
      background: var(--green);
      color: white;
    }
  }
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s
}
.v-enter-to {
  opacity: 1;
}
.v-leave {
  opacity: 2;
}
.v-leave-active {
  transition: opacity 0.5s
}
.v-leave-to {
  opacity: 0;
}


</style>