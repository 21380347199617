<template>
  <div id="app">
    <Header />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");
#app {
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic,
    "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 16px;
  color: #2c3e50;
}

:root {
  --black: rgb(62, 58, 57);
  --green: rgb(27, 160, 159);
  --gray: rgb(166, 166, 166);
  --blue: rgb(0, 99, 157);
  --line: rgb(6, 199, 85);
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1150px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sp-kaigyo {
  display: none;

  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.pc-kaigyo {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.body-top {
  padding-top: 70px;
  @media (max-width: 767px){
    padding-top: 60px;
  }
}

h1,
h2 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}
.blue-area {
  padding: 100px 0;
}

.space-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}
.space-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}


.description-area {
  h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 40px;
  }
  .bottom-0 {
    margin-bottom: 0;
  }
  .blue {
    color: var(--blue);
  }
  .green {
    color: var(--green);
  }

  ol {
    margin-bottom: 40px;
    li {
      list-style-type: decimal;
      margin-left: 20px;
    }
  }
  ul {
    margin-bottom: 40px;
    li {
      list-style: disc;
      margin-left: 20px;
    }
  }
  .none {
    margin-top: 30px;
    li {
      list-style-type: none;
      margin-left: 0px;
    }
  }
}
</style>
