<template>
  <div class="heading2">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeB2: type === 'B2',
        typeC: type === 'C',
      }"
    >
            <img
        :src="`${rootUrl}/img/fukidashi-left.png`"
        alt="ふきだし"
      />
      <h1 v-if="h === '1'" v-html="text"></h1>
      <h2 v-else-if="h === '2'" v-html="text"></h2>
      <h3 v-else-if="h === '3'" v-html="text"></h3>
      <h4 v-else-if="h === '4'" v-html="text"></h4>
      <h5 v-else-if="h === '5'" v-html="text"></h5>
            <img
        :src="`${rootUrl}/img/fukidashi-right.png`"
        alt="ふきだし"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "heading2",
  components: {},
  props: {
    text: String,
    h: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h1,h2 {
  font-size: 32px;
}
h3{
  font-size: 24px;
  font-weight: bold;
}

.heading2{
  .typeA {
  display: flex;
  justify-content: space-evenly;
img{
  filter: grayscale(100%);
  width: 30px;
}
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
    }
}

</style>
