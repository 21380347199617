<template>
  <div class="zeirishi-henko-question">
    <UiHeading :h="'2'" :type="'A'" :text="`税理士変更について<br class='sp-kaigyo'>よくある質問`" />
    <UiLine />


    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'今の税理士に知られることなく相談はできますか？'"
       :barAnswer="'もちろん可能です。当事務所に相談されたことはわからないようにいたします。今の税理士に問い合わせるといったことは一切いたしません。'"
      />
      <div class="answer">
  <!-- <p><button v-on:click="show=!show">切り替え</button></p> -->

</div>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'どのタイミングで税理士の変更をしたらいいですか？'"
       :barAnswer="'どのタイミングでも構いません。わかりやすいのは、新しい事業年度が始まるタイミングからです。ただ、期の途中でも、途中までの資料を前の税理士に依頼し、引き継ぐことができれば問題ありません。お客様が「今」と思われたタイミングが変更のタイミングであると考えます。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'今までの慣れたやり方を変えることが不安です。'"
      :barAnswer="'お客様の慣れたやり方をそのまま続けてもらうこともあります。一番大切なのはお客様がやりやすい形を作ることですから、状況に合わせてベストの提案をいたします。やれないことや負担がかかることを強制することは決してありませんのでご安心ください。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'期の途中で税理士変更した場合の顧問料はどうなりますか？'"
      :barAnswer="'変更し、前任の税理士が作成した資料がある場合は、既に経過してしまった月数×顧問料の半額をいただいています。前任の税理士が作成した資料がない場合は別途相談になります。'"/>
       <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'今の税理士を変えるつもりはないけど、問題点だけ教えてもらうことはできますか？'"
      :barAnswer="'現状、税理士の変更を考えている方のみの対応とさせていただいております。'"/>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarPlus from "@/components/Ui/BarPlus.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiBarPlus,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>



</style>
