<template>
  <div class="home-access">
    <UiHeading :h="'2'" :type="'A'" :text="'アクセス'" />
    <UiLine />
    <div class="wrap">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d407.6748940827587!2d136.7972574!3d35.1716028!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60039e6f95513377%3A0x81debc913913406b!2z44CSNDk3LTAwMTEg5oSb55-l55yM44GC44G-5biC5LiD5a6d55S65a6J5p2-6KW_6auY5b6h5aCC77yS77yV77yQ77yT!5e0!3m2!1sja!2sjp!4v1688537509914!5m2!1sja!2sjp"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
      

      <div class="yamada-tax">
        <img
          :src="`${rootUrl}/img/yamada-yudai-logo01.png`"
          alt="山田雄大税理士事務所"
          class="photo"
        />
        <p class="adress">〒497-0011 愛知県あま市七宝町安松西高御堂2503 3階</p>
        <p class="tel-fax">TEL:052-700-3238</p>
        <p class="route">
          <br
            class="sp-kaigyo"
          />お越しの場合はカーナビで上記の住所で検索ください。
        </p>
      </div>
    </div>
    <UiToContact />
    <!-- <div class="wrap">
      <UiToLine />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiToContact from "@/components/Ui/ToContact.vue";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "HomeAccess",
  components: {
    UiHeading,
    UiLine,
    UiToContact,
    // UiToLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.yamada-tax {
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
  }
  .photo {
    width: 500px;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      width: 300px;
    }
  }
  .tel-fax {
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}
</style>
