<template>
  <div class="company-vision-reason">
    <CompanyVisionDetailsNo1 id="reasonNo1"/>
    <CompanyVisionDetailsNo2 id="reasonNo2"/>
    <CompanyVisionDetailsNo3 id="reasonNo3"/>
    <CompanyVisionDetailsNo4 id="reasonNo4"/>
    <CompanyVisionDetailsNo5 id="reasonNo5"/>
    <CompanyVisionDetailsNo6 id="reasonNo6"/>
    <CompanyVisionDetailsNo7 id="reasonNo7"/>

    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyVisionDetailsNo1 from "@/components/CompanyVision/Details/No1.vue";
import CompanyVisionDetailsNo2 from "@/components/CompanyVision/Details/No2.vue";
import CompanyVisionDetailsNo3 from "@/components/CompanyVision/Details/No3.vue";
import CompanyVisionDetailsNo4 from "@/components/CompanyVision/Details/No4.vue";
import CompanyVisionDetailsNo5 from "@/components/CompanyVision/Details/No5.vue";
import CompanyVisionDetailsNo6 from "@/components/CompanyVision/Details/No6.vue";
import CompanyVisionDetailsNo7 from "@/components/CompanyVision/Details/No7.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    CompanyVisionDetailsNo1,
    CompanyVisionDetailsNo2,
    CompanyVisionDetailsNo3,
    CompanyVisionDetailsNo4,
    CompanyVisionDetailsNo5,
    CompanyVisionDetailsNo6,
    CompanyVisionDetailsNo7,
    // HomeArticle,
    UiToContact,
    // UiToLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.description {
  h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 40px;
  }
  .bottom-0 {
    margin-bottom: 0;
  }
  .blue {
    color: var(--blue);
  }
  .green {
    color: var(--green);
  }

  ol {
    margin-bottom: 40px;
    li {
      list-style-type: decimal;
      margin-left: 20px;
    }
  }
  ul {
    margin-bottom: 40px;
    li {
      list-style: disc;
      margin-left: 20px;
    }
  }
  .none {
    margin-top: 30px;
    li {
      list-style-type: none;
      margin-left: 0px;
    }
  }
}
</style>
