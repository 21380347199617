import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'
import Company from '../views/Company.vue'
import CompanyVision from '../views/CompanyVision.vue'
import Zeimukomon from '../views/Zeimukomon.vue'
import Kaigyo from '../views/Kaigyo.vue'
import ZeirishiHenko from '../views/ZeirishiHenko.vue'
import Spot from '../views/Spot.vue'
import Blog from '../views/Blog.vue'
import BlogDetail from '../views/Blog/BlogDetail.vue'
import Partner from '../views/Partner.vue'
import Seminar from '../views/Seminar.vue'
import Contact from '../views/Contact.vue'
import Ctrl from '../views/Ctrl.vue'
import CtrlHome from '../views/Ctrl/Home.vue'
import CtrlLogin from '../views/Ctrl/Login.vue'
import CtrlChangePassword from '../views/Ctrl/ChangePassword.vue'
import CtrlPost from '../views/Ctrl/Post.vue'
import CtrlPostDetail from '../views/Ctrl/PostDetail.vue'
import CtrlCategory from '../views/Ctrl/Category.vue'
import CtrlSort from '../views/Ctrl/Sort.vue'
import CtrlCategoryDetail from '../views/Ctrl/CategoryDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '山田雄大税理士事務所',
      description: '山田雄大税理士事務所は、あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商２億円未満の小規模事業主を専門とした税理士事務所です。小規模事業者を専門とすることで、事業内容とご要望を的確に把握し、最適な料金をご提案し、きめ細かな税務対応をお約束します。当事務所の方針として、税理士である私がすべてのお客様の顧問をしております。'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    meta: {
      title: '事務所・税理士紹介｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商2億円未満の事業主を専門とする山田雄大税理士事務所の事務所、および、所長の山田雄大について詳しく説明しています。税務署職員出身である代表税理士の山田のちょっと変わった経歴などについて説明しています。'
    }
  },
  {
    path: '/company/vision',
    name: 'company-vision',
    component: CompanyVision,
    meta: {
      title: '選ばれる理由｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商2億円未満の事業主を専門とする山田雄大税理士事務所がお客様から選ばれる理由について説明しています。小規模事業者に特化しており、かつ、ITやクラウドに強い税理士だからこそ提供できる価値が当事務所にはあります。'
    }
  },
  {
    path: '/zeimukomon',
    name: 'zeimukomon',
    component: Zeimukomon,
    meta: {
      title: '税務顧問・記帳代行｜山田雄大税理士事務所',
      description: 'あま市とその隣接エリア（清須市・稲沢市・・津島市・愛西市・一宮市・名古屋市・大治町・蟹江町）の年商2億円未満の事業主を専門とする山田雄大税理士事務所の税務顧問と記帳代行についてです。古いスタイルの税理士事務所と差別化し、若さとITを用いて小規模事業者の皆様のサポートをさせていただいております。初回の相談から顧問料のお見積りまで、無料で行っています。'
    }
  },
  {
    path: '/kaigyo',
    name: 'kaigyo',
    component: Kaigyo,
    meta: {
      title: '会社設立・開業のサポート｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商2億円未満の事業主を専門とする山田雄大税理士事務所の会社設立・開業のサポートについてです。'
    }
  },
  {
    path: '/zeirishi-henko',
    name: 'zeirishi-henko',
    component: ZeirishiHenko,
    meta: {
      title: '現在の税理士からの変更｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商2億円未満の事業主を専門とする山田雄大税理士事務所では、現在の税理からの変更もスムーズに承っています。当事務所に税理士を変更することによるメリットや、変更によって実現できたことを説明しています。'
    }
  },
  {
    path: '/spot',
    name: 'spot',
    component: Spot,
    meta: {
      title: '税務関連のスポットでのお仕事｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商2億円未満の事業主を専門とする山田雄大税理士事務所では、一回だけ仕事を依頼されたい方のお手伝いもしております。事業が小規模で確定申告だけ依頼したい個人事業主や会社経営者の方、事業はしていないが確定申告が必要な方はご相談ください。'
    }
  },
  {
    path: '/category/blog',
    name: 'blog',
    component: Blog,
    meta: {
      title: 'お役立ち情報｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）で活動する税理士山田雄大による税務のお役立ち情報です。'
    }
  },
  {
    path: '/category/blog/:catUrl/:postId',
    name: 'blog-detail',
    component: BlogDetail,
    props: route => ({
      catUrl: String(route.params.catUrl),
      postId: String(route.params.postId)
    }),
    meta: {
      title: 'お役立ち情報｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）で活動する税理士山田雄大による税務のお役立ち情報です。'
    }
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    meta: {
      title: '提携業者の紹介｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）を対応エリアとする山田雄大税理士事務所は、愛知県エリアの専門家（～士、～業）や、各専門分野の業者と提携しております。ご紹介の上、対応させていただきます。当事務所は信頼できるプロフェッショナルの方以外お付き合いしておりませんので、自信をもってご紹介いたします。'
    }
  },
  {
    path: '/seminar',
    name: 'seminar',
    component: Seminar,
    meta: {
      title: 'セミナーの依頼｜山田雄大税理士事務所',
      description: 'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）を対応エリアとする山田雄大税理士事務所では、起業、記帳、税金（節税等）に関するセミナーを、起業を考えている方や、企業、グループに向けて提供しています。ご興味がある個人、経営者、セミナー関連業の方は、お気軽にお問い合わせください。'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ｜山田雄大税理士事務所',
      description: '山田雄大税理士事務所へのお問い合わせはこちらからお願いいたします。お電話とメールにくわえ、LINEによるお問い合わせも可能です。'
    }
  },
  {
    path: '/ctrl',
    name: 'ctrl',
    component: Ctrl,
    meta: {
      title: '管理画面',
      description: ''
    },
    children: [
      {
        path: '',
        name: 'ctrl-home',
        component: CtrlHome,
        meta: {
          title: '',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'login',
        name: 'ctrl-login',
        component: CtrlLogin,
        meta: {
          title: 'ログイン',
          description: '',
          requiresAuth: false,
        }
      },
      {
        path: 'change-password',
        name: 'ctrl-change-password',
        component: CtrlChangePassword,
        meta: {
          title: 'パスワード変更',
          description: '',
          requiresAuth: false,
        }
      },
      {
        path: 'post',
        name: 'ctrl-post',
        component: CtrlPost,
        meta: {
          title: '投稿一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'sort',
        name: 'ctrl-sort',
        component: CtrlSort,
        meta: {
          title: '記事並び替え',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'post-detail/:postId',
        name: 'ctrl-post-detail',
        component: CtrlPostDetail,
        props: route => ({
          postId: String(route.params.postId)
        }),
        meta: {
          title: '投稿',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'category',
        name: 'ctrl-category',
        component: CtrlCategory,
        meta: {
          title: 'カテゴリー一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'category-detail/:categoryId',
        name: 'ctrl-category-detail',
        component: CtrlCategoryDetail,
        props: route => ({
          categoryId: String(route.params.categoryId)
        }),
        meta: {
          title: 'カテゴリー設定',
          description: '',
          requiresAuth: true
        }
      },
    ]
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //lastcheck
  // base: '/renew',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  console.log(to);
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");
  store.dispatch("setCategoryList");
  store.dispatch("setPostList");

    //認証が必要なページかどうか(ログインチェック)
    if (to.matched.some(record => record.meta.requiresAuth) === true) {

      // // 認証状態を確認
      store.dispatch('loginCheck').then((res) => {
        if (res.data.status === 200) {
          store.dispatch('setLogin', 1); //ログインチェック対象のページでのみ、login状態の監視をするようにする
          next();
        } else {
          //ココから本番復活ゾーン
          store.dispatch('setLogin', 0); //ログインチェック対象のページでのみ、login状態の監視をするようにする
          next('/ctrl/login');
          //ココまで本番復活ゾーン
          //ココからローカルゾーン
          // store.dispatch('setLogin', 1); 
          // next();
          //ココまでローカルゾーン
        }
      }).catch((err) => {
        console.log(err);
      });
  
    } else {
      console.log("requiresAuth through.");
      next();
    }
  
  });

  // let changeWindowSize = 375;
  // let viewportContent = '';
  // if (window.innerWidth < changeWindowSize) {
  //   viewportContent = 'width=375';
  // }else {
  //   viewportContent = 'width=1300';
  // }
  // document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);



export default router
