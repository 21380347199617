<template>
  <div class="partner">
    <div class="wrap space-100">
      <PartnerJointBusiness />
    </div>

    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PartnerJointBusiness from "@/components/Partner/JointBusiness.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "partner",
  components: {
    // HomeArticle,
    UiToContact,
    // UiToLine,
    PartnerJointBusiness,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
