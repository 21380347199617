<template>
  <div class="zeimukomon">
    <div class="space-150">
      <ZeimukomonRequest />
    </div>
    <div class="space-200">
      <ZeimukomonTroubles />
    </div>
    <div class="space-200">
      <ZeimukomonConsider />
    </div>
    <div class="space-200 wrap">
      <ZeimukomonSupport />
    </div>
    <div class="space-200 wrap">
      <ZeimukomonPrice />
    </div>
    <div class="space-200">
      <ZeimukomonReason />
    </div>

    <div class="space-200 blue-area" style="background-color: #d0eeee">
      <HomeMap />
    </div>
    <div class="space-200 wrap">
      <HomeVoice />
    </div>

    <div class="space-200">
      <ZeimukomonQuestion />
    </div>

    <div class="wrap space-200">
      <HomeFlow />
    </div>
    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ZeimukomonRequest from "@/components/Zeimukomon/Request.vue";
import ZeimukomonTroubles from "@/components/Zeimukomon/Troubles.vue";
import ZeimukomonConsider from "@/components/Zeimukomon/Consider.vue";
import ZeimukomonSupport from "@/components/Zeimukomon/Support.vue";
import ZeimukomonPrice from "@/components/Zeimukomon/Price.vue";
import ZeimukomonReason from "@/components/Zeimukomon/Reason.vue";
import HomeMap from "@/components/Home/Map.vue";
import HomeVoice from "@/components/Home/Voice.vue";
import ZeimukomonQuestion from "@/components/Zeimukomon/Question.vue";
import HomeFlow from "@/components/Home/Flow.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "zeimukomon",
  components: {
    ZeimukomonRequest,
    ZeimukomonTroubles,
    ZeimukomonConsider,
    ZeimukomonSupport,
    ZeimukomonPrice,
    ZeimukomonReason,
    HomeMap,
    HomeVoice,
    ZeimukomonQuestion,
    HomeFlow,
    // HomeArticle,
    UiToContact,
    // UiToLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
