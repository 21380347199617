<template>
  <div class="table">
    <table>
      <tr>
        <th class="title" colspan="3">{{tableTitle}}</th>
      </tr>
      <tr>
        <th>対象の方・<br class="pc-kaigyo">お仕事の詳細</th>
        <td colspan="2" v-html="target" class="target">
          
        </td>
      </tr>
      <tr v-if="type === 'A'">
        <th rowspan="9">料金（全て税別）</th>
        <td class="condition">年間売上が100万円以下</td>
        <td class="price">30,000円</td>
      </tr>
      <tr v-if="type === 'A'">
        <td class="condition">年間売上が300万円以下</td>
        <td class="price">50,000円</td>
      </tr>
      <tr v-else-if="type === 'B'">
        <th rowspan="8">料金（全て税別）</th>
        <td class="condition">年間売上が300万円以下</td>
        <td class="price">50,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が500万円以下</td>
        <td v-if="type === 'B'" class="price">75,000円</td>
        <td v-else-if="type === 'A'" class="price">70,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が1,000万円以下</td>
        <td v-if="type === 'B'" class="price">100,000円</td>
        <td v-else-if="type === 'A'" class="price">90,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が2,000万円以下</td>
        <td v-if="type === 'B'" class="price">125,000円</td>
        <td v-else-if="type === 'A'" class="price">120,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が3,000万円以下</td>
        <td v-if="type === 'B'" class="price">150,000円</td>
        <td v-else-if="type === 'A'" class="price">150,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が5,000万円以下</td>
        <td class="price">175,000円</td>
      </tr>
      <tr>
        <td class="condition">年間売上が5,000万円超</td>
        <td class="price">別途見積もり</td>
      </tr>
      <tr>
        <td class="condition">※消費税申告書の作成がある場合</td>
        <td class="price">別途30,000円</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Uitable",
  components: {},
  props: {
    tableTitle:String,
    target:String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 50px;
  // table-layout: fixed;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: var(--green) solid 1px;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    th {
      background-color: rgb(223, 223, 223);
      color: var(--green);
      text-align: center;
      vertical-align: middle;
      width: 22%;
      font-size: 18px;
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
    td {
      padding: 10px 0 10px 15px;
      width: 39%;
      @media screen and (max-width: 767px){
        width: 100%;
        text-align: left;
      }
    }
  }
  .title {
    background-color: var(--green);
    width: 100%;
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .price {
    text-align: right;
    padding-left: 0;
    padding-right: 20px;
  border-left: none;
  @media screen and (max-width: 767px){
    border-left: solid 1px var(--green);
    border-top: none;
  }
}
.condition{
  border-right: none;
  @media screen and (max-width: 767px){
    border-right: solid 1px var(--green);
  }
}
.target{
  padding: 20px; 
}
}
</style>