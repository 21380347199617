<template>
  <div class="wrap space-100">
    <el-row>
      <el-col><h1>並び替え設定</h1></el-col>
    </el-row>

    <p style="text-align: center">
      並び替えたい「カテゴリ」または「記事」をマウスでドラッグして、希望の位置においてください。<br />
      「ソート確定」をクリックすると、並び替えが確定します。
    </p>

    <div class="top-bar">
      <el-button type="warning" plain @click="back">戻る</el-button>
      <router-link :to="'/category/blog/'" target="_blank"
        ><el-button type="primary" plain
          >プレビュー</el-button
        ></router-link
      >
    </div>

    <draggable
      v-model="informationList"
      element="ul"
      :options="{ animation: 300 }"
      class="box"
    >
      <li
        v-for="categoryData in informationList"
        :key="categoryData.categoryId"
      >
        <p class="category">{{ categoryData.catName }}</p>
        <draggable
          v-model="categoryData.postList"
          element="ul"
          :options="{ animation: 300 }"
          class="cover"
        >
          <li
            class="post"
            v-for="postData in categoryData.postList"
            :key="postData.postId"
          >
            {{ postData.title }}
          </li>
        </draggable>
      </li>
    </draggable>
    <div class="kakutei">
      <el-button type="danger" plain @click="registerSort"
          >ソート確定</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "Draggable",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      informationList: [],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    this.informationList = JSON.parse(
      JSON.stringify(this.$store.getters["informationList"])
    );
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    registerSort() {
      let params = new URLSearchParams();
      params.append("information_list", JSON.stringify(this.informationList));
      this.$axios
        .post(`${this.rootUrl}/api/reg-sort.php`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("setCategoryList");
            this.$store.dispatch("setPostList");
            alert(response.data.message);
            // this.$router.go(-1);
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
.cover {
  padding-bottom: 30px;
}
.category,
.post {
  padding-left: 20px;
  background-color: #e9f7f7;
}
.category {
  color: var(--green);
  font-size: 22px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.post {
  padding-bottom: 10px;
}
.box {
  padding: 30px 80px;
}
.top-bar {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.kakutei{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
</style>
