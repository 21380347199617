<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'07'"
      :type="'A'"
      :barText="'元税務署職員だからこそできるリアルな税務対応'"
      class="space-100"
    />
    <div class="description-area wrap">
      <p>
        山田雄大税理士事務所の代表は名古屋国税局・税務署出身の税理士です。その経験から、税務署とうまく付き合うノウハウがあります。無駄な納税をなくし、なるべく税務調査がないように、知識と経験を生かしてサポートいたします。
      </p>

      <h3 class="blue">元税務職員の税理士と普通の税理士の差とは？</h3>


      <p>元税務職員の税理士と普通の税理士の差とは何でしょうか。一番大きな違いは、「常に税務署を意識しているか」という点です。</p>

      <p>
        お客様にとって一番のリスクは、税務調査が入り追徴で税金を取られることです。</p><p>
        ある程度の事業規模になると、税務調査を避けることはできません。狙われやすい業種によっては数年に一度ずつ調査があります。もちろん税務署は事前に色々な情報を収集した後に調査を行います。先日も、ある経営者から私が元税務職員ということを聞きつけて、税務調査の立ち合いをして欲しいとの依頼がありました。（その方が付き合っていたのは税理士ではない無資格者でした）。話を聞くと、明らかな脱税行為をしていることがわかりました。</p><p>
        このまま調査が進めば、多額の税金が追徴されることは間違いないという状況でした。追徴の税金は一括納付が原則で、さらに税金というものは破産しても無くなることはありません。恐らくこの方は事業を続けられず、何年もかけて税金を払い続けることになるだろうな、ということが私自身の経験からすぐに推測できました。（もちろん依頼に関してお断りました。）</p><p>
        少し極端な例にはなりましたが、税理士事務所は税務調査に対して明確なスタンス持って対策にあたるべきと考えます。
      </p>
      <!-- <p>元税務職員の税理士が担当する当事務所はは、税務調査のリスクを回避するために2つの方法を採ります。</p>
      <ol class="blue number">
        <li>適切な申告書を作成する</li>
        <li>税務調査になった際に適切な対応をしてお客様を守る</li>
      </ol>

      <p>１について、そもそも適切な申告書を作成できればリスクは減ります。税務署は提出された申告書を参考にして税務調査の対象を選びます。元税務職員の税理士は税務署が申告書のどこを見て税務調査の対象を選んでいるのかを知っています。危険な場所がどこかがわかるということです。</p>

      <p>
        ２についても同じです。元税務職員の税理士は税務署が税務調査の際に何に注目しているか、どこを詳しく調べるのかを知っています。税務調査の前に対策を取ることができ、実際の調査の際も適切な対応ができるということです。税務職員との交渉も、落としどころがわかります。
      </p> -->

      <p>具体的な対応方法の細かな内容についてはお手伝いさせていただくお客様ごとに異なるため、ここで詳しくお伝えすることはできませんが、自信をもって対応できることだけはお約束いたします。</p>

      <p>元税務職員の税理士に頼むメリットは
        <ul>
          <li>税務調査のリスクが減る</li>
          <li>税務署内部の事情に精通しているので状況に合わせた対応ができる</li>
          <li>税務署の質問の意図や考えがわかる</li>
          <li>どのような状況が狙われやすいかがわかる。</li>
ということです。
        </ul>
      </p>

      <p>
        税理士の中には最初から税務署とけんか腰の方や、税務署を軽視している方がいます。このような税理士と付きあうことはデメリットにしかなりません。税務署としっかりと対応できる税理士と付きあうことが皆様を守ることに繋がります。当事務所はそのメリットを皆様にお届けすることをお約束します。
      </p>

    </div>
            <UiMiniToTopButton :type="'C'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
