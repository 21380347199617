<template>
  <div class="ctrl-category space-100 wrap">
    <div>
      <el-row>
        <el-col
          ><h1>カテゴリー設定{{ categoryId }}</h1></el-col
        >
      </el-row>

      <el-row type="flex" justify="space-between" class="top-bar">
        <el-col :span="4"
          ><el-button type="warning" plain @click="back"
            >戻る</el-button
          ></el-col
        >
        <el-col :span="3"
          ><router-link :to="'/ctrl/category-detail/0'"
            ><el-button type="danger" plain style="width: 150px"
              >新規作成</el-button
            ></router-link
          ></el-col
        >
      </el-row>
      <el-row>
        <el-col>
          <table class="typeA stripe">
            <thead>
              <tr>
                <th class="cat-name">カテゴリー名</th>
                <th class="url">URL</th>
                <!-- <th class="sort">ソート順</th> -->
                <th class="edit" colspan="2">Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="categoryData in categoryList"
                :key="categoryData.categoryId"
              >
                <td>
                  {{ categoryData.catName }}
                </td>
                <td class="url">
                  {{ categoryData.catUrl }}
                </td>
                <!-- <td class="sort">
                  {{ categoryData.catSort }}
                </td> -->
                <td class="btn edit">
                  <router-link
                    :to="`/ctrl/category-detail/${categoryData.categoryId}`"
                  >
                    <el-button type="success" plain>編集</el-button>
                  </router-link>
                </td>
                <td class="btn edit">
                  <el-button
                    plain
                    @click="deleteCategory(categoryData.categoryId)"
                    >削除</el-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrlCategory",
  components: {},
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl", "categoryList"]),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    deleteCategory(categoryId) {
    if( confirm("本当に削除してもいいですか？") ) //
    {
      let params = new URLSearchParams();
      params.append("category_id", categoryId);
      this.$axios
        .post(`${this.rootUrl}/api/del-category.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            this.$store.dispatch("setCategoryList");
            alert(response.data.message);
            // console.log('ココok')
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
        }
    else {
    return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
.el-col {
  text-align: left;
}
.top-bar {
  margin: 20px;
}
table.typeA {
  //一覧などに使うテーブル。ヘッダー有色。
  width: 100%;
  border-collapse: collapse;
  color: #4d4d4d;
  margin-bottom: 100px;
  tr {
    border-top: solid 1px #f0f0f0;
    border-bottom: solid 1px #f0f0f0;
    th,
    td {
      padding: 10px 20px;
    }
  }
  thead {
    tr {
      background: #f0f0f0;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      th {
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        &.btn {
          width: 80px;
          text-align: center;
          padding: 10px 0;
        }
      }
    }
  }
}
table.stripe {
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: #f8f8f8;
      }
    }
  }
}
.cat-name {
  width: 50%;
}
.url {
  width: 30%;
}
.sort {
  width: 15%;
  text-align: center !important;
}
.edit {
  width: 20%;
  text-align: center !important;
}
</style>