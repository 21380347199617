<template>
  <div class="partner-joint-business">
    <UiHeading :h="'1'" :type="'A'" :text="'提携事業者の紹介'" />
    <UiLine />
    <p>
      山田雄大税理士事務所は、愛知県エリアの専門家（～士、～業）や、各専門分野の業者と提携しております。ご紹介の上、対応させていただきます。当事務所は信頼できるプロフェッショナルの方以外お付き合いしておりませんので、自信をもってご紹介いたします。ご依頼を希望の場合は、まずは山田雄大税理士事務所までお問い合わせください。
    </p>
    <div class="company-table space-100">
      <h3>原司法書士事務所</h3>
      <UiTable4 :tableList="TableList" />
    </div>

    <div class="company-table space-100">
      <h3>西原社労士事務所</h3>
      <UiTable4 :tableList="TableList2" />
    </div>
    <div class="company-table space-100">
      <h3>行政書士伊藤りょういち事務所</h3>
      <UiTable4 :tableList="TableList3" />
    </div>
    <div class="company-table space-100">
      <h3>行政書士不動産 小林総合事務所</h3>
      <UiTable4 :tableList="TableList4" />
    </div>
    <div class="company-table space-100">
      <h3>株式会社グレイトヘルプ</h3>
      <UiTable4 :tableList="TableList5" />
    </div>

    <div class="company-table space-100">
      <h3>Clear works 鈴木陽子</h3>
      <UiTable4 :tableList="TableList6" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiTable4 from "@/components/Ui/Table4.vue";
export default {
  name: "partner-joint-business",
  components: {
    UiHeading,
    UiLine,
    UiTable4,
  },
  props: {},
  data() {
    return {
      TableList: [
        {
          id: 1,
          th: "専門業種",
          td: "相続・商業・事業承継を専門にした司法書士",
        },
        {
          id: 2,
          th: "住所",
          td: "愛知県名古屋市北区憧旛町三丁目２７番地",
        },
        {
          id: 3,
          th: "電話番号",
          td: "090-4258－8288",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="https://shihousyoshi-harayuta.com" target="_blank" rel="noopener noreferrer">https://shihousyoshi-harayuta.com/</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: "愛知県名古屋市にある人と人とのつながりを大切にしたいをモットーとする司法書士事務所です。<br>困ったときに、迅速な対応とクライアントに寄り添って対応いたします。",
        },
      ],
      TableList2: [
        {
          id: 1,
          th: "専門業種",
          td: "社会保険・労働保険に関する相談・手続、助成金申請代行、労働問題の未然防止対策、給与計算代行",
        },
        {
          id: 2,
          th: "住所",
          td: "愛知県名古屋市中村区亀島2-17-23　カルム亀島３C",
        },
        {
          id: 3,
          th: "電話番号",
          td: "052-485-8376",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="http://www.nishihara-sr.com" target="_blank" rel="noopener noreferrer">http://www.nishihara-sr.com</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: "名古屋駅西側にある社会保険労務士事務所です。<br>労働環境における社内制度の整備について、助成金活用を併せてご提案させていただきます。<br>またクライアント様は本業に専念できるよう、社会保険・労働保険に関する手続や給与計算など雇用に関する手続を代行させていただきます。",
        },
      ],
      TableList3: [
        {
          id: 1,
          th: "専門業種",
          td: "建設業許可を中心とした許認可専門の行政書士",
        },
        {
          id: 2,
          th: "住所",
          td: "愛知県名古屋市北区憧旛町三丁目２７番地",
        },
        {
          id: 3,
          th: "電話番号",
          td: "052-387-7676",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="https://kensetsu-kyoninnka.com" target="_blank" rel="noopener noreferrer">https://kensetsu-kyoninnka.com</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: "当事務所は愛知県名古屋市にある行政書士事務所です。<br>何事にも「全力」で「真心」こめて「誠実」にをモットーとしています。<br>当事務所の特徴は、面談の際にしっかりと時間をかけてヒアリングを行い、「今取得したい許認可」のことだけではなく、将来のビジョンや今後の計画なども含めて最も適切な許認可取得プランを練っていきます。<br>クライアント様の想いやお悩みに寄り添って事業の拡大のお手伝いをさせていただきます。",
        },
      ],
      TableList4: [
        {
          id: 1,
          th: "専門業種",
          td: "不動産関係に強い行政書士　風営法許可、深夜酒類提供営業の届出、外国人の在留資格、遺言・相続業務",
        },
        {
          id: 2,
          th: "住所",
          td: "名古屋市中区新栄1丁目5番6号スタービル7階705",
        },
        {
          id: 3,
          th: "電話番号",
          td: "052-756-0033",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="https://kobayashioffice.net" target="_blank" rel="noopener noreferrer">https://kobayashioffice.net</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: "不動産関係に強い行政書士です。不動産が関連する相続、遺言、図面作成が大変な風営法許可や深夜酒類提供営業、宅地建物取引業の申請、自動車の移転登録（出張封印）など、その他外国人の方の在留資格（ビザ）の申請などを取り扱っていますが、対応できるものは他にもありますのでお気軽にお問い合わせください。",
        },
      ],
      TableList5: [
        {
          id: 1,
          th: "専門業種",
          td: "ホームページ制作、システム開発、紙媒体の制作、マーケティング・集客",
        },
        {
          id: 2,
          th: "住所",
          td: "岐阜県岐阜市加納桜田町2-5-2",
        },
        {
          id: 3,
          th: "電話番号",
          td: "058-214-9005",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="https://greathelp.co.jp/" target="_blank" rel="noopener noreferrer">https://greathelp.co.jp/</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: "集客・マーケティングに強みのあるホームページ制作業者です。ネットショップ制作やシステム開発、紙媒体のデザインと印刷などの案件も対応可能なため、事業を行う上で必要となる販促媒体などをワンストップでご注文いただくことができます。",
        },
      ],
      TableList6: [
        {
          id: 1,
          th: "専門業種",
          td: "業務改善、お仕事片付けコンサルティング、ITサポートサービス、システム導入相談、",
        },
        {
          id: 2,
          th: "住所",
          td: "非公開",
        },
        {
          id: 3,
          th: "電話番号",
          td: "090-6807-6268",
        },
        {
          id: 4,
          th: "ホームページ",
          td: '<a href="https://sunnycreation.net/" target="_blank" rel="noopener noreferrer">https://sunnycreation.net/</a>'
        },
        {
          id: 5,
          th: "詳細",
          td: " 売上をキープしながら稼働時間３割削減を目指すお仕事片付けコンサルタントです。<br>業務の無駄・効率改善ポイントを発見し、状況に合わせた効率化システムの導入や業務時短アドバイスを行います。<br>具体的には予約システムの導入や、業務のオンライン化、チーム内の情報共有化システムの導入などです。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.partner-joint-business{
   @media screen and (max-width: 767px){
     margin-bottom: 150px;
   }
}
.company-table {
  h3 {
    color: var(--green);
    font-size: 24px;
    margin-bottom: 30px;
    
  @media screen and (max-width: 767px){
    margin-top: 50px;
    font-size: 20px;
  }
}
  }
</style>