<template>
  <div class="home">
    <img
      :src="`${rootUrl}/img/yamada-yudai_top-image01.jpg`"
      alt="トップ画像"
      class="top pc"
    />
    <img
      :src="`${rootUrl}/img/yamada-yudai_sp-image01.jpg`"
      alt="トップ画像"
      class="top sp"
    />
    <div class="wrap space-200">
      <HomeAboutus />
    </div>

    <div class="wrap space-200">
      <HomeAboutchief />
    </div>

    <div class="wrap space-200">
      <HomeService />
    </div>

    <div class="space-200">
      <UiHeading
        :h="'2'"
        :type="'B3'"
        :text="'ヒアリングの上、顧問料の見積りを無料で行っています'"
      />
    </div>
    <div class="wrap">
      <HomeEstimate />
    </div>

    <div class="space-200">
      <HomeReason :button="false" :h="'2'" :noteShow="false" style="pointer-events: none;"/>
          <UiDetailButton
      :type="'B'"
      :path="`/company/vision/`"
      :text="'詳細を見てみる'"
      style="margin: 50px 0;"
      class="button-home"
    />
    </div>

    <div class="space-200 blue-area" style="background-color: #d0eeee">
      <HomeMap />
    </div>

    <div class="wrap space-200">
      <HomeVoice />
    </div>

    <div class="wrap space-200">
      <HomeClient />
    </div>

    <div class="wrap space-200">
      <HomeIndustry />
    </div>

    <div class="space-200">
      <HomeQuestion />
    </div>

    <div class="wrap space-200">
      <HomeFlow />
    </div>

    <div class="space-200">
      <HomeAccess />
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeAboutus from "@/components/Home/Aboutus.vue";
import HomeAboutchief from "@/components/Home/Aboutchief.vue";
import HomeService from "@/components/Home/Service.vue";
import HomeEstimate from "@/components/Home/Estimate.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomeMap from "@/components/Home/Map.vue";
import HomeVoice from "@/components/Home/Voice.vue";
import HomeClient from "@/components/Home/Client.vue";
import HomeIndustry from "@/components/Home/Industry.vue";
import HomeQuestion from "@/components/Home/Question.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeAccess from "@/components/Home/Access.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiHeading from "@/components/Ui/Heading.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";

export default {
  name: "home",
  components: {
    HomeAboutus,
    HomeAboutchief,
    HomeService,
    HomeEstimate,
    HomeReason,
    HomeMap,
    HomeVoice,
    HomeClient,
    HomeIndustry,
    HomeQuestion,
    HomeAccess,
    HomeFlow,
    // HomeArticle,
    UiHeading,
    UiDetailButton
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
.top {
  width: 100%;
}
.pc{
   @media screen and (max-width: 767px){
     display: none;
   }
}
.sp{
  display: none;
  @media screen and (max-width: 767px){
    display: initial;
  }

}
/deep/.button-home{
        p{
          margin: 10px 0px;
        }
      }

</style>
