import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto/vue-scrollto.js'
import VueHead from 'vue-head'

import ElementUI from 'element-ui';//エレメント
import 'element-ui/lib/theme-chalk/index.css';//エレメント
import locale from 'element-ui/lib/locale/lang/ja'

Vue.use(VueHead)

Vue.use(VueScrollTo, {
  offset: -100
})

Vue.use(ElementUI, { locale });

//グローバル登録
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
