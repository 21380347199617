<template>
  <div class="ui-bar">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
      <div class="wrap">
        <div class="outer">
          <div class="bar-box">
            <div class="cover">
              <div class="bar-no">
                <p>{{ barNo }}</p>
              </div>
              <div class="bar-text">
                <p v-html="barText"></p>
              </div>
            </div>
            <img
              v-if="openContent === false"
              :src="`${rootUrl}/img/open.png`"
              alt="開く"
              class="open"
            />
            <img
              v-else-if="openContent === true"
              :src="`${rootUrl}/img/opening.png`"
              alt="閉じる"
              class="open"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBox",
  components: {
    // UiDetailButton,
  },
  props: {
    barNo: String,
    barText: String,
    type: String,
    openContent: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  margin: 15px 0;
  min-height: 120px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .bar-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      // width: 100%;
    }
    .cover {
      display: flex;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        //  justify-content: center;
        align-items: center;
      }
    }
    .bar-no {
      // width: 100px;
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid white;
      border-left: 1px solid white;

      @media screen and (max-width: 767px) {
        margin: 10px 0;
        width: 100px;
      }
      p {
        font-size: 34px;
        color: white;
      }
    }
    .bar-text {
      font-size: 26px;
      color: white;
      padding-left: 30px;
      display: flex;
      justify-content: space-between;
      //2行になりそうなタイトルにつける
      /deep/span {
        font-size: 24px;
      }
      @media screen and (max-width: 767px) {
        padding: 0;
        margin: 15px 0;
        // min-height: 100px;
        font-size: 25px;
        display: flex;
        // align-items: center;
        justify-content: center;
        p {
          vertical-align: middle;
        }
      }
    }
  }
}
.typeA {
  background-color: var(--blue);
}
.typeB {
  background-color: var(--green);
}
.open {
  width: 32px;
  height: 32px;
  margin-left: 20px;
  @media screen and (max-width: 767px) {
    margin-left: calc(100% - 30px);
    margin-bottom: 10px;
  }
}
.open:hover {
  filter: opacity(85%);
  cursor: pointer;
}
</style>