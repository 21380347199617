<template>
  <div class="seminar-use">
    <div class="wrap use">
      <UiHeading2
        :h="'3'"
        :type="'A'"
        :text="'こんな用途でご利用をいただいています'"
      />

      <div class="example">
     
          <div class="in-cercle">
            <p>起業を考えている方の<br>記帳や税金についての勉強会として</p>
        </div>
     
          <div class="in-cercle">
            <p>ご自身で記帳をされたい方の<br class="sp-kaigyo">勉強会として</p>
          </div>
     
          <div class="in-cercle">
            <p>同業者団体やグループの研修として</p>
          </div>
          <div class="in-cercle">
            <p>企業が従業員の雇用形態を<br>業務委託に変える研修として</p>
        </div>
     
         </div>
     

      <div class="price space-100">
        <h3>■料金について</h3>
        <p>依頼内容によってお見積もりいたします。具体的にセミナーの内容を相談いただくことも可能です。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading2 from "@/components/Ui/Heading2.vue";

export default {
  name: "seminar-use",
  components: {
    UiHeading2,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.use {
   @media screen and (max-width: 767px){
     margin-top: 50px;
   }
  .example {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
     @media screen and (max-width: 767px){
       margin-top: 0;
     }

   .in-cercle {
    width: 48%;
    margin: 20px 0;
  border-radius: 50px;
  border: var(--green) 2px solid;
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px){
    height: 120px;
    width: 100%;
    margin-bottom: 0;
  }

      p{
        color: var(--green);
        font-size: 26px;
        line-height: 1.8em;
        @media screen and (max-width: 767px){
          font-size: 18px;
          padding: 0 20px;
        }
      }
      
    }
  }
  .price{
    h3{
      font-size: 24px;
      color: var(--green);
    }
    p{
      margin-left: 20px;
      
    }

  }
}
</style>