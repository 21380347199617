<template>
  <div class="company">
    <div class="space-150 wrap" >
      <CompanyIntroduction />
    </div>

    <div class="space-150" >
      <CompanyCareer />
    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyCareer from "@/components/Company/Career.vue";
import CompanyIntroduction from "@/components/Company/Introduction.vue";


export default {
  name: "company",
  components: {
    CompanyIntroduction,
    CompanyCareer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>

</style>
