<template>
  <div class="spot-request">
    <UiHeading :h="'2'" :type="'B2'" :text="'法人税'" class="space-top"/>

    <div class="wrap">
      <h3>■年一回の確定申告書の作成</h3>    
      <UiTable2
        :tableTitle="'法人税の確定申告書の作成'"
        :target="'・決算が近づいているが何も処理していない<br>・法人を設立したが何をしていいか分からない<br>・記帳は自社で行っているので確定申告書の作成だけ依頼したい<br>とお考えの方が対象です。<br>当事務所において確定申告書と決算書の作成を行い、電子申告で確定申告書の提出まで行います。<br>記帳代行料も含んでおりますので領収書や通帳のコピー等の資料を提出いただくだけで完了いたします。'"
        :price="'120,000円～<br>※消費税申告書の作成がある場合…別途見積もり'"
      />
      <h3>■法人税の確定申告をされていない方（無申告）</h3>    
      <UiTable2
        :tableTitle="'無申告の場合の過去の確定申告書の作成'"
        :target="'確定申告をする必要があったにもかかわらず事情により確定申告をされていない無申告の方のが対象です。<br>申告期限を過ぎていても確定申告をすることができますのでまずはご相談ください。<br>過去数年分を作る必要がある方、領収書等の資料を紛失されている方も対応いたします。<br>記帳代行料も含んでおりますので領収書や通帳のコピー等の資料を提出いただくだけで完了いたします。<br>税務調査が入り、多額の延滞税等のペナルティが発生する前にお早めにご相談されることをお勧めします。'"
        :price="'120,000円～（1年分）<br>無申告期間が数年にわたる場合や特殊事情については別途見積もり'"
      />
    </div>

   <UiHeading :h="'2'" :type="'B2'" :text="'贈与税'" class="space-top"/>
   <div class="wrap">
      <UiTable3
        :tableTitle="'贈与税の申告書の作成'"
        :target="'他人から金銭や不動産等の贈与を受けた方が対象です。<br>特例の適用をすることで贈与税を抑えることができますが、特例の適用を受けるためには必ず確定申告が必要です。<br>基本料金は金銭のみ贈与を受けた場合の料金です。<br>贈与財産の評価が必要な場合や、特例の適用をする場合、基本料金に加えて追加料金をいだたいております。'"/>
</div>

   <UiHeading :h="'2'" :type="'B2'" :text="'税金のご相談'" class="space-top" />
   <div class="wrap">
      <UiTable2 style="margin-top:50px;"
        :tableTitle="'個別税務相談'"
        :target="'当事務所と契約をしていないが、個別に税金の相談をされたい方が対象です。'"
        :price="'1時間 10,000円<br>※契約をいただいた場合は無料'" />
</div>

   <UiHeading :h="'2'" :type="'B2'" :text="'納税シミュレーション'"  class="space-top"/>
   <div class="wrap">
      <UiTable2 style="margin-top:50px;"
        :tableTitle="'納税額のシミュレーション'"
        :target="'納税額の予想を立てたい方が対象です。<br>現在と今後の状況をヒアリングして、納税額の予想と今後の対策についてのアドバイスをいたします。'"
        :price="'別途見積もり'" />
</div>




  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
// import UiTable1 from "@/components/Ui/Table1.vue";
import UiTable2 from "@/components/Ui/Table2.vue";
import UiTable3 from "@/components/Ui/Table3.vue";
export default {
  name: "SpotPrice2",
  components: {
    UiHeading,
    // UiTable1,
    UiTable2,
    UiTable3,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--green);
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 20px;
}
.space-top{
  margin-top: 200px;
}
</style>
