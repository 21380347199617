<template>
  <div class="zeimukomon-request">
    <div class="wrap">
      <UiHeading :h="'1'" :type="'A'" :text="`税務顧問・<br class='sp-kaigyo'>記帳代行のご依頼`" />
      <UiLine />

      <div class="request-box">
        <div class="photo-cover">
          <img
            :src="`${rootUrl}/img/yamada-yudai-zeimukomon02.jpg`"
            alt="山田雄大"
          />
          <div class="name">税理士<span>山田雄大</span></div>
        </div>
        <p>
          当事務所のホームページをご覧いただきありがとうございます。愛知県稲沢市で税理士事務所を営んでいる税理士の山田雄大と申します。古いスタイルの税理士事務所と差別化し、若さとITを用いて小規模事業者の皆様のサポートをさせていただいております。顧問契約をご希望の方はお気軽にご相談ください。初回の相談から顧問料のお見積りまで、無料で行っています。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "zeimukomon-request",
  components: {
    UiHeading,
    UiLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.request-box {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
   @media screen and (max-width: 767px){
     display: block;
     width: 100%;
   }
  .photo-cover {
    width: 45%;
     @media screen and (max-width: 767px){
       width: 100%;
     }
    img {
      vertical-align: bottom;
      width: 100%;
    }
    .name {
      background-color: var(--black);
      color: white;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 0;

      span {
        margin-left: 10px;
      }
    }
  }
  p{
    width: 50%;
     @media screen and (max-width: 767px){
       width: 100%;
       margin: 20px 0;
     }
  }
}
</style>
