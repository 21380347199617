<template>
  <div class="company-vision">

    <div class="space-150">
      <CompanyVisionReason :noteShow="true"/>
    </div>

    <div class="space-100">
      <CompanyVisionDetail />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyVisionReason from "@/components/CompanyVision/Reason.vue";
import CompanyVisionDetail from "@/components/CompanyVision/Detail.vue";

export default {
  name: "company-vision",
  components: {
    CompanyVisionReason,
    CompanyVisionDetail,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>

</style>
