var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('table',[_c('tr',[_c('th',{staticClass:"title",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.tableTitle))])]),_c('tr',[_vm._m(0),_c('td',{staticClass:"target",attrs:{"colspan":"2"},domProps:{"innerHTML":_vm._s(_vm.target)}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"vertical"},[_c('span',[_vm._v("対象の方・お仕事の詳細")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"vertical",attrs:{"rowspan":"15"}},[_c('span',[_vm._v("料金（全て税別）")])]),_c('td',{staticClass:"sub",attrs:{"colspan":"2"}},[_vm._v("基本料金")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("500万円以下")]),_c('td',{staticClass:"price"},[_vm._v("20,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("1,000万円以下")]),_c('td',{staticClass:"price"},[_vm._v("30,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("2,000万円以下")]),_c('td',{staticClass:"price"},[_vm._v("40,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("3,000万円以下")]),_c('td',{staticClass:"price"},[_vm._v("50,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("5,000万円以下")]),_c('td',{staticClass:"price"},[_vm._v("60,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("7,000万円以下")]),_c('td',{staticClass:"price"},[_vm._v("70,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("1億円以下")]),_c('td',{staticClass:"price"},[_vm._v("80,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"sub",attrs:{"colspan":"2"}},[_vm._v("特例の追加料金")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("住宅取得資金贈与")]),_c('td',{staticClass:"price"},[_vm._v("20,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("配偶者控除")]),_c('td',{staticClass:"price"},[_vm._v("20,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("土地の評価"),_c('br',{staticClass:"sp-kaigyo"}),_vm._v("（一物件につき）")]),_c('td',{staticClass:"price"},[_vm._v("30,000円")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("上場株式")]),_c('td',{staticClass:"price"},[_vm._v("10,000円×会社数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("非上場株式")]),_c('td',{staticClass:"price"},[_vm._v("別途見積もり")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"condition"},[_vm._v("相続時精"),_c('br',{staticClass:"sp-kaigyo"}),_vm._v("算課税手続き")]),_c('td',{staticClass:"price"},[_vm._v("別途見積もり")])])
}]

export { render, staticRenderFns }