<template>
  <div class="seminar-use">
    <div class="wrap request">
      <UiHeading :h="'1'" :type="'A'" :text="`セミナー・<br class='sp-kaigyo'>企業研修のご依頼`" />
      <UiLine />
      <p>
        山田雄大税理士事務所では、起業、記帳、税金（節税等）に関するセミナーを、起業を考えている方や、企業、グループに向けて提供しています。ご興味がある個人、経営者、セミナー関連業の方は、お気軽にお問い合わせください。
      </p>
    </div>
    <div class="space-100">
      <UiHeading :h="'2'" :type="'A'" :text="'提供しているセミナー'" />
      <UiBarList :barList="barList" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarList from "@/components/Ui/BarList.vue";

export default {
  name: "seminar-use",
  components: {
    UiHeading,
    UiLine,
    UiBarList,
  },
  props: {},
  data() {
    return {
      barList: [
        {
          id: 1,
          type: "A",
          barNo: "01",
          barText: "個人事業の開業に関するセミナー",
        },
        {
          id: 2,
          type: "B",
          barNo: "02",
          barText: "会社設立に関するセミナー",
        },
        {
          id: 3,
          type: "A",
          barNo: "03",
          barText: "記帳に関するセミナー",
        },
                {
          id: 4,
          type: "B",
          barNo: "04",
          barText: "税金（節税）に関するセミナー",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.seminar-about-seminar {
  /deep/h3 {
    font-size: 28px;
  }
  .request {
    text-align: center;
  }
}
</style>