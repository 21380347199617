<template>
  <div class="ctrl-login wrap space-100">
      <el-row>
        <el-col><h1>管理画面</h1></el-col>
      </el-row>
<div class="panel">
    <el-row type="flex" justify="center" style="margin-bottom: 30px">
      <el-col :span="16">
        <el-input type="email" autocomplete="on" placeholder="登録メールアドレス" v-model="mail_address">
          <template slot="prepend">ログインID</template>
        </el-input>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :span="16">
        <el-input type="password" autocomplete="on" placeholder="123456789abcdefg" v-model="password">
          <template slot="prepend">パスワード</template>
        </el-input>
      </el-col>
    </el-row>

     <el-row type="flex" justify="center" style="margin-top: 40px">
      <el-col :span="6" style="text-align: center">
        <el-button @click="login" type="success">ログイン</el-button>
      </el-col>
    </el-row>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ctrl-login",
  components: {

  },
  data() {
    return {
      mail_address:"",
      password:"",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    login(){
      let params = new URLSearchParams();
      params.append("mail_address", this.mail_address);
      params.append("password", this.password);
      this.$axios
        .post(`${this.rootUrl}/api/login.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            // console.log('ok')
            this.$store.dispatch("setImageList");
            this.$router.push("/ctrl")
            return;
          } else if (
            response.data.status === 401 || response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
},
}
</script>

<style lang="scss" scoped>
.panel{
  height: 500px;
  background-color:#f5f5f5;
  padding: 100px;
  margin-bottom: 50px;
  margin-top: 70px;
}
.el-button{
  width:90%;
}
h1{
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}

</style>
