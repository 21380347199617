<template>
  <div class="blog">
    <div class="blog-top space-150">
    <UiHeading
      :h="'1'"
      :type="'A'"
      :text="'お役立ち情報'"
    />
    <UiLine />
    <BlogPost />

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import BlogPost from "@/components/Blog/Post.vue";

export default {
  name: "blog",
  components: {
    UiHeading,
    UiLine,
    BlogPost,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl","informationList"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
