<template>
  <div class="ui-voice-box">
    <!-- <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    > -->
      <div class="file">
        <div class="show-bar" @click="buttomSideShow = !buttomSideShow">
          <div class="file-name">file.{{fileNo}}</div>
          <div class="company">
            <p class="company-text" v-html="companyText">
              
            </p>
            <img
              :src="`${rootUrl}/img/${imgpath}`"
              :alt="{ imgName }"
              class="company-photo"
              v-show="buttomSideShow === false"
            />
          </div>
          <div class="type">
            <div class="type-text">
              <span>業種：</span>{{typeText}}
            </div>
            <img
              v-if="buttomSideShow === false"
              :src="`${rootUrl}/img/open2.png`"
              alt="open-button"
              class="open-button"
            />

            <img
              v-if="buttomSideShow === true"
              :src="`${rootUrl}/img/opening2.png`"
              alt="open-button"
              class="open-button"
            />
          </div>
        </div>

        <div class="buttom-side" v-show="buttomSideShow">
          <div class="photo-cover">
            <img
               :src="`${rootUrl}/img/${imgpath}`"
              :alt="{ imgName }"
              class="photo"
            />
          </div>
          <div class="detail">
            <p v-html="detailText">
            </p>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiVoiceBox",
  components: {
    // UiDetailButton,
  },
  props: {
    companyText: String,
    imgpath:String,
    imgName:String,
    typeText:String,
    detailText:String,
    fileNo:String,
  },
  data() {
    return {
          buttomSideShow: false
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.file {
  margin-bottom: 20px;
  .show-bar {
    display: flex;
    height: 100px;
    border: solid 1px var(--green);
    @media screen and (max-width: 767px) {
      display: block;
      height: auto;
    }
    .file-name {
      width: 100px;
      background-color: var(--green);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .company,
    .type {
      width: calc(calc(100% - 100px) * 0.5);
      padding: 10px 30px;
      font-size: 20px;
      display: flex;
      align-items: center;
              @media screen and (min-width: 768px) and (max-width: 979px){
          padding: 10px 20px;
          font-size: 16px!important;
        }
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 16px;
      }
    }
    .company {
      border-right: var(--green) solid 1px;
      color: var(--green);
      display: flex;
      justify-content: space-between;

      .company-photo {
        width: 80px;
        object-fit: cover;
      }

    }
    /deep/.type {
      display: flex;
      justify-content: space-between;
      font-size:18px;
      span {
        color: var(--green);
        font-size: 18px;
                @media screen and (min-width: 768px) and (max-width: 979px){
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .open-button {
        width: 32px;
                @media screen and (min-width: 768px) and (max-width: 979px){
        margin-left: 10px;
        }
         @media screen and (max-width: 767px) {
           margin-left: calc(100% - 32px);
           margin-top: 10px;
         }
      }

      @media screen and (max-width: 767px) {
        display: block;
        font-size: 16px;
      }
    }
  }
  .show-bar:hover {
    filter: opacity(70%);
    cursor: pointer;
  }
  .buttom-side {
    display: flex;
    border: solid 1px var(--green);
    border-top: none;
    padding: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  .photo-cover {
    width: 20%;
    min-width: 180px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 30px;
    @media screen and (max-width: 767px) {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .photo {
      width: 100%;
      object-fit: cover;
    }
  }
  .detail {
    width: 78%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s;
}
.v-enter-to {
  opacity: 1;
}
.v-leave {
  opacity: 2;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}

// .typeA {
//   background-color: var(--blue);
// }
// .typeB {
//   background-color: var(--green);
// }
</style>