<template>
  <div class="kigyo-to-such-people">
    <UiHeading :h="'2'" :type="'B'" :text="'会社設立をお考えの方'" />
    <div class="description-area wrap space-100">
      <p>
        会社設立を考えている方の相談をいつでも受けつけています。相談時は以下の内容を中心に説明いたします。
      </p>
      <ul class="green">
        <li>会社設立のメリットとデメリットを個人事業と比較して説明</li>
        <li>
          会社設立時に迷うことについてアドバイス（株式か合同か、資本金額、役員の選定など）
        </li>
        <li>会社設立から決算、確定申告をまでの流れを説明</li>
        <li>お客様の状況に合わせてやっていただきたいことを提案</li>
        <li>ヒアリングの上、顧問料のお見積りを提示</li>
      </ul>
      <p>
        ※顧問契約された方は税務関係の書類は完全無料で作成と提出を行います。<br />
        ※顧問契約された方はe-Tax（電子申告）の手続きを無料で行います。<br />
        ※希望される方は提携する司法書士に会社設立を依頼します。（株式会社の場合約30万円）<br />
        ※希望される方は提携する社会保険労務士に社会保険の手続きを依頼します。<br />
        ※希望される方は提携する行政書士に各種許認可の手続きを依頼します。
      </p>
      <p class="bottom-0">
        会社設立時に当事務所が作成する税務関係の書類は以下になります。
      </p>
      <div class="small-business">
        <div class="business">
            <li>法人設立届出（税務署）</li>
            <li>法人設立届出（都道府県）</li>
            <li>法人設立届出（市町村）</li>
            <li>青色申告の承認申請書</li>
            <li>棚卸資産の評価方法の届出書</li>
            <li>減価償却資産の償却方法の届出書</li>
        </div>
        <div class="business">
            <li>有価証券の一単位当たりの帳簿価額の算出方法の届出書</li>
            <li>給与支払事務所等の開設・移転・廃止の届出</li>
            <li>源泉所得税の納期の特例の承認に関する申請書</li>
            <li>消費税課税事業者選択届出書(第1号様式)</li>
            <li>消費税簡易課税制度選択届出書（軽減第1号様式）</li>
            <li>電子申告・納税等開始（変更等）の届出</li>
        </div>
      </div>
    </div>

    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="'個人事業の開業をお考えの方'"
      class="space-100"
    />
    <div class="description-area wrap space-100">
      <p>
        個人事業の開業を考えている方の相談をいつでも受けつけています。相談時は以下の内容を中心に説明いたします。
      </p>
      <ul class="green">
        <li>
          個人事業の開業につきメリットとデメリットをサラリーマンの場合と比較して説明
        </li>
        <li>開業時に迷うことについてアドバイス</li>
        <li>開業から決算、確定申告をまでの流れを説明</li>
        <li>お客様の状況に合わせてやっていただきたいことを提案</li>
        <li>ヒアリングの上、顧問料のお見積りを提示</li>
      </ul>
      <p>
        ※顧問契約された方は税務関係の書類は完全無料で作成と提出を行います。<br />
        ※顧問契約された方はe-Tax（電子申告）の手続きを無料で行います。
      </p>

            <p class="bottom-0">
        個人事業の開業時に当事務所が作成する税務関係の書類は以下になります。
      </p>
      <div class="small-business">
        <div class="business">
          
            <li>個人事業の開業届出・廃業届出等手続</li>
            <li>所得税の青色申告承認申請書</li>
            <li>青色事業専従者給与に関する届出書</li>
            <li>所得税の棚卸資産の評価方法の届出手続</li>
            <li>所得税の減価償却資産の償却方法の届出手続</li>
        </div>
        <div class="business">
            <li>給与支払事務所等の開設・移転・廃止の届出</li>
            <li>源泉所得税の納期の特例の承認に関する申請書</li>
            <li>消費税課税事業者選択届出書(第1号様式)</li>
            <li>消費税簡易課税制度選択届出書（軽減第1号様式）</li>
            <li>電子申告・納税等開始（変更等）の届出</li>
        </div>
      </div>
    </div>

    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="'個人事業を会社に変更したい方（法人成り）'"
      class="space-100"
    />
    <div class="description-area wrap space-100">
      <p>
        個人事業が会社形態に変わることを法人成りといいます。当事務所では法人成りの相談を随時受けつけています。相談時は以下の内容を中心に説明いたします。
      </p>
      <ul class="green">
        <li>会社成りのメリットデメリットの説明</li>
        <li>個人事業と比べてどのような変化があるかの説明</li>
        <li>消費税の納税についての説明</li>
        <li>税金や社会保険料の負担がどう変わるかといったシミュレーション</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "kigyo-to-such-people",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.small-business {
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--green);
  padding: 20px 20px 20px 40px;
    li{
    margin-left: 1.4em;
    text-indent: -1.4em;
  }
    @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px 0;
  }

  .business {
    width: 48%;
    // padding: 20px 0 20px 40px;
    // padding: 20px;
        @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
      font-size: 15px;
    }
  }
}
</style>