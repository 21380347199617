<template>
  <div class="ctrl-category-detail space-100 wrap">
    <div>
      <el-row>
        <el-col
          >
          <!-- <h1>{{ categoryId }}</h1> -->
          <h1>新規カテゴリー</h1>
          </el-col
        >
      </el-row>

      <el-row>
        <el-col>
          <div class="block">
            <h3>
              <span class="demonstration">【カテゴリー名】</span>
            </h3>
            <el-input placeholder="設定するカテゴリ名を入力してください。" v-model="categoryData.catName"></el-input>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <h3>【URL】</h3>
          <el-input placeholder="当カテゴリページのURLとなる文字列を半角英数字で設定してください。" v-model="categoryData.catUrl"></el-input>
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-col>
          <h3>【ソート順】</h3>
          <el-input placeholder="Please input sort order." v-model="categoryData.catSort"></el-input>
        </el-col>
      </el-row> -->
    </div>

    <el-row class="back-button">
      <el-col :span="2" :offset="11" style="text-alghn: center"
        ><el-button type="danger" plain @click="register" class="toukou"
          >作成</el-button
        ></el-col
      >
    </el-row>
    <el-row class="back-button">
      <el-col :span="4"
        ><el-button type="warning" plain @click="back">戻る</el-button></el-col
      >
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrlCategoryDetail",
  components: {},
  props: {
    categoryId: String,
  },
  data() {
    return {
      categoryData: {}
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {
      categoryId: {
      handler(newValue) {
        this.categoryData = JSON.parse(
          JSON.stringify(this.$store.getters["categoryData"](newValue))
        );
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    register(){
            let params = new URLSearchParams();
      params.append("category_id", this.categoryId);
      params.append("name", this.categoryData.catName);
      params.append("slug", this.categoryData.catUrl);
      params.append("sort", this.categoryData.catSort);
      this.$axios
        .post(`${this.rootUrl}/api/reg-category.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
          this.$store.dispatch("setCategoryList");
            alert(response.data.message);
            // console.log('ok')
            this.$router.go(-1);
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        
        .catch((err) => {
          console.log(err);
        });
        
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
h3 {
  margin-top: 40px;
  margin-bottom: 10px;
}
.back-button {
  margin: 50px 0;
}
.toukou {
  width: 150px;
}
</style>