<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'06'"
      :type="'B'"
      :barText="'クラウド会計の完全対応や最新のITを用いてお客様の負担を徹底的に軽減'"
      class="space-100"
    />
    <div class="description-area wrap">
      <p>
        当事務所は古くて非効率な税理士事務所と決別し、若さとITを使った新しい税理士事務所を目指しています。クラウド会計の完全対応・サポートを始め、最新のITを積極的に取り入れ、お客様への導入補助もしています。どんな業務・経理のスタイルにもITで柔軟に対応し、より良い提案をします。
      </p>

      <h3 class="green">クラウド会計の完全対応</h3>
      <p>クラウド会計とは、インターネット環境があれば、いつでもどこでも利用できるものです。従来の会計ソフトのようにパソコンにインストールする必要がありません。インターネット上にある同じデータを見ながら利用でき、自動で銀行口座やクレジットカードのデータを取り込むことができ、税理士事務所にもお客様にも、大きなメリットがあります。</p>
      <p>間違いなくこれからの時代の主流になる会計ソフトですが、ITに弱い税理士は対応できません。クラウド会計に対応していない税理士事務所は古いやり方をしている、と考えてもらって差し支えありません。</p>

      <p>当事務所はクラウド会計に完全対応しています。メジャーなクラウド会計のソフトとしてfreee(フリー)とＭＦ（マネーフォワード）があります。もちろん当事務所はどちらの会計ソフトも対応しており、特にfreeeについては認定アドバイザー登録済み、操作について上級エキスパートの資格も保有しています。</p>

      <p>クラウド会計を導入することによるメリット、導入方法仕、他の会計ソフトからのデータ移行等、全てサポートいたします。別料金にはなりますが、導入の際の初期設定にも当事務所で代行しています。</p>

      <p>また、クラウド会計以外の会計ソフトについても対応しています。慣れているソフトがあれば引き続きお使いいただければ構いません。クラウド会計専門ということではなく、必ずこのソフトにしてください、といったことはありませんのでご安心ください。</p>
      <div class="freee-cover">
        <img
            :src="`${rootUrl}/img/moneyforword.png`"
            alt="フリー"
            class="money"
          />
        <img
            :src="`${rootUrl}/img/yamada-yudai-freee logo.png`"
            alt="フリー"
            class="freee"
          />
          </div>

      <h3 class="green">積極的なITの活用</h3>
      <p>当事務所は積極的にIT化を進めています。
        <ul>
          <li>確定申告の完全電子化（紙ではなくパソコン上で確定申告書を提出）</li>
          <li>事務所内のペーパーレス化（データとして保存し、基本的に紙を使わない）</li>
          <li>徹底的なセキュリティ対策（お客様の個人情報を守るために）</li>
          <li>最新のパソコン等の機器の導入（仕事の高速化、正確化）</li>
          といったことが中心になります。
        </ul>
      </p>

      <p>
        ＩT化を進める理由は、事務所の情報を整理し無駄を無くすことで業務を効率化し、お客様への情報発信を早くして本業に集中してもらいたい、という事務所の大きな目標があるからです。
      </p>

      <p>確定申告書を未だに紙で提出されている税理士事務所もあるようですが、
        <ul class="green">
          <li>パソコンで提出したほうが郵送ではないので完成した瞬間に提出できる</li>
          <li>期限内なら何度でも訂正できる</li>
          <li>申告書がデータとして税理士事務所が保管できるから必要な時にいつでもお客様に渡せる</li>
        </ul>
        といったように、メリットしかありません。
      </p>

      <p>
        事務所が紙であふれていては整理することも資料を探す時間も無駄です。古いパソコンを使って動作がノロノロで時間がかかるのも無駄です。全てデータで管理し、必要な情報をいつでもすぐに取り出せるようにしています。</p>
      <p>
        例えば、お客様から電話があった際、その瞬間にデータを見てお話しできるのか、資料を探して時間をかけてから改めてお電話をするのか、どちらがお客様のためになるかは一目瞭然です、お客様とお会いする際も大量の資料を持っていくことはありません。全ての情報が入ったタブレット一つでお話しできるので、きめ細かい対応が可能です。
      </p>
      <p>
        もちろんデータについては徹底的なセキュリティ対策にお金をかけ、情報の保護は完璧に行っています。
      </p>

      <h3 class="green">資料の受け渡し・連絡の取り方はどんな方法でも大丈夫！</h3>
      <p>当事務所は様々な形でお客様との連絡と資料の受け渡しを行っています。
</p>
      <p>連絡方法として対応しているもの
        <ul class="green">
          <li>電話、メール、LINE、チャットワーク、ZOOM、SMS</li>
        </ul>
      </p>
      <p>また、独自の連絡方法を使いたい、という方につきましては、当事務所で対応可能であれば対応いたします。</p>

      <p>資料の受け渡し方法として対応しているもの
        <ul class="green">
          <li>実際にお会いしての受け渡し</li>
          <li>郵送での受け渡しを（封筒・レターパック・段ボール等）</li>
          <li>メールやLINE、チャットワークなどのツールを用いてエクセルデータ、PDF、写真等の受け渡し</li>
          <li>クラウドなどの共有フォルダでのデータの共有（Dropbox、OneDrive、GoogleDrive等）</li>
          <li>クラウド会計などを導入していただいた場合の当事務所へのアカウントの権限付与</li>
        </ul>
        その他ITツールを用いた方法についてはどんな方法でも対応いたします。
      </p>

      <p>これらの連絡方法や資料の受け渡し方法については、お客様の状況に応じて提案をしますので、負担のかからない方法を話し合っていきましょう。お客様がやったことはないけど、便利ならチャレンジしてみたい、というご要望があれば喜んでサポートさせていただきます。当事務所とのやり取りをスムーズに行うためのパソコンの設定については、ご自身でやっていただくこと以外にも、遠隔操作ソフト（TeamViewerやクロームリモートデスクトップなど）を使い、当事務所がお客様のパソコンを遠隔（お会いすることなく）操作しサポートすることも可能です。口頭で操作をお教えするより効率的な場合がありますので是非ご相談ください。</p>

      <h3 class="green">税理士もお客様もITを導入すると良いことだらけ？</h3>
      <p>世の中の進歩とともに新しいツールや仕組みが次々と誕生し、事業を行う人においてはそれらの情報を手に入れ、使いこなしていくことが事業の発展に必要不可欠となります。以下に挙げるのはその一例です。</p>

      <ul>
        <li>キャッシュレス化</li>
        <li>レジなどの決済のデジタル化</li>
        <li>ネットバンキングやネットのクレジットカード</li>
        <li>各種料金の支払い方のデジタル化</li>
        <li>クラウドでのデータ管理、共有</li>
        <li>手書きの書類のデジタル化</li>
        <li>新しいデジタルデバイス（パソコン、プリンター等）の導入</li>
        <li>マイナンバーカードの正しい利用方法</li>
        挙げたらきりがありません。また、今後も増えていくことでしょう。
      </ul>
      <p>当事務所は上記のものは全て導入しており、新しいものも積極的に導入しています。事務所の業務をミスなく効率的にするため、そして何よりお客様のメリットになるものを紹介できると考えているからです。
      </p>
      
      <ul class="none">
        <li>「便利だとは聞いているけどやり方がわからないからなあ・・・」</li>
        <li>「そもそもやったらどんなメリットがあるの？」</li>
        <li>「これを導入しようと思うんだけどアドバイスが欲しい！」</li>
      </ul>
        <p>こんなお客様からのご要望が増えています。このような声に応えるため、実際に使って中身を検証し、良いもののみ紹介しています。これらのツールや仕組みの導入については、顧問契約に含めてサポートしていますので別料金をいただくことはありません。</p>

      <p>多くの小規模事業者を顧問して最近強く感じることがあります。それは、ITに強い事業者の方が売上も利益も多いということです。常に変化をすることを恐れず、新しいことにチャレンジする気持ちがあるからだと思います。導入が遅れている業種もあります。積極的に導入いただくことにより、効率化し、お互いの負担が減れば、顧問料の割引もご提案できます。ぜひ最新のITを用いてライバルに差をつけ、事業を発展させましょう。</p>
      
      <h3 class="green">税金のこと、どうやって調べていますか？</h3>
      <p>皆さんは税金のことを調べるときにどうしていますか。スマートフォンやパソコンを使って検索しますか？同業者や知り合いに相談をするでしょうか？さて、その税金の情報、本当に正しいものでしょうか？</p>

      <p>世の中には税金の素人が記載したインターネットの情報や、素人からの間違ったアドバイスが大量にうごめいています。根本的に間違っているものはもちろん、中には法律違反の脱税指南のようなものもあります。これは非常に危険なことです。</p>

      <p>一番の深刻な問題は、その情報を鵜吞みにして、損をするのも責任をとるのも「皆様自身である」ということです。知らない間に脱税をしており、税務調査をされ、ペナルティを含め多額の税金を負担することにもなりかねません。情報を提供した人は一切責任を取ってくれません。</p>

      <p>本当に必要な情報はそんなに気軽に手に入るものではありません。お客様に必要なものは「生きた、正しい情報」です。インターネットに載っているような教科書のような情報ではなく、お客様の事情に合った具体的な情報ということです。</p>

      <p>税理士の存在意義はここにあります。私たちはそんなときのために税の専門家として日々勉強を重ね、最新の情報に目を配っているのです。自分で時間をかけて調べて間違った情報を手に入れるくらいなら税理士に相談してください。</p>
      
      <p>当事務所は脱税の指南は一切いたしません。その代わりといっては何ですが、法律に基づいた、皆様が一番必要な情報を提供します。この情報化社会の中、世間に広がっている間違った税金の情報を正していくために積極的に情報を提供し、発信していくことが税の専門家としての使命だと考えています。</p>

      <p>当事務所ではSNSやブログを通して実務的で正しい情報を発信してまいります。そちらもぜひご覧ください。</p>



    </div>
      <UiMiniToTopButton :type="'B'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.freee-cover {
  text-align: right;
  @media screen and (max-width: 768px) {
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
  }
  .freee{
    width: 200px;
    @media screen and (max-width: 768px){
      width: 150px;
    }
  }
  .money{
    width: 250px;
    margin-bottom: 10px;
    margin-right: 40px;
      @media screen and (max-width: 768px) {
        width: 200px;
        margin-right: 0px;
  }
  }
}
</style>
