<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'04'"
      :type="'B'"
      :barText="'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）を中心とした地域密着型の迅速な対応'"
      class="space-100"
    />
    <div class="description-area wrap">
      <p>
        山田雄大税理士事務所は、お客様の悩みやトラブルを一分一秒でも早く解決したいと考えています。重要で緊急を要する相談は、電話でもメールでもなく、お会いして顔と顔を合わせて話す必要があります。地域に密着し、対応エリアを限定することで、「すぐに」、「いつでも」会うことができます。地理的な距離だけではなく、こころの面でもお客様の近くで寄り添える事務所であることが選ばれている理由です。
      </p>
      <p>
        当事務所が地域密着を方針としているのは、「お客様にすぐに会うことができる」、という理由だけではなく、以下のメリットがあります。
      </p>
      <h3 class="green">資料の受け渡しが柔軟にできる</h3>
      <p>
        税理士事務所の仕事はお客様から資料をいただくところからスタートします。資料の受け渡しにつき、郵送やデータではなく直接お会いして渡したいというお客様もいらっしゃいます。その際、距離が近いのでお互いのお金も時間も節約できます。定期的に訪問する契約であれば、資料をいただく際にお話をすることもできます。
      </p>
      <h3 class="green">地域の情報を共有できる</h3>
      <p>
        同じ地域で活動していることで地域の情報を共有できます。例えばこういう業種が盛り上がっている…、この辺りは地価が高い…、この地域は外国人が多い…、といった地域独自の情報です。このような情報を共有していることでより具体的なお話ができます。また、地域独自の助成金や補助金といった制度についてもご案内することができます。
      </p>
      <h3 class="green">税務署や銀行へ税理士が同行できる</h3>
      <p>
        事業をされる中では、お客様自身が様々な場所へ行かなければなりません。銀行融資の相談や税務署への納税相談などがその一例です。その際、お客様一人で行くのではなく、顧問税理士が同行することで信用が大きく変わります。これも距離が近いことのメリットです。
      </p>
      <h3 class="green">専門家の紹介やマッチングができる</h3>
      <p>
        事業を行う上で、様々な分野の専門家に仕事を依頼することがあるでしょう。当事務所は同じ地域の信頼できる専門家を紹介しています。弁護士や司法書士といった資格の専門家を始め、不動産、保険、さらにはホームページ作成といった分野の専門家も紹介し、トータルで事業のサポートをしています。また、お客様同士のマッチングも行っています。同じ地域で色々な職種の方の顧問をさせていただいていることから可能なことです。
      </p>
      <h3 class="green">お仕事の状況を目で確認できる</h3>
      <p>
        実際に会社やご自宅へ伺うことができるので、どのような環境でお仕事や生活をされているかを目で確認できます。実際にお客様のお店に一人の客としてお邪魔することもあります。実際に目で見ることにより、状況に合わせた具体的なアドバイスができるようになると考えています。
      </p>
    </div>
    <UiMiniToTopButton :type="'B'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
