<template>
  <div class="home-map">
    <div class="wrap">
      <UiHeading :h="'2'" :type="'A'" :text="'対応エリア'" />
      <UiLine />

      <div class="map">
        <div class="top-text">
          <p>
            山田雄大税理士事務所は上記地域に対応エリアを限定しています。<br />地域密着型の税理士事務所を目指し、以下のことをお約束します
          </p>
          <ul>
            <li>お客様の拠点に訪問します。</li>
            <li>当事務所へお越しいただきやすいです。</li>
            <li>迅速な移動による対応ができます。</li>
            <li>役所への手続きなどに代わりに行くことができます。</li>
            <li>地域の特性を理解した上でお客様の相談に乗ります。</li>
            <li>地域の情報や状況を共有できます。</li>
            <li>
              近隣エリアの専門家の紹介など、地域のネットワークを生かしたトータルサポートをします。
            </li>
          </ul>
          <p class="note">
            ※その他のエリアについてもお仕事のご依頼は可能です。小規模事業者を専門とした税理士事務所ならではのメリットから、エリア外のお客様も多数みえます。お気軽にご相談ください。
          </p>
        </div>
        <div class="bottom-cover">
          <div class="left">
            <div class="center-text">
              <div class="title"><p>対応エリア</p></div>
              <p>
                あま市<span>稲沢市</span><span>清須市</span><span>津島市</span><span>愛西市</span><span>大治町</span>蟹江町<span>北名古屋市</span><span>弥富市</span>
              </p>
            </div>

            <div class="bottom-text">
              <p>
                お客様が税理士事務所と付き合う際に、その税理士事務所がどこにあるのか、活動地域がどこなのか、ということはとても大事です。税理士がお客様に代わって税務署や役所へ行くことがあり、また地域によって税金の仕組みが違うこともあります。同じ地域の税理士と付き合うことで、お客様の地域性に合わせたサポートが可能となります。山田雄大税理士事務所はお客様に仕事の面で寄り添うことはもちろん、距離においても密着したサポートができるよう、上記地域のお客様とお付き合いをさせていただいております。
              </p>
            </div>
          </div>
          <img :src="`${rootUrl}/img/tizu202209.png`" alt="対応エリア" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "HomeReason",
  components: {
    UiHeading,
    UiLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.map {
  // height: 1700px;
  // background-size: contain;
  // background-repeat: no-repeat;
  // position: relative;

  // @media screen and (min-width:979px) and ( max-width:1024px){
  //   height: 2000px;

  // }
  // @media screen and (max-width: 979px) {
  //   background-image: none !important;
  //   height: auto;
  // }
  .top-text {
    ul {
      list-style-type: disc;
      li {
        margin-left: 20px;
        letter-spacing: 0.1px;
      }
    }
    .note{
      margin-top: 30px;
    }
  }
  .bottom-cover {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
.left{
  width: 30%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .center-text {
    font-size: 15px;
    margin: 50px 0;

    @media screen and (max-width: 979px) {
      width: 100%;
    }

    .title {
      background-color: var(--green);
      color: white;
      text-align: center;
      margin-bottom: 20px;
    }
    .sub {
      font-size: 16px;
      color: var(--green);
    }
    span {
      margin-left: 15px;
    }
    .no2 {
      margin-left: 11px;
    }
  }
  .bottom-text {
  }
}
img{
  width: 65%;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 30px;
  }
}
  }
}
</style>
