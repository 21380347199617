<template>
  <div class="zeimukomon-troubles">
    <div class="wrap">
      <UiHeading
        :h="'1'"
        :type="'A'"
        :text="'税務について、こんなお悩みはありませんか？'"
      />
      <UiLine />

      <UiBar3List :barList3="barList3" :good="false"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBar3List from "@/components/Ui/Bar3List.vue";
export default {
  name: "zeimukomon-trouble",
  components: {
    UiHeading,
    UiLine,
    UiBar3List,
  },
  data() {
    return {
      barList3: [
        {
          id: 1,
          type: "A",
          barText: "確定申告ができない、経理や帳簿付けができない",
        },
        {
          id: 2,
          type: "B",
          barText: "確定申告や経理について時間が取られて困っている",
        },
        {
          id: 3,
          type: "A",
          barText: "事業を始めたのはいいが、何から手を付けていいかわからない",
        },
        {
          id: 4,
          type: "B",
          barText:
            "自分で確定申告をしているが税金の知識がないので損をしている気がする",
        },
        {
          id: 5,
          type: "A",
          barText:
            "税金についてしっかりした知識がある人が身近におらず、誰にも相談できない",
        },
        {
          id: 6,
          type: "B",
          barText: "事業が大きくなってきたのでそろそろプロに任せたい",
        },
        {
          id: 7,
          type: "A",
          barText: "税務署に目を付けられているのではないかと心配だ",
        },
        {
          id: 8,
          type: "B",
          barText:
            "真実かわからないインターネットで税金のことを調べることに疲れた",
        },
        {
          id: 9,
          type: "A",
          barText: "消費税の申告が必要になったがやり方がわからない",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.request-box {
  display: flex;
  justify-content: space-between;
  .photo-cover {
    width: 50%;
    img {
      vertical-align: bottom;
      width: 100%;
    }
    .name {
      background-color: var(--black);
      color: white;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 0;

      span {
        margin-left: 10px;
      }
    }
  }
  p {
    width: 48%;
  }
}
</style>
