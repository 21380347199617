<template>
  <div class="home-service">
    <UiHeading :h="'2'" :type="'A'" :text="'法人設立・開業についてよくある質問'" />
    <UiLine />


    <UiBarPlus    
      :type="'A'"
      :titleBox="true"
      :barNo="'Q'"
      :barText="'開業する前か後か、どのタイミングで相談した方がいいですか？'"
       :barAnswer="'どのタイミングで相談いただいても大丈夫です。ただ、開業する前の方が、準備段階で進められることがありますのでおすすめです。開業した後でもご相談いただければより良い形を提案できますので、税理士に相談していない、経理に手を付けずに放置している、そんな場合でもご相談ください。'"
      />
      <div class="answer">
  <!-- <p><button v-on:click="show=!show">切り替え</button></p> -->

</div>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'会社か個人事業か、どちらで始めたほうがいいか迷っています。'"
       :barAnswer="'どちらにもメリットとデメリットがありますから、まずはそこを丁寧に説明いたします。また、事業の内容や状況、今後の展望など、事業の方向性についてお聞きします。どちらかに当事務所が誘導するようなことはせず、お客様に合う形態はこちら、という専門家としての客観的な提案を差し上げます。</p>'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'相談の際、具体的にどんなことを話すのですか？'"
      :barAnswer="'どんな事業を考えているか、お金の動きや管理はどうか、開業した後の流れ、税理士に依頼したいことは何か、こんなことを中心にお聞きします。こちらから質問いたしますから、身構えず話していただければ大丈夫です。特に用意していただくものはありませんし、どんなことでも聞いてください。<br>事前に疑問点などを整理していただくとスムーズです。もちろん何もわからないから教えてください！というご相談も大歓迎です。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'どんな業種の開業も対応してくれますか？'"
      :barAnswer="'基本的にどんな業種でも大丈夫ですが、一部受けられない業種もありますので、お問い合わせの際に事業の概要を教えていただければ、対応できるかどうかすぐにご回答差し上げます。（当事務所の現在の顧問先業種一覧を参考にしてください）開業に伴い許認可が必要な事業の場合は、提携する行政書士を紹介いたします。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'法人成りを考えており、個人事業の時は税理士に依頼していませんが大丈夫ですか？'"
      :barAnswer="'法人成りされる前に税理士をつけていなくても問題ありません。個人の確定申告書を拝見し、ヒアリングを行った上で税金上問題のない形で法人成りできるようにサポートいたします。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'最初はすぐに売上が上がらないかもしれないから、顧問料が払えるか心配です。'"
      :barAnswer="'開業してからしばらくの間の支払いが難しい場合などは、事業が軌道に乗った後、まとめてお支払いいただくことも可能です。また、開業当初のみ、一部の作業をお客様ご自身でやっていただき。顧問料を後から見直すといった対応もしております。柔軟に対応いたしますのでご心配されることなくご相談ください。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'税理士によってやり方（経費にしてくれるものなど）が違うって聞いたけど本当ですか？'"
      :barAnswer="'基本的に経理のやり方についてはルールが決まっているので、どの税理士に依頼しても大きく変わることはありません。経費など、事業の内容や払った経緯などをお客様としっかり話し合った上で個別に判断いたします。何でもかんでも経費にしますよ、といったスタンスは危険ですから採用していません。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'創業した際に融資を受けられると聞いたけれどサポートしてくれますか？'"
      :barAnswer="'主に日本政策金融公庫の「新規開業資金」制度を活用することをお勧めし、そのサポートをしています。ご希望の銀行や信用金庫がある場合に関しても対応いたします。事業計画書の作成や見積書を収集する必要があることから、開業前のお早目のご相談をおすすめします。'"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarPlus from "@/components/Ui/BarPlus.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiBarPlus,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>



</style>
