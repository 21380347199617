<template>
  <div class="ui-bar2">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
      <div class="outer">
        <img
          :src="`${rootUrl}/img/yamada-yudai-kao2.png`"
          alt="tsura-chan"
          v-if="good === true"
        />
        <img
          :src="`${rootUrl}/img/yamada-yudai-kao.png`"
          alt="tsura-chan"
          v-else-if="good === false"
        />

        <div class="bar-box">
          <div class="bar-text">
            <p>{{ barText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBar3",
  components: {
    // UiDetailButton,
  },
  props: {
    barText: String,
    type: String,
    good: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }

  img {
    width: 105px;
    @media screen and (max-width: 767px) {
      width: 50px;
      margin-top: 10px;
    }
  }
  .bar-box {
    width: 88%;
    height: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 10px 0 10px 10px;
    padding: 7px 10px;
    max-width: 100%;
    background: #e0edff;
    @media screen and (max-width: 767px) {
      height: 100px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -30px;
      margin-top: -15px;
      border: 15px solid transparent;
      border-right: 15px solid #e0edff;
      @media screen and (max-width: 767px) {
        border: 10px solid transparent;
        border-right: 10px solid #e0edff;
        left: -20px;
        top: 28%;
      }
    }
    p {
      font-size: 26px;
      color: white;
      // max-width: 98%;
      // word-break: break-all;
      margin-left: 10px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}
.typeA {
  .bar-box {
    background-color: var(--gray);
    &:before {
      border-right: 15px solid var(--gray);
    }
  }
}
.typeB {
  .bar-box {
    background-color: var(--blue);
    &:before {
      border-right: 15px solid var(--blue);
    }
  }
}
</style>