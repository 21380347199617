<template>
  <div class="spot">
    <div class="space-150">
      <SpotRequest />
    </div>

    <div class="space-150">
      <SpotPrice1 />
    </div>

    <div>
      <SpotPrice2 />
    </div>

    <UiToContact />
    <div class="wrap">
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SpotRequest from "@/components/Spot/Request.vue";
import SpotPrice1 from "@/components/Spot/Price1.vue";
import SpotPrice2 from "@/components/Spot/Price2.vue";
import UiToContact from "@/components/Ui/ToContact.vue";
// import UiToLine from "@/components/Ui/ToLine.vue";
// import HomeArticle from "@/components/Home/Article.vue";

export default {
  name: "spot",
  components: {
    SpotRequest,
    SpotPrice1,
    SpotPrice2,
    UiToContact,
    // UiToLine,
    // HomeArticle,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>

</style>
