<template>
  <el-row>
    <el-col>
      <el-button
        @click="showImageEditor = true"
        class="image-button"
        size="medium"
        ><i class="el-icon-picture-outline"></i>メディアを追加</el-button
      >
      <Editor
        v-model="localText"
        api-key="7alh0cm0yvwirih5m74eahjpy8clolqyvaryadnk249fv34e"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo | redo | forecolor backcolor fontsizeselect | link | bold underline strikethrough| alignleft aligncenter alignright alignjustify | bullist | numlist | preview | ',

          //入力時に改行が<p>にならないように
          force_br_newlines: true,
          force_p_newlines: false,
          forced_root_block: '',

          //テキストからコピペするときに改行が<p>にならないように
          paste_as_text: true,

          // data-mce-srcが入らないように！！！
          relative_urls: false,
          remove_script_host: false,
        }"
      />

      <div v-show="showImageEditor === true">
        <div
          @click="showImageEditor = false"
          class="image-editor-background"
        ></div>
        <div class="image-editor">
          <div class="image-editor-header">
            <h3>メディアを追加</h3>
            <div @click="showImageEditor = false" class="close-button">×</div>
          </div>
          <el-tabs type="card" v-model="imageEditorTab">
            <el-tab-pane label="ファイルをアップロード" name="upload">
              <div class="file-select-button-cover">
                <label for="file-select">
                  <div class="file-select-button">ファイルを選択</div>
                </label>
                <p>最大アップロードサイズ: 1 MB。</p>
              </div>
              <input
                style="display: none"
                id="file-select"
                name="file"
                type="file"
                @change="uploadImage($event)"
                accept="image/*"
              />
            </el-tab-pane>
            <el-tab-pane label="メディアライブラリ" name="images">
              <p style="text-align: center; font-size: 22px">画像一覧</p>
              <div class="button-line">
                <el-button @click="addImg" type="primary">記事に追加</el-button>
                <el-button @click="delImg" type="danger">削除</el-button>
              </div>
              <div class="img-panel-cover">
                <div class="img-panel">
                  <div
                    v-for="(imgPath, index) in imageList"
                    :key="index"
                    class="img-unit"
                    :class="{ selected: targetImgIndex === index }"
                  >
                    <img
                      :src="`${rootUrl}${imgPath}`"
                      @click="selected(index, imgPath)"
                    />
                    <!-- <img :src="rootUrl+imgPath"> -->
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TinyMceEditor",
  components: {
    Editor,
  },
  props: {
    text: String,
  },
  data() {
    return {
      showImageEditor: false,
      imageEditorTab: "upload",
      targetImgIndex: "",
      targetImgPath: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "imageList"]),
    localText: {
      get() {
        return this.text;
      },
      set(newValue) {
        this.$emit("update:text", newValue);
      },
    },
  },
  watch: {
    propsText(newValue) {
      this.text = newValue;
    },
    text(newValue) {
      this.$emit("text", newValue);
    },
  },
  methods: {
    uploadImage(event) {
      let formData = new FormData();
      formData.append("uploaded_file_data", event.target.files[0]);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rootUrl}/api/upload-image.php`, formData, config)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.imageEditorTab = "images";
            this.$store.dispatch("setImageList");
            return;
          } else if (res.data.status === 204) {
            //ファイル未選択
            return;
          } else if (res.data.status === 400 || res.data.status === 500) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert("エラーだよ----");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addImg() {
      this.localText += `<img src="${this.rootUrl}${this.targetImgPath}">`;
      alert("記事に画像を挿入しました！");
      this.showImageEditor = false;
      // this.$router.go(-1);
    },
    delImg() {
      if (this.targetImgPath !== "") {
        if (confirm("選択している画像を削除してもいいですか？")) {
          let params = new URLSearchParams();
          params.append("img_path", this.targetImgPath);
          console.log("コレ" + this.targetImgPath);
          this.$axios
            .post(`${this.rootUrl}/api/del-img.php`, params)
            .then((res) => {
              console.log(res);
              if (res.data.status === 200) {
                this.$store.dispatch("setImageList");
                alert("削除しました。");
                return;
              } else if (res.data.status === 400 || res.data.status === 500) {
                alert(res.data.message);
                return;
              } else if (res.data.status === 401) {
                alert(res.data.message);
                this.$router.push("/login");
              } else {
                alert("エラーだよ----");
                return;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          alert("画像の削除を取り消しました");
        }
      } else {
        alert("画像を選択してください");
      }
    },
    selected(imgIndex, imgPath) {
      this.targetImgIndex = imgIndex;
      this.targetImgPath = imgPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-button {
  margin-bottom: 5px;
}

//image-editor
.image-editor-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.image-editor {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 95%;
  height: 95%;
  background-color: #fff;
  .image-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 10px 20px;
    }
    .close-button {
      font-size: 24px;
      font-weight: bold;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .file-select-button-cover {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .file-select-button {
      font-size: 18px;
      padding: 15px 30px;
      color: #0071a1;
      background: #f3f5f6;
      border: solid 1px #0071a1;
      border-radius: 5px;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
    p {
      font-size: 14px;
    }
  }
}
.img-panel-cover {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
}
.img-panel {
  width: 99%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 630px;
  overflow-y: scroll;
}
.img-unit {
  padding-top: 9%;
  width: 9%;
  border: 1px solid gray;
  position: relative;
  margin: 1%;
  //   height: 100%;
  // overflow-y: scroll;

  img {
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 80%;
    height: 100%;
    align-self: flex-start;
  }
}
.selected {
  border: solid 2px #409eff;
}
.button-line {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  .el-button {
    width: 200px;
  }
}
</style>
