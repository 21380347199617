<template>
  <div class="company-vision-reason">
    <HomeReason :button="false" :h="'1'" :noteShow="true"
    />
    <div class="wrap">
<div class="photo">
        <img
        :src="`${rootUrl}/img/yamada-yudai-erabareru01.jpg`"
        alt="選ばれる理由イメージ1"
      />
        <img
        :src="`${rootUrl}/img/yamada-yudai-erabareru02.jpg`"
        alt="選ばれる理由イメージ1"
        class="sp-none"
      />
        <img
        :src="`${rootUrl}/img/yamada-yudai-erabareru03.jpg`"
        alt="選ばれる理由イメージ1"
        class="sp-none"
      />
</div>
</div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeReason from '@/components/Home/Reason.vue'

export default {
  name: "CompanyVisionReason",
  components: {
HomeReason
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.photo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: 767px){
    margin-top: 0;
   
  }
  img{
    width: 30%;
    @media screen and (max-width: 767px){
      width: 100%;
       margin-bottom: 50px;
    }
  }
}
.sp-none{
  @media screen and (max-width: 767px){
    display: none;
  }
}
</style>
