<template>
  <div class="layout-footer">
    <div class="copyright">
      <p>Copyright © <br class="sp-kaigyo">山田雄大税理士事務所 All Rights Reserved.</p>
      <img :src="`${rootUrl}/img/yamada-yudai-logo02.png`" alt="ロゴ" />
    </div>
    <div class="mobile-fixed">
      <div class="contact">
      <router-link
          to="/contact"
          >
        <img :src="`${rootUrl}/img/mail-ft.png`" alt="ロゴ" /><span>Webでのお問い合わせ</span>
        </router-link
        >
      </div>
        <div class="tel">
      <a href="tel:0527003238">
          <img :src="`${rootUrl}/img/tel-ft.png`" alt="ロゴ" /><span
            >お電話でのお問い合わせ</span
          >
        </a>
        </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.layout-footer {
  background-color: var(--green);
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    align-items: center;
    margin-bottom: 40px;
  }

  .copyright {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
    color: white;
    margin-right: 120px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
    p {
      margin-bottom: 10px;
      @media screen and (max-width: 767px) {
        line-height: 1.1em;
      }
    }
    img {
      width: 60px;
    }
  }
  .mobile-fixed {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: space-around;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 2.5em;
      background-color: rgba(255, 255, 255, 0.7);
      align-items: center;
      z-index: 999;

      a{
        text-decoration: none;
        color: white;

      }

      .contact,
      .tel {
        width: 48%;
        text-align: center;
        padding: 10px 0;
        font-size: 13px;
        vertical-align: middle;
        margin: 0;

        img {
          width: 15px;
          vertical-align: middle;
          margin-right: 3px;
        }
        span {
          vertical-align: middle;
        }
      }
      .contact {
        // background-color: #CBE9E9;
        background-color: var(--green);
        color: white;
      }
      .tel {
        background-color: var(--blue);
        color: black!important;
        // font
      }
    }
  }
}
</style>
