<template>
  <div class="home-industry">
    <UiHeading :h="'2'" :type="'A'" :text="'クライアントの業種'" />
    <UiLine />
<div class="outer"> 
        <UiListItem :ListItem="ListItem"/>
</div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiListItem from "@/components/Ui/ListItem.vue";


export default {
  name: "HomeIndustry",
  components: {
    UiHeading,
    UiLine,
    UiListItem,
  },
  data() {
    return { 
      ListItem:[
        {
          id:1,
          type: "A",
          listCategory:"店舗・販売系",
          listDetail:"飲食店、美容院、小売（店舗）、ＥCサイト、ネットショップ(国内)、ネットショップ(海外)、せどり、ナイトクラブ、アパレル（店舗）、ヨガ教室、マッサージ、ネイルサロン、パーソナルジム、ペットトリミング、雀荘、ウーバーイーツ、古物商、遺品整理、お菓子製造",
        },
        {
          id:2,
          type: "A",
          listCategory:"建設業関連業",
          listDetail:"建設全般、建設（一人親方）、リフォーム、内装、外壁工事、塗装、設備、電気工事、配管工、とび、足場、造作大工、外構、造園、荷揚、住宅点検",
        },
        {
          id:3,
          type: "A",
          listCategory:"自動車関連業",
          listDetail:"自動車整備、自動車板金、自動車販売（オークション代行）、カーコーティング",
        },
        {
          id:4,
          type: "A",
          listCategory:"運送関連業",
          listDetail:"運送業（トラック）、軽貨物（赤帽）、陸送業、配送業(ヤマト運輸）",
        },
        {
          id:5,
          type: "A",
          listCategory:"サービス業",
          listDetail:"不動産賃貸業、アフィリエイト業、不動産仲介業、印刷業、WEBマーケティング、インターネット関連業、清掃業、外国人派遣業、通信業、太陽光発電、広告業、椅子製造、金属研磨",
        },
        {
          id:6,
          type: "A",
          listCategory:"専門職",
          listDetail:"歯科医、大学教授、社会保険労務士、経営コンサルタント、医療コンサルタント、保険代理店",
        },
        
      ]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer{
  padding-top: 50px;
   @media screen and (max-width: 767px){
     padding-top: 0;
   }
}
</style>
