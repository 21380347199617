<template>
  <div class="ctrl">
    <TestDraggable />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TestDraggable from "@/components/Test/Draggable.vue";
export default {
  name: "Ctrlsort",
  components: {
   TestDraggable,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
