<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'02'"
      :type="'B'"
      :barText="'税理士資格を保有する30代の所長が直接お客様を担当'"
      class="space-100"
    />
    <div class="description-area wrap">
      <p class="bottom-0">
        山田雄大税理士事務所では以下の理由から、代表税理士が直接お客様との対応、相談を行っております。
      </p>
      <ol class="green">
        <li>
          お客様は専門家である税理士を信用して「税理士本人」に仕事を依頼している
        </li>
        <li>税理士本人に会って相談することがお客様の安心につながる</li>
        <li>
          お客様のお金の相談は税金のことだけではなく、人生に関わることがある
        </li>
        <li>税理士資格のない事務員が対応することでトラブルの発生が多くなる</li>
        <div class="stands-out">
        
         <span>※連絡や資料の受け渡し等、一部を職員が担当する場合があります。</span>
        </div>
      </ol>
      <h3 class="green">税理士事務所ではなく税理士に依頼するメリット</h3>
      <p>
        税理士事務所に仕事を依頼するのはなぜでしょうか。自分で税金の計算ができない、といった基本的なご要望から、顧問として事業の税金の相談に乗ってほしい、といった理由が多いと思います。
      </p>
      <p>
        一つ言えることは、皆様はこれらのことを税の専門家である税理士本人に依頼するべき、ということです。税理士事務所に依頼しているというより、税理士に依頼しているわけで、税理士事務所の職員に依頼しているわけではありませんね。
      </p>
      <p>
        最近では税理士業界も大規模な事務所がたくさんできており（税理士事務所を会社で運営する税理士法人など）、そういった大手の事務所に依頼する方も多いです。大きな事務所は、それぞれの税金の専門の部署があり、得意分野のある税理士がいます。もちろん大規模ゆえ、お客様からの依頼が多いので、サポートする事務員（税理士資格のない人）が多いのも特徴です。小さな会社や個人事業主はそのような事務員（税理士資格がない人）がお客様の担当になっていることがよくあります。
      </p>
      <p>
        何を隠そう私も税理士になるまでは以前勤務していた事務所で担当をもって仕事をしていました。もちろん資格がなくても長年経験している方や、勉強をして税理士並に知識がある事務員もいます。ただ、事務員は税理士に雇われているあくまで「従業員」です。税金のトラブルがあった時に従業員は責任を取れませんし、特に経験の浅い事務員の場合、間違ったアドバイスをすることもあるでしょう。
      </p>
      <p>
        私もミスをしないようにびくびくしながら担当をしていました。あなた税理士なの？資格ないのに大丈夫？と言ったことを尋ねられました。
      </p>
      <p>
        当事務所ではお客様との対応、相談は税理士である私が行っています。お客様が安心し、信頼していただいた上でお付き合いをしたいからです。直接話し、税金のことはもちろん、悩みや不安、会社の今後、人生設計（ライフプランニング）といったところまで深く相談していただける関係を目指しています。
      </p>
      <p>
        また、税理士本人が担当していることで、他の税理士事務所より料金が高いのでは？と思われることがあるかもしれませんが、当事務所は税理士本人ががお客様としっかり向き合うことができるような事務所の組織づくり・運営をしているため、そのようなことはありませんのでご安心ください。
      </p>
      <h3 class="green">税金の専門家だけでなく、お金の専門家として</h3>
      <p>以前お客様からこんなご相談をいただきました。</p>
      <p>
        お客様&nbsp;「2年後に新居を建てたいと考えていますがアドバイスはありますか？」<br />
        税理士&nbsp;「では今年の確定申告から所得を意識して準備しましょう、予算はいくらですか？」<br />
        お客様&nbsp;「だいたい○○万円くらいです。」<br />
        税理士&nbsp;「それでは所得金額がこれくらい必要です。その場合税金はこれくらいです…。」
      </p>
      <p>
        例えばお客様からこの話を聞くことがなければ、新居の計画が何年も遅くなるということになりかねません。このような相談は、お金全般の知識がある人間にしか適切なアドバイスをすることはできません。経験の浅い事務員では対応できないのです。
      </p>

      <p>
        当事務所がお付き合いしているお客様は小規模であるがゆえに、売上や利益が自身の生活に直結しています。当事務所の相談内容は税金のことだけではなく、「お金のこと全て」です。私はこのような相談に対応できるよう、家計のお金のプロであるファイナンシャルプランナーの資格も所持しています。税理士はみな「税金」のアドバイスはできます。しかし、お客様が求めているのは必ずしも税金の話だけではなく、「お金のこと全て」です。
      </p>

      <p>
        そんなときに皆様がまず相談してみよう、と思いつくのは顧問税理士ではないでしょうか。税金の話以外はわかりません、という税理士もいますが、私は、まずこの人に相談してみよう、と思ってもらえる税理士を目指しています。そのために日々お金に関する知識を習得すること努力を重ねています。どんなお金の相談にも適切なアドバイスをすることを約束します。
      </p>

      <h3 class="green">税理士事務所の事務員とは</h3>
      <p>税理士事務所には税理士資格のない事務員がいます。</p>
      <p>
        私は税理士事務所を開業する前、3つの税理士事務所で勤務しました。もちろん税理士の下で働く事務員という立場です。事務所の代表である税理士がどのような考えで事務所を運営していくかによって仕事内容が変わり、3つの事務所それぞれで全く違う仕事をしていました。
      </p>
      <p>
        税理士に代わってお客様の担当になり、確定申告書の作成や税金の計算まで任されていたところ、税理士のサポート業務が中心のところ、新規顧客を獲得するための営業活動や教材の開発をしていたこともあります。どの仕事も良い経験になりましたが、税理士事務所はどうしても事務員の仕事が固定化されてしまう傾向があります。結果、知識や経験に偏りができ、できることとできないことがはっきりします。資格のない従業員が顧客の担当をする税理士事務所も多くありますが、このような知識や経験が不足する事務員が担当になるとお客様に例えば以下のようなデメリットが生まれます。
      </p>

      <ul>
        <li>すぐに質問に答えてくれない</li>
        <li>自信がなさそう</li>
        <li>間違ったアドバイスをされた</li>
      </ul>

      <p>
        ビジネスは一瞬で判断しなければいけないことがあります。一つの判断ミスで経営に大きな影響を及ぼす可能性があります。
        このような理由から当事務所は税理士本人である私がお客様の担当をしています。従業員にはそれぞれのレベルや得意分野を考慮して仕事をしてもらい、ミスのない安定した事務所運営を心がけおります。
      </p>
    </div>
    <UiMiniToTopButton :type="'B'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.stands-out{
  padding: 15px 10px;
  margin: 15px;
    background-color: #dcf0f0;
    max-width: 520px;
  p{
    padding: 20px;
  }
}

</style>
