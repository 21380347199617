<template>
  <div class="ctrl">
 <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "ctrl",
  components: {

  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>

</style>
