<template>
  <div class="Introduction">
    <UiHeading
      :h="'1'"
      :type="'A'"
      :text="`山田雄大税理士事務所と<br class='sp-kaigyo'>代表「山田雄大」の紹介`"
    />
    <UiLine />

    <div>
      <div class="introduction-box">
        <div class="photo-cover">
          <img
            :src="`${rootUrl}/img/yamada-yudai-zeimukomon02.jpg`"
            alt="山田雄大"
          />
          <div class="name">税理士<span>山田雄大</span></div>
        </div>
        <div class="description">
          <table>
            <tr>
              <th>
                <div>事務所名</div>
              </th>
              <td>
                <div>山田雄大税理士事務所</div>
              </td>
            </tr>
            <tr>
              <th>
                <div>代表者名</div>
              </th>
              <td>
                <div>山田 雄大</div>
              </td>
            </tr>
            <tr>
              <th>
                <div>郵便番号</div>
              </th>
              <td>
                <div>497-0011</div>
              </td>
            </tr>
            <tr>
              <th>
                <div>住所</div>
              </th>
              <td>
                <div>愛知県あま市七宝町安松西高御堂2503 3階</div>
              </td>
            </tr>
            <tr>
              <th>
                <div>電話番号</div>
              </th>
              <td>
                <div>052-700-3238</div>
              </td>
            </tr>
            <tr>
              <th>
                <div>メール<br class="sp-kaigyo">アドレス</div>
              </th>
              <td>
                <div>yudai@tax-yamada.net</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "ConpanyIntroduction",
  components: {
    UiHeading,
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.introduction-box {
  display: flex;
  justify-content: space-between;
   @media screen and (max-width: 767px){
     display: block;
   }
  .photo-cover {
    width: 50%;
     @media screen and (max-width: 767px){
       width: 100%;
     }
    img {
      vertical-align: bottom;
      width: 100%;
    }
    .name {
      background-color: var(--black);
      color: white;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 0;

      span {
        margin-left: 10px;
      }
    }
  }
  .description {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
     @media screen and (max-width: 767px){
       width: 100%;
     }

    table {
      width: 90%;
      @media screen and (max-width: 767px){
        width: 100%;
        margin: 20px 0;
      }
      tr {
        text-align: left;
        th,
        td {
          padding: 16px 0;
          padding-left: 25px;
          border-top: solid 1px var(--black);
          border-bottom: solid 1px var(--black);
          vertical-align: middle;
        }
        th {
          width: 33%;
          div {
            border-right: solid 1px var(--black);
          }
        }
      }
    }
  }
}
</style>