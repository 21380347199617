var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ui-DetailButton"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      typeB: _vm.type === 'B',
      typeC: _vm.type === 'C',
    }},[_c('div',{staticClass:"cover"},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#app',
          container: 'body',
          duration: 750,
          easing: 'ease',
          offset: 0,
        }),expression:"{\n          el: '#app',\n          container: 'body',\n          duration: 750,\n          easing: 'ease',\n          offset: 0,\n        }"}],staticClass:"button"},[_c('p',[_vm._v(_vm._s(_vm.text))]),_c('div',{staticClass:"arrow"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }