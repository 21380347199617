<template>
  <div class="seminar">
    <div class="space-100">
      <SeminarAboutSeminar />
    </div>

    <div class="space-100">
      <SeminarUse />
    </div>

    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SeminarAboutSeminar from "@/components/Seminar/AboutSeminar.vue";
import SeminarUse from "@/components/Seminar/Use.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "seminar",
  components: {
    // HomeArticle,
    UiToContact,
    // UiToLine,
    SeminarAboutSeminar,
    SeminarUse,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
