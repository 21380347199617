<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'03'"
      :type="'A'"
      :barText="'お客様の事業内容や要望に合わせた最適かつ柔軟な料金設定'"
      class="space-100"
    />
    <div class="description-area wrap">
      <p>
        世の中の税理士事務所はお客様の事業の売上の金額で顧問料を決めているところが多いですね。
        なぜかというと、わかりやすいというのはもちろん、お客様の個別の事情を考慮するのが大変だからです。
        しかし、顧問料は売上だけで判断できるものではありません。
        そのため、当事務所では売上に応じた一律の顧問料という料金体系を取っていません。
        （公開している料金表は、参考になるようお客様の判断の目安を示すものです）
        当事務所には売上が多いのに顧問料が相場より安いお客様もいらっしゃいますし、反対に売上は少ないですが顧問料が相場よりも高いお客様もいらっしゃいます。
      </p>
      <p>
        お客様が税理士との顧問契約に求めるものは様々です。
        定期的に税理士と面談をしたい方、とりあえず顧問契約をして税金のリスクを減らしたい方、将来の事業拡大を見据えて開業から経理の体制を整えたい方…
        事業の内容も様々です。
        毎日たくさんの商品を仕入れて売るネット通販業、年に数回だけの売上しかない不動産業、全ての取引を現金で行う理容業…、
        個人の事情も様々です。
        簿記や経理の知識がある方、ITを使いこなせる方、クラウド会計をやってみたい方、資料の整理をバッチリやっていただける方…
      </p>
      <p>
        当税理士事務所では、様々な事情をお客様からしっかりとヒアリングした上で、最適な顧問契約の内容と顧問料の提示をいたします。
        双方納得した上で顧問契約をすることは、お客様だけでなく、税理士事務所にとっても必須であると考えています。
      </p>
      <h3 class="blue">顧問料が相場より高くなるケース</h3>
      <p>
        当事務所の考え方により、顧問料が相場より高くなるケースについてご紹介します。
      </p>
      <ol class="blue number">
        <li>事業の内容によるもの</li>
        <p class="black">
          複雑な取引や多種多様な取引がある場合は顧問料が高くなります。<br />
          例えばネット通販業で、Amazon、ヤフー、楽天、メルカリ…といった様々な媒体で大量の商品を販売しているような場合、売上や在庫と言った管理はもちろん、大量の取引全てを正確に経理することが大変だからです。
        </p>
        <li>お客様の要望によるもの</li>
        <div class="black">
          <ul>
            <li>月一回会って毎月の試算表を出してほしい</li>
            <li>従業員に定期的に経理の指導をして欲しい</li>
            といった場合です。
          </ul>
        </div>
        <li>お客様の個人の事情によるもの</li>
        <div class="black">
          <ul>
            <li>簿記や経理の知識が無いので全てお任せ</li>
            <li>ITが使えないので全て紙ベースでのやりとり</li>
            <li>レシート等の資料を整理する時間がないので丸投げ</li>
            <li>土日しか対応できない</li>
            といった場合です。
          </ul>
        </div>
      </ol>
      <p>
        もちろんこういったケースが悪いといったことではなく、どうしても顧問料が高くなってしまう理由をお客様自身が理解していただくことが大切であると考えています。
      </p>

      <h3 class="blue">顧問料が相場より安くなるケース</h3>
      <p>
        当事務所の考え方により、顧問料が相場より安くなるケースについてご紹介します。
      </p>

      <ol class="number blue">
        <li>事業の内容によるもの</li>
        <p class="black">
          取引が簡単で単純な場合は顧問料が安くなります。
          例えば毎月同じ金額の売上や経費しかないような不動産賃貸業のような場合です。
        </p>
        <li>お客様の要望によるもの</li>
        <div class="black">
          <ul>
          <li>
          定期的な面談を希望せず、何かあった時だけ面談をする</li>
          <li>税金やITの知識を積極的に身につけようと考えている</li>
          といった場合です。
          </ul>
        </div>
        <li>お客様の個人の事情によるもの</li>
        <div class="black">
          <ul>
          <li>簿記や経理の知識があるのでやりとりがスムーズ</li>
          <li>自社で経理を行うので記帳代行の必要が無い</li>
          <li>クラウド会計を使うので記帳代行の必要がない</li>
          <li>ITが使えるので資料のやりとりをデータですぐに行える</li>
          <li>レシート等の資料を細かく整理して提出していただける</li>
          </ul>
          といった場合です。
        </div>
      </ol>
      <p>
        こういったケースは当事務所としても値下げをすることができます。
        お客様の中にはすぐにはできなくても頑張っていきたい、といったお声もいただきます。
      </p>
    </div>
    <UiMiniToTopButton :type="'C'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.black {
  color: black;
  margin-left: 20px;
  ul {
    li {
      margin-left:  1.4em !important;
      text-indent: -0.4em !important;
    }
  }
}
</style>
