<template>
  <div class="line">
   <div class="black-line"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiLine",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.black-line{
border-bottom: solid 2px var(--black);
margin: 37px auto 55px auto;
width: 78px;
}

</style>