<template>
  <div class="home-service">
    <UiHeading :h="'2'" :type="'A'" :text="'業務・サービス案内'" />
    <UiLine />
    <div class="service-box">
      <UiBox
        v-for="boxData in boxList"
        :key="boxData.id"
        class="box"
        :imgpath="boxData.imgpath"
        :imgName="boxData.imgName"
        :subTitle="boxData.subTitle"
        :text="boxData.text"
        :category="boxData.category"
        :categoryText="boxData.categoryText"
        :buttonData="boxData.buttonData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBox from "@/components/Ui/Box.vue";

export default {
  name: "HomeService",
  components: {
    UiHeading,
    UiLine,
    UiBox,
  },
  data() {
    return {
      boxList: [
        {
          id: 1,
          imgpath: "yamada-yudai-top03.jpg",
          imgName: "記帳代行",
          subTitle: "税務顧問・記帳代行",
          text: "決算書・確定申告書の作成、記帳代行、帳簿の作成<br>年末調整、税務相談、その他税金・経理に関する業務",
          category: "対象者",
          categoryText:
            "<li>税理士と顧問契約したい方</li><li>税理士に記帳代行を依頼したい方</li>",
          buttonData: { path: "zeimukomon/" },
        },
        {
          id: 2,
          imgpath: "yamada-yudai-top04.jpg",
          imgName: "会社設立",
          subTitle: "会社設立・個人事業の開業（起業）",
          text: "会社設立および個人事業の開業など、起業されたい方の税務手続き<br>会社設立、個人事業の開業後の記帳代行や確定申告書の作成",
          category: "対象者",
          categoryText:
            "<li>会社設立をお考えの方</li><li>個人事業の開業をお考えの方</li><li>個人事業を会社に変更したい方</li>",
          buttonData: { path: "kaigyo/" },
        },
        {
          id: 3,
          imgpath: "yamada-yudai-top05.jpg",
          imgName: "税理士の変更",
          subTitle: "税理士の変更",
          text: "契約中の税理士の変更・見直しの相談<br>当事務所に変更を希望の方へ料金の見積もり",
          category: "対象者",
          categoryText:
            "<li>契約中の税理士について変更・見直しを考えている方</li><li>税理士変更した場合の顧問報酬の違いを知りたい方</li><li>違う税理士の立場からの意見を聞きたい方</li>",
          buttonData: { path: "zeirishi-henko/" },
        },
        {
          id: 4,
          imgpath: "yamada-yudai-top06.jpg",
          imgName: "単発でのお仕事",
          subTitle:
            "スポットの仕事<br class='sp-kaigyo'>（単発でのお仕事のご依頼）",
          text: "顧問契約をせず、年に一回の所得税の確定申告書の作成（医療費控除・住宅ローン控除初年度・土地建物の譲渡等）<br>顧問契約をせず、年に一回の法人税の決算書・確定申告書の作成<br>その他の確定申告書の作成（消費税・贈与税等）<br>無申告の方の確定申告書の作成",
          category: "対象者",
          categoryText:
            "<li>個人事業主や法人で、事業が小規模などの理由で、確定申告書の作成を年一回だけ依頼したい方</li><li>事業はしていないが確定申告書の作成を依頼したい方（住宅ローン控除・土地・建物の譲渡等）</li><li>その他税理士に仕事を依頼、相談したい方</li>",
          buttonData: { path: "spot/" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.service-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .box {
    width: 49%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  /deep/li {
    margin-left: 1.4em !important;
    text-indent: -1.4em !important;
  }
}
</style>
