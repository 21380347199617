<template>
  <div class="bar-list">
              <UiBar
      v-for="barData in barList"
      :key="barData.id"
      :jumpTo="barData.jumpTo"
      :type="barData.type"
      :barNo="barData.barNo"
      :barText="barData.barText"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
export default {
  name: "UiBarList",
  components: {
    UiBar
  },
  props: {
  barList: Array,
  },
  data() {
    return {      
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>


</style>