<template>
  <div class="spot">
    <div class="space-150">
      <ContactContactTop />
    </div>

    <!-- <div class="space-50 wrap">
      <UiToLine />
    </div> -->

    <div class="space-100 wrap">
      <ContactContactForm />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContactContactTop from "@/components/Contact/ContactTop.vue";
import ContactContactForm from "@/components/Contact/ContactForm.vue";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "spot",
  components: {
    // UiToLine,
    ContactContactTop,
    ContactContactForm
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>

</style>
