<template>
  <div class="zeirishi-henko-trouble">
    <div class="wrap">
      <UiHeading
        :h="'1'"
        :type="'A'"
        :text="'税理士の変更をご検討されている事業主様へ'"
      />
      <UiLine />

      <h3 class="top">現在の税理士にこんな悩みや<br class="sp-kaigyo">不満はありませか？</h3>

      <UiBar3List :barList3="barList3" :good="false" />
    </div>
    <div class="back-green">
      <h2>
        今の税理士をあなたの大切な方に紹介できるかを考えてみてください。<br />現在の税理士にこのような悩みがあれば、ぜひ山田雄大税理士事務所へご相談ください！
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBar3List from "@/components/Ui/Bar3List.vue";

export default {
  name: "zeirishi-henko-trouble",
  components: {
    UiHeading,
    UiLine,
    UiBar3List,
  },
  props: {},
  data() {
    return {
      barList3: [
        {
          id: 1,
          type: "B",
          barText: "相場はよくわからないけど料金が高いと感じている",
        },
        {
          id: 2,
          type: "A",
          barText: "親身になって相談に乗ってくれない",
        },
        {
          id: 3,
          type: "B",
          barText: "質問や相談に対する返事が遅い",
        },
        {
          id: 4,
          type: "A",
          barText: "頭でっかち、怖いなどコミュニケーションがとりづらい",
        },
        {
          id: 5,
          type: "B",
          barText:
            "ITに対応できず、会計ソフトなど独自のシステムを強制してくる",
        },
        {
          id: 6,
          type: "A",
          barText: "年配の税理士でこの先長く付き合っていくことに不安がある",
        },
        {
          id: 7,
          type: "B",
          barText: "自分の仕事内容に対する知識がない",
        },
        {
          id: 8,
          type: "A",
          barText: "税理士と直接関わる機会が少ない",
        },
        {
          id: 9,
          type: "B",
          barText: "税理士が代理店契約している保険を勧められる",
        },
        {
          id: 10,
          type: "A",
          barText: "知人から聞いた他の税理士とやってくれていることが違う",
        },
        {
          id: 11,
          type: "B",
          barText: "担当者が税理士資格のない人で信頼できない",
        },
        {
          id: 12,
          type: "A",
          barText: "担当者が頻繁に入れ替わる",
        },
        {
          id: 13,
          type: "B",
          barText: "無意味な定期訪問がある",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.back-green {
  background-color: var(--green);
  padding: 50px 0;
  color: white;
  line-height: 2.9em;
  margin: 50px 0;
  h2{
      @media screen and (max-width: 767px){
        font-size: 20px;
        padding: 0 20px;
        text-align: left;
      }
  }
}
.top {
  text-align: center;
  font-size: 34px;
  margin-top: 80px;
  margin-bottom: 25px;
  @media screen and (max-width: 767px){
    font-size: 23px;
  }
}
</style>