<template>
  <div class="company-career">
    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="'ちょっと変わった代表税理士の経歴'"
    />

<div class="wrap description-area">
  <p>私は税理士としては変わった経歴をしています。</p>
  <p>簡単にご紹介すると、大学を卒業した後、国税局（税務署と言う名前の方がなじみがあるかもしれません）に就職しました。最初は税理士になるつもりは全くなく、安定した公務員として仕事をしていくつもりでした。</p>
  <p>しかし、勤務していく中で税務署というところが警察などと同じように体育会系の組織であることや、税金を徴収するという仕事が肌に合わず、その仕事を続けていくべきか考えるようになりました。</p>
  <p>そんな中、納税者の代理人である税理士という職業の人と仕事の上で関わることが多くなりました。税金を納める人に代理して、知識と経験を武器に税務署に対応するその仕事に憧れを持つようになり、一念発起、税務署を退職して税理士を志すことにしたのです。</p>
  <p>その決意を税務署の上司に伝えたとき、「なれる保証もない税理士を目指すなんて。安定した公務員を辞めない方がいい。今の時代、税理士になったからといってお金を稼げる保証もないぞ。」と言われました。今でもこの言葉はとても印象に残っています。私はそこまで言われるなら絶対税理士になろう、公務員を辞めて良かったと自分が思える未来を税理士という仕事を通して実現しよう、と思ったのです。</p>
  <p>ただ、税務署での経験はとても貴重なものになりました。なにせ今実際に税理士という立場で税務署と関わっているわけですから。税務署の仕事内容はもちろん、どんな考えで仕事をしているのか、を理解しており、お客様にその情報を提供できるのです。</p>
  <p>その後、税理士資格取得の勉強をしながら税理士事務所（会計事務所とも呼びます）に就職し、税理士の補助として実務の経験を積むことになりました。</p>
  <p>自分で独立開業するまでに3社の税理士事務所で勤務しました。3つの事務所で勤務した理由は、色々な税理士事務所のやり方を経験してみたかったからです。独立開業することが念頭にありましたからそれぞれの事務所の良いところや悪いところを自分なりに分析して独立した際の参考にしました。</p>
  <p>さて、ホームページをご覧になっている皆様は税理士や税理士事務所についてどんな印象をお持ちでしょうか。厳しそう、まじめそう、といった固いイメージが多いのではないかと思います。（そもそも何のイメージもない、何をしているかよくわからないといった声もありそうですが）</p>
  <p>結論としては、税理士の考え方や事務所の方針によってバラバラです。</p>
  <p>私の経験した3つの事務所はそれぞれ全く違う色があり、何とか売上を増やしたい！拡大だ！と、営業に力を入れている事務所もあれば、小規模でのんびりやろう、という事務所もありました。これは、代表である税理士の考え方が全てで、同じ考え方や方針があるわけではなく、それぞれの色があるということです。</p>
  <p>税理士資格を取得した私は、開業するにあたり、どんな色の事務所にすることがお客様のためになるのか、社会に貢献できるか、それを実現するための私の強みは何か、を必死で考えました。</p>
  <p>そして、私の強みは
    <ol class="green">
      <li>年齢が若いこと</li>
      <li>変化に柔軟に対応していく自信があること</li>
      <li>コミュニケーション能力に自信があること</li>
      <li>税務署の勤務の経験があること</li>
      <li>3つの税理士事務所の勤務の経験があること</li>
    </ol>
    だと結論付けました。
  </p>
  <p>税務署や税理士事務所での勤務の経験から、良い部分は取り入れ、悪いところ排除する、そして自分の強みや特色を活かし、時代に合った事務所にする、これが開業する際に私が決意したことです。</p>
  <p>今ではスタッフにも恵まれ、多くのお客様のお手伝いをさせていただいております。開業した時の決意は今でも変わることなく、日々変化していく時代に対応し、税の専門家としてお客様や社会に貢献できる事務所運営を心掛けています。</p>
  <p>ホームページをご覧いただきました皆様につきましても、私の経験と当事務所の強みを活かして精一杯サポートさせていただきます。皆様にお会いできるのを楽しみにしております。</p>
</div>

    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="'代表税理士の趣味・嗜好'"
    />

<div class="wrap description-area">
<h3 class="green">趣味</h3>
<ul>
  <li>読書（年間100冊ほど読んでいます。どんなジャンルの本でも読みますが、ビジネス書が多いです。おすすめの本を紹介しますので是非お尋ねください。）</li>
  <li>グルメ（いろんな方と美味しいものを食べながらお話しすることが好きです。）</li>
  <li>旅行（世界の100都市を訪れることが夢です。まだまだ先は長いです。）</li>
  <li>音楽鑑賞（クラシックや邦楽、ボーカロイドなどが好きです。）</li>
</ul>

<h3 class="green">嗜好・性格</h3>
<ul>
  <li>目標や予定を決め、それに向かって進んでいくことに大きなやりがいを感じます。</li>
  <li>知的好奇心が旺盛で、常に変化しているのを実感したいと考えています。</li>
  <li>人と話し、自分の知らない知識や経験を教えてもらうことが好きです。</li>
  <li>自分の知識や経験を伝えて、その人が成長していく姿を見ることはもっと好きです。</li>
</ul>

</div>
<UiDetailButton :path="'/'" :text="'トップページに戻る'" :type="'B'" style="margin-bottom:100px;"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";

export default {
  name: "ConpanyCareer",
  components: {
    UiHeading,
    UiDetailButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.description-area {
  margin-top: 100px;
  margin-bottom: 150px;
  @media screen and (max-width: 767px){
    margin-top: 50px;
  }
}
</style>