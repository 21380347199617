<template>
  <div class="ui-list">
        <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
    <div class="list-cover">
      <div class="category">{{listCategory}}</div>
      <div class="detail">{{listDetail}}</div>
    </div>
</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiLine",
  components: {},
  props: {
    type:String,
    listCategory:String,
    listDetail:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.typeA{
  .list-cover{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

     @media screen and (max-width: 767px){
       flex-direction: column;
     }
    .category{
      width: 20%;
      min-width: 150px;
      text-align: center;
      border: 1px var(--green) solid;
      padding: 10px 0;
      font-size: 20px;
      color: var(--green);
      font-weight: 500;
      max-height: 57px;
      @media screen and (max-width: 767px){
        width: 100%;
      }

    }
    .detail{
      width: 78%;
      margin-left: 10px;
      letter-spacing: 0.1px;
      line-height: 1.2em;
      font-size: 16px;
      @media screen and (max-width: 767px){
        width: 100%;
        margin-top: 20px;
        line-height: 1.5em;
      }
    }
  }

}
</style>