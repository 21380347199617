<template>
  <div class="kigyo-to-everyone">
    <UiHeading
      :h="'1'"
      :type="'A'"
      :text="'会社設立・開業をご検討されている事業主様へ'"
    />
    <UiLine />
    <div class="description-area">
      <p class="bottom-0">
        会社設立や個人事業の開業をお考えの方は色々な不安や悩みを抱えています。
      </p>
      <ul class="green">
        <li>何から手をつければいいの？</li>
        <li>気を付ける点は？</li>
        <li>専門家に依頼した方がいい？</li>
      </ul>

      <p>
        当事務所はこんな起業の際の不安や悩みを解消します。会社や個人事業の税務署への手続き、記帳や経理、税金の確定申告書の作成はもちろん、会社設立については司法書士を、社会保険の手続きは社会保険労務士を、各種許認可については行政書士を紹介します。
      </p>

      <p class="bottom-0">専門家に依頼するメリットは、</p>
      <ul class="green" style="margin-bottom:0;">
        <li>もれやミスがなく正確である</li>
        <li>時間や手間がかからない</li>
        <li>開業後の業務をスムーズに行える</li>
      </ul>
        <p >といったことが挙げられます。</p>

      <p>
        特に税理士は事業が続く限り付き合うことになりますから、始めからしっかりと関係を築いていくことをおすすめします。開業の目的や業務内容、お客様の状況などの情報を税理士と共有することで、初めて適切な経理や税金の対策をすることができるからです。初期費用がかかる、規模が小さいといった理由で税理士に依頼せず、ご自身で経理や確定申告をやろうとする方もいらっしゃいますが、ご自身でやることはあまりおすすめしません。税務署への書類を一枚提出し忘れた、経理のやり方を間違えていた、こんな理由で大きく損をしてしまう可能性があります。また、正しい経理や確定申告を行わないことで、銀行融資や住宅ローンの借入、各種許認可手続きの不備、補助金や助成金が受けられない、といった悪影響をおよぼすことがあります。
      </p>
      <p>
        当事務所は小規模事業者に特化し、そのサポートに力を入れています。皆様は経理の勉強をすることや、確定申告書を作るために起業するわけではありません。そこに時間を取られることなく、事業の発展や、売上を伸ばすことに専念して欲しいと当事務所は考えます。小規模事業者こそ経理に割く時間や税金の負担を減らすことが必要になります。税理士とうまく付き合い、事業の基盤である経理や確定申告の心配をすることなく、事業の発展に繋げていきましょう。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "kigyo-to-everyone",
  components: {
    UiHeading,
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>