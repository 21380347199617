<template>
  <div class="ctrl">
    <CtrlCtrlPostDetail :postId="postId"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlCtrlPostDetail from "@/components/Ctrl/PostDetail.vue";
export default {
  name: "CtrlPost",
  components: {
    CtrlCtrlPostDetail,
  },
  props: {
  postId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
