<template>
  <div class="home-estimate">
    <div class="estimate-box">
      <img :src="`${rootUrl}/img/yamada-yudai-top07.jpg`" alt="悩みイメージ" />
      <div class="estimate-body">
        <h4>こんな<br class="sp-kaigyo">悩みはありませんか？</h4>
        <ul>
          <li :style="`background-image: url(${rootUrl}/img/yamada-yudai-top01.png);`">確定申告を自分でやることができない</li>
          <li :style="`background-image: url(${rootUrl}/img/yamada-yudai-top01.png);`">
            税理士に頼むべきか分からない
          </li>
          <li :style="`background-image: url(${rootUrl}/img/yamada-yudai-top01.png);`">どの税理士事務所に依頼すればいいか分からない</li>
          <li :style="`background-image: url(${rootUrl}/img/yamada-yudai-top01.png);`">税金の負担を減らしたいけどどうしたらいいか分からない</li>
          <li :style="`background-image: url(${rootUrl}/img/yamada-yudai-top01.png);`">今の税理士で本当に良いのか不安だ</li>
        </ul>
      </div>
    </div>
    <div class="estimate-foot">
      <p>所長の山田が話をお聞きします！まずはお気軽にご相談ください！</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "HomeEstimate",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.estimate-box {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
   @media screen and (max-width: 767px){
     flex-direction: column;
   }
  img {
    width: 46%;
     @media screen and (max-width: 767px){
       width: 100%;
     }
  }
  .estimate-body {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     @media screen and (max-width: 767px){
       width: 100%;
       margin-top: 30px;
     }
    h4 {
      color: var(--green);
      font-weight: 500;
      font-size: 34px;
      margin-bottom: 20px;
      @media screen and (max-width: 767px){
        margin-bottom: 20px;
        font-size: 28px;
      }

    }
    ul {
      list-style: none;
      li {
        padding: 14px 0 14px 48px;
        background-repeat: no-repeat;
        background-size: 2em;
        background-position: left center;
        font-size: 20px;
        @media screen and (max-width: 767px){
          background-position: 0px 5px;
          padding: 0 0 28px 48px;

        }

      }
    }
  }
}
.estimate-foot {
  border-top: 2px solid var(--blue);
  border-bottom: 2px solid var(--blue);
  margin: 50px 0;
  text-align: center;
  p {
    width: 100%;
    color: var(--blue);
    font-size: 26px;
    font-weight: 500;
    padding: 25px 0;
  }
}
</style>
