<template>
  <div class="blog-detail">
    <div class="blog-top space-150">
      <UiHeading :h="'1'" :type="'A'" :text="`${postData.title}`" />
      <UiLine />

      <div class="wrap content">
        <div v-html="postData.text"></div>
    <UiDetailButton
        :path="'/category/blog/'"
        :type="'C'"
        :text="'お役立ち情報一覧に戻る'"
        style="margin-top:30px"/>

     <UiDetailButton
        :path="'/'"
        :type="'B'"
        :text="'トップページに戻る'"
         style="margin-top:50px"
      />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";

export default {
  name: "blog-detail",
  components: {
    UiHeading,
    UiLine,
    UiDetailButton,
  },
  props: {
    postId: String,
  },
  data() {
    return {
      postData: {},
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {
    postId: {
      handler(newValue) {
        this.postData = JSON.parse(
          JSON.stringify(this.$store.getters["postData"](newValue))
        );
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin-bottom: 100px;
  min-height:400px;
}
</style>
