<template>
  <div class="zeirishi-henko">
    <div class="space-150">
      <ZeirishiHenkoTrouble />
    </div>
    <div class="space-200">
      <ZeirishiHenkoImprovement />
    </div>

    <div class="space-200">
      <ZeirishiHenkoQuestion />
    </div>

    <div class="wrap space-200">
      <HomeFlow />
    </div>

    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ZeirishiHenkoQuestion from "@/components/ZeirishiHenko/Question.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import ZeirishiHenkoTrouble from "@/components/ZeirishiHenko/Trouble.vue";
import ZeirishiHenkoImprovement from "@/components/ZeirishiHenko/Improvement.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "zeirishi-henko",
  components: {
    ZeirishiHenkoQuestion,
    HomeFlow,
    ZeirishiHenkoTrouble,
    ZeirishiHenkoImprovement,
    // HomeArticle,
    UiToContact,
    // UiToLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
