<template>
  <div class="home-cliant">
    <UiHeading :h="'2'" :type="'A'" :text="`山田雄大税理士事務所の<br class='sp-kaigyo'>クライアントについて`" />
    <UiLine />

    <div class="glaf">
      <h3>[クライアントの年商規模]</h3>
            <img
        :src="`${rootUrl}/img/yamada-yudai-top08.jpg`"
        alt="グラフ１" class="pc-only"
      />
            <img
        :src="`${rootUrl}/img/yamada-yudai-top-cl-sp-01.jpg`"
        alt="グラフ１" class="sp-only"
      />
    </div>
    <div class="glaf">
      <h3>[クライアントの事業形態の割合]</h3>
            <img
        :src="`${rootUrl}/img/yamada-yudai-top09.jpg`"
        alt="グラフ2" class="pc-only"
      />
                  <img
        :src="`${rootUrl}/img/yamada-yudai-top-cl-sp-02.jpg`"
        alt="グラフ2" class="sp-only"
      />
    </div>
    <div class="glaf">
      <h3>[クライアントの代表者の年齢]</h3>
            <img
        :src="`${rootUrl}/img/yamada-yudai-top10.jpg`"
        alt="グラフ3" class="pc-only"
      />
                  <img
        :src="`${rootUrl}/img/yamada-yudai-top-cl-sp-03.jpg`"
        alt="グラフ3" class="sp-only"
      />
    </div>
    <div class="glaf">
      <h3 class="sp-font">[過去5年間で個人事業から法人成りした件数]</h3>
            <img
        :src="`${rootUrl}/img/yamada-yudai-top11.jpg`"
        alt="グラフ4" class="pc-only"
      />
                  <img
        :src="`${rootUrl}/img/yamada-yudai-top-cl-sp-04.jpg`"
        alt="グラフ4" class="sp-only"
      />
    </div>
    <div class="glaf">
      <h3>[開業から携わった割合]</h3>
            <img
        :src="`${rootUrl}/img/yamada-yudai-top12.jpg`"
        alt="グラフ5" class="pc-only"
      />
                  <img
        :src="`${rootUrl}/img/yamada-yudai-top-cl-sp-05.jpg`"
        alt="グラフ5" class="sp-only"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
  },
  data() {
    return { };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.glaf{
margin-bottom: 50px;
  h3{
    text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  }
}
.pc-only{
@media screen and (max-width: 767px){
  display: none;
}
}
.sp-only{
  display: none;
  @media screen and (max-width: 767px){
    display:initial;
  }
}
.sp-font{
  @media screen and (max-width: 767px){
    font-size: 16px!important;
  }
}

</style>
