<template>
  <div class="zeirishi-henko-improvement">
    <div class="wrap">
      <UiHeading
        :h="'2'"
        :type="'A'"
        :text="'山田雄大税理士事務所に変更して改善した具体例'"
      />
      <UiLine />

      <UiTwoBox
        v-for="twoboxData in twoboxList"
        :key="twoboxData.id"
        :before="twoboxData.before"
        :after="twoboxData.after"
      />
    </div>
    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="`セカンドオピニオン<br class='sp-kaigyo'>としての税理士`"
      class="space-200"
    />
    <div class="wrap tax-accountant">
      <p>
        今はお医者さんの世界でも、今の治療が本当に正しいものなのかどうかを別のお医者さんに見てもらうセカンドオピニオン（他の専門家の意見を聞くこと）が当たり前になっています。
自分の病気に関わることなのでお医者さんのセカンドオピニオンは普及している一方、税理士のセカンドオピニオンは未だ普及しているとはいえません。
      </p>
      <p>
        実実は会社や事業も人間の体と同じで、適切な診断や治療をしなくてはどんどん症状は悪化していきます。私自身、気づかない間に病気が進行しており、手の施しようがなくなってしまった会社をたくさん目にしてきました。
      </p>
      <p>
        税理士事務所によってお客様との付き合い方が違うことは当然のことでしょう。大切なのは、その税理士との付き合い方がお客様のためになっているのか、ということです。今の税理士との付き合い方を変えることができれば問題を解決することもあります。
      </p>
      <p>
        当事務所では税理士の変更を考えている方の相談を受けています。相談の際には今の税理士の不満や不安を遠慮なくぶつけてください。セカンドオピニオンとして今の税理士と付き合っていくメリットとデメリットを客観的にご説明いたします。お客様のお話をしっかりと聞いた上で、当事務所に税理士を変更していただいた際のメリットとデメリットをご説明し、一番良いお付き合いの形を提案いたします。一度人間ドックを受ける気持ちで、お気軽にお問い合わせください。
      </p>
    </div>
  
    <UiHeading
      :h="'2'"
      :type="'B'"
      :text="'ITに強い税理士です！'"
      class="space-200"
    />
  <div class="wrap tax-accountant">
    <p>税理士にもITに強い人とそうでない人がいます。若い税理士はITに強く、年配の税理士は苦手なことが多いようです。</p>
    <p>ITに強い税理士に変更すると以下のようなメリットがあります。
      <ul>
        <li>古い税理士事務所の無駄な手作業をITで効率化できるので顧問料が下がる</li>
        <li>連絡手段や資料の受け渡しについて、ITを使った色々な方法を提案できるので、スムーズになり、紙で印刷するといった余計な手間やコストがかからない</li>
        <li>全ての会計ソフトに対応できるので税理士を変更しても、今使っている会計ソフトなどを変更する必要がない</li>
        <li>事業の途中のデータの移行を簡単にできるので税理士変更の際の追加料金がかからない</li>
        <li>お客様側の古いやり方について、ITを使った新しい効率的なやり方を提案できるので事務負担が減る</li>
        </ul>
      といったことが挙げられます。
    </p>

    <p>反対にITに弱い税理士と付き合うデメリットは、
      <ul>
        <li>効率の悪い事務所運営をしている→事務所の無駄な作業が多くなる→コストがかかる→お客様の顧問料が高くなる</li>
        <li>お客様がITに対応していても税理士事務所側が対応できず無駄な作業が増える</li>
        <li>時代の変化に対応できないので新しい提案を受けることができない</li>
        <li>昔ながらのやり方が正しいと思っているのでそのやり方を強制される</li>
      </ul>
      といったことが挙げられます。</p>

<p>税理士業界にもIT化の波は押し寄せていますが、一般社会に比べてその変化への対応が遅いです。長く同じ税理士と付き合っていると昔のやり方をそのまま続け、税理士もお客様もそれが当たり前になってしまいます。実はこれが一番怖いことです。世の中にはお客様にとってもっといいやり方があるのに、税理士も同じ状態のほうが楽なのでそれに甘えて勉強しない、お客様も提案を受けないので今の形が当然だと思う、実際はお客様のためにはならず古いやり方を続け、どんどん時代に取り残される、ということです。</p>

<p>当事務所はITに力を入れており、どんなやり方にも対応できます。今後、世の中はどんどんIT、デジタル化が進みます。この変化に対応できるかどうかがビジネスに大きく影響することは間違いありません。ITは苦手でよくわからない、という方も心配はいりません。まずはできることだけを取り入れてもらい、少しずつ慣れていけるようにサポートいたします。当事務所はITを活用することで古い税理士のやり方を変えていき、皆様にベストな提案をいたします。</p>
  </div>

<div class="wrap">
  <UiBox3Plus :theme="'事例１'" :title="'高額の顧問料が半額になった個人事業主'" :industry="'【運送業】'" :before="'開業時に知り合いに紹介された税理士と契約したが、顧問料の相場がわからず契約した。その後、同業の人から顧問料が高すぎるとアドバイスを受け、不信感をもっていた。また、税理士に勧められた多額の生命保険に加入していたが本当に必要なものか疑問があった。'" :after="'顧問料の見直しを行い、月額顧問料が半額になった。また、税理士とのヒアリングを重ね、生命保険を含め不要な支払いをすべて見直し、経営のスリム化に成功した。'"/>
  </div>
<div class="wrap">
  <UiBox3Plus :theme="'事例２'" :title="'ITの活用で業務効率が劇的に改善した会社'"  :industry="'【インターネット通販業】'" :before="'自ら税理士にITを活用した付き合い方について提案したが、「対応できない」と言われ効率が悪いと感じていた。結局すべての資料を印刷し、税理士に郵送で渡していた。また、クラウドソフトを使いたいと思っていたが、「対応できない」と言われ、税理士に勧められた会計ソフトの使用を強制されていた。'" :after="'資料の受渡しにつきITを活用し、クラウドでデータ共有することになったので郵便での資料のやり取りがなくなった。また、どの会計ソフトでも対応できるとのことで、銀行やクレジットカード、アマゾンでの売上の情報が同期できるクラウドソフトを導入することになり、リアルタイムで経営状況を把握することができるようになった。'"/>
  </div>
<div class="wrap">
  <UiBox3Plus :theme="'事例３'" :title="'年配の税理士が適切な処理をしてくれていなかった会社'"  :industry="'【人材派遣業】'" :before="'年配の税理士が顧問していたが、間違いが多く、税務署にたびたび指摘され、修正していた。立場が税理士の方が上で、そのことについて問いただすことができなかった。また、質問をしてもレスポンスが遅く、結局自分でお金の状態を把握し、経営判断をせざるを得なかった。'" :after="'今までの税理士のミスを全て見つけ出してもらい、きれいに直した状態で心機一転スタートすることができた。若くて話しやすいことから、遠慮することなくなんでも聞くことができるようになった。レスポンスも早く、安心してスピーディーに事業を行うことができるようになった。'"/>
  </div>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiTwoBox from "@/components/Ui/TwoBox.vue";
import UiBox3Plus from "@/components/Ui/Box3Plus.vue";
// import UiBar3List from "@/components/Ui/Bar3List.vue";

export default {
  name: "zeirishi-henko-trouble",
  components: {
    UiHeading,
    UiLine,
    UiTwoBox,
    UiBox3Plus,
    // UiBar3List,
  },
  props: {},
  data() {
    return {
      twoboxList: [
        {
          id: 1,
          before:
            "料金が高いと感じていたが相場がわからないのでそのままにしていた",
          after:
            "作業内容、訪問回数、データのやり取り等を見直し、顧問料が安くなった",
        },
        {
          id: 2,
          before: "データのやり取りを全て紙で印刷して行っていた",
          after:
            "共有フォルダやツールを使って紙ではなくデータで資料の受け渡しをするように変更し、印刷や郵送などの作業コストの削減ができた",
        },
        {
          id: 3,
          before:
            "予定を税理士に合わせたり、税理士の事務所に行って面談をしていた",
          after:
            "・都合のいい場所に税理士が来てくれるようになったので移動がなくなった<br>・Zoomなどの在宅での面談に変更したので予定の調整や移動が楽になった",
        },
        {
          id: 4,
          before:
            "電話をかけたが繋がらず、折り返しが遅く緊急の対応をしてくれない",
          after:
            "既読が付くLINEやチャットワーク等のツールを使うことで対応が早くなり、必要な書類やデータのやり取りが瞬間的にできるようになった",
        },
        {
          id: 5,
          before: "税理士の指定した会計ソフトを使って入力作業をしている",
          after:
            "どんな会計ソフトやエクセル形式でも対応してくれるようになったので自社のやり方を変える必要がなくなった",
        },
        {
          id: 6,
          before:
            "年齢や雰囲気が原因で、税理士との距離を感じて、心を開いて相談ができなかった",
          after:
            "若い税理士なので何でも気軽に話せるようになり、距離が近くなった",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tax-accountant {
  margin: 50px auto;
  p {
    padding: 15px 0;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
    color: var(--green);
  }
}
</style>