import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl: 'https://tax-yamada.net',
    postList: [],
    categoryList: [],
    imageList:[],
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
    login(state) {
      return state.login
    },
    postList(state) {
      return state.postList
    },
    categoryList(state) {
      return state.categoryList
    },
    imageList(state) {
      return state. imageList
    },

    categoryData: (state) => (categoryId) => {
      let categoryData = {};
      if (categoryId !== "0") {
        categoryData = state.categoryList.find(category => category.categoryId === categoryId);
      } else {
        categoryData = {
          catName: "",
          catUrl: "",
          catSort: ""
        }
      }
      return categoryData;
    },
    postData: (state) => (postId) => {
      let postData = {};
      if (postId !== "0") {
        postData =state.postList.find(post => post.postId === postId);
      } else {
        postData = {
          categoryId: "",
          title: "",
          text: "",
          sort: ""
        }
      }
      return postData;
    },
    informationList(state) {
      let categoryList = [];

      // categoryListを分解して
      state.categoryList.forEach(categoryData => {
        let categoryUnit = {};
        let postList = [];
        // postListからここで検索
        postList = state.postList.filter(postData => postData.categoryId === categoryData.categoryId);
        postList.sort((a, b) => a.sort - b.sort);
        
        if (postList.length !== 0) {
          categoryUnit = {
            categoryId: categoryData.categoryId,
            catName: categoryData.catName,
            catUrl: categoryData.catUrl,
            catSort: categoryData.catSort,
            postList: postList
          };
          categoryList.push(categoryUnit);
        } else {
          // no actions
        }
      });
      categoryList.sort((a, b) => a.catSort - b.catSort);

      return categoryList;

    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
    setCategoryList(state, payload) {
      state.categoryList = payload;
    },
    setPostList(state, payload) {
      state.postList = payload;
    },
    setLogin(state, payload) {
      state.login = payload;
    },
    setImageList(state, payload) {
      state.imageList = payload;
    }
  },
  actions: {
    setRootUrl({
      commit
    }) {
      let rt = process.env.NODE_ENV === 'production' ? "https://tax-yamada.net" : "http://tax-yamada.localhost";
      //↑同じ意味↓
      // let rt;
      // if(process.env.NODE_ENV === 'production') {
      //   rt = "https://aru-dry.com";
      // }else {
      //   rt = "http://aru-dry.localhost";
      // }
      commit('setRootUrl', rt);
    },
    setCategoryList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rootUrl}/api/get-categories.php`)
        .then((response) => {
          console.log(response);
          commit('setCategoryList', response.data.categoryList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPostList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rootUrl}/api/get-posts.php`)
        .then((response) => {
          console.log(response);
          commit('setPostList', response.data.postList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setImageList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rootUrl}/api/get-images.php`)
        .then((response) => {
          console.log("koko");
          console.log(response);
          commit('setImageList', response.data.imageList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setLogin({
      commit
    }, payload) {
      commit('setLogin', payload);
    },
    loginCheck({
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rootUrl}/api/login-check.php`)
          .then(res => {
            console.log(res.data);
            //[status: 200, message: "ログイン失敗"]
            resolve(res);
            // resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      })
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
