<template>
  <div class="cotacnt-top">
       <UiHeading :h="'2'" :type="'A'" :text="'お問い合わせ'" />
    <UiLine />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "ContactTop",
  components: {
    UiHeading,
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.black-line{
border-bottom: solid 2px var(--black);
margin: 37px auto 55px auto;
width: 78px;
}

</style>