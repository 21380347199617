<template>
  <div class="home-service">
    <UiHeading :h="'2'" :type="'A'" :text="'山田雄大税理士事務所が選ばれる理由'" />
    <UiLine />


    <UiBarPlus    
      :type="'A'"
      :titleBox="true"
      :barNo="'01'"
      :barText="'年商2億円未満の小規模事業者に特化するからこそ可能なサポート力'"
       :barAnswer="'小規模事業者だからこそ税理士事務所がやるべきこと、やれることがあり、それが事業者の生活に直結します。当事務所は小規模事業者に特化することで小規模事業者に合ったノウハウを提供することができます。年商200万円前後の方ともお付き合いさせていただいております。また、近年は副業等の普及に伴い、小規模事業者には危険がいっぱいです。小規模だけど税理士に頼んでもいい？、頼むメリットはある？、そんなことをお考えの方はどうぞお気軽にお問い合わせください。'"
      />
      <div class="answer">
  <!-- <p><button v-on:click="show=!show">切り替え</button></p> -->

</div>
    <UiBarPlus    
      :type="'B'"
      :barNo="'02'"
      :barText="'税理士資格を保有する30代の所長が、連絡、相談を担当'"
       :barAnswer="'当事務所では以下の理由から、代表税理士が直接お客様との対応、相談を行っております。<br>１、お客様は専門家である税理士を信用して「税理士本人」に仕事を依頼している<br>２、税理士本人に会って相談することがお客様の安心につながる<br>３、お客様のお金の相談は税金のことだけではなく、人生に関わることがある<br>４、税理士資格のない事務員が対応することでトラブルの発生が多くなる<br>※連絡や資料の受け渡し等、一部を職員が担当する場合があります。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'03'"
      :barText="'お客様の事業内容や要望に合わせた、最適かつ柔軟な料金設定'"
      :barAnswer="'世の中の税理士事務所は売上の金額で顧問料を決めているところが多いです。<br>しかし、お客様には様々な事情（事業の内容、個人の能力など）があり、顧問料は売上だけで判断できるものではありません。<br>そのため、当事務所では売上に応じた一律の顧問料という料金体系を取っていません。<br>（公開している料金表は、参考になるようお客様の判断の目安を示すものです）<br>山田雄大税理士事務所では、こういった様々な事情をお客様からヒアリングした上で、双方が納得できる最適な顧問契約の内容と顧問料の提示をいたします。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'04'"
      :barText="'あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）を中心とした地域密着型の迅速な対応'"
      :barAnswer="'山田雄大税理士事務所は、お客様の悩みやトラブルを一分一秒でも早く解決します。<br>重要で緊急を要する相談は、電話でもメールでもなく、お会いして顔と顔を合わせて話す必要があります。<br>地域に密着し、対応エリアを限定することで、「すぐに」、「いつでも」会うことができます。地理的な距離だけではなく、こころの面でもお客様の近くで寄り添える事務所であることが選ばれている理由です。<br>当事務所が地域密着を方針としているのは、「お客様にすぐに会うことができる」、という理由だけではなく、以下のメリットがあります。<br><br><ul><li>役所への手続きなどに代わりに行くことができます。</li><li>迅速な移動による対応ができます。</li><li>迅速な移動による対応ができます。</li><li>地域の特性を理解した上でお客様の相談に乗ります。</li><li>地域の情報や状況を共有できます。</li><li>近隣エリアの専門家の紹介など、地域のネットワークを生かしたトータルサポートをします。<ul>'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'05'"
      :barText="'<span>開業、設立、税理士変更などを全面的にバックアップし、本業に集中できるサポート力</span>'"
      :barAnswer="'「開業してすぐに本業に集中して売上を上げてもらいたいから」これが、当事務所が開業や設立のサポートに力を入れている目的です。開業や会社設立後の経理や税金にとられる時間やストレスを削減しましょう。必要な書類の提出、会社設立の手続は丸投げしてください。開業時の方針、悩みをすべて解決します。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'06'"
      :barText="'クラウド会計の完全対応や最新のITを用いてお客様の負担を徹底的に軽減'"
      :barAnswer="'当事務所は古くて非効率な税理士事務所と決別し、若さとITを駆使した新しい税理士事務所を目指しています。クラウド会計の完全対応・サポートを始め、最新のITやその導入補助も積極的に取り入れています。お客様のどんな業務・経理のスタイルにもITで柔軟に対応し、より良い提案をします。もちろん今はITが使えないけど、頑張って使えるようになりたい！といったお客様についても全力でサポートいたします。また、連絡方法や資料の受け渡し方法についても、お客様の状況に応じて負担のかからない方法を提案いたします。'"/>
          <UiBarPlus    
      :type="'A'"
      :barNo="'07'"
      :barText="'元税務署職員だからこそできるリアルな税務対応'"
      :barAnswer="'山田雄大税理士事務所の代表は名古屋国税局・税務署出身の税理士です。その経験から、税務署とうまく付き合うノウハウがあります。お客様に法律に違反することのない節税のアドバイスを行い、なるべく税務調査がないように、知識と経験を生かしてサポートいたします。'"/>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarPlus from "@/components/Ui/BarPlus.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiBarPlus,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
/deep/ul{
  padding-left: 20px;
  li{
    list-style-type: disc;
  }
}


</style>
