<template>
  <div class="ui-bar2">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
      <div class="outer">
        <div class="bar-box">
          <div class="bar-no">
            <p>{{ barNo }}</p>
          </div>
          <div class="bar-title" v-show="titleBox">
            <div class="title-cover"><p v-html="barTitle"></p></div>
          </div>
          <div class="bar-text">
            <p v-html="barText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBar2",
  components: {
    // UiDetailButton,
  },
  props: {
    barNo: String,
    barTitle: String,
    barText: String,
    type: String,
    titleBox: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  padding: 20px 0;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  .bar-box {
    display: flex;
    align-items: stretch;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .bar-no {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
        @media screen and (max-width: 767px){
          width: 100%;
          justify-content: flex-start;
          border: solid 1px var(--green);
        }

      p {
        font-size: 50px;
        color: var(--green);
         @media screen and (max-width: 767px){
           margin: 10px 0 15px 20px;
           vertical-align: middle;
           width: 100%;
         }
      }
    }
    .bar-title {
      color: var(--green);
      font-size: 18px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
        font-size: 20px;
      }
      .title-cover {
        height: auto;
        min-height: 64px;
        width: 80%;
        display: flex;
        align-items: center;
        // justify-content: center;
          @media screen and (max-width: 767px){
           width: 90%;
         }
      }
    }
    .bar-text {
      font-size: 16px;
      width: 75%;
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      p {
        padding: 20px;
        border-left: 1px solid var(--green);
         @media screen and (max-width: 767px){
           border: none;
           padding: 10px 20px;
         }
      }
      /deep/span{
        color: var(--green);

      }
    }
  }
}
.typeA {
  .outer {
    border-top: 1px solid var(--green);
     @media screen and (max-width: 767px){
       border-top: none;
     }
  }
}
.typeB {
  .outer {
    border-top: 1px solid var(--green);
    border-bottom: 1px solid var(--green);
     @media screen and (max-width: 767px){
       border: none;
     }
  }
}
</style>