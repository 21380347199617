<template>
  <div class="home-service">
    <UiHeading :h="'2'" :type="'A'" :text="'税務顧問に関するよくある質問'" />
    <UiLine />


    <UiBarPlus    
      :type="'A'"
      :titleBox="true"
      :barNo="'Q'"
      :barText="'顧問契約をすることのメリットは何ですか？'"
       :barAnswer="'確定申告書を税金のプロである税理士が作成することになります。<br>無駄のない正確なものができる、自分でやる手間がかからない、事業の社会的な立場が上がるといったことメリットになります。'"
      />
      <div class="answer">
  <!-- <p><button v-on:click="show=!show">切り替え</button></p> -->

</div>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'顧問契約か年に一回だけの依頼か、どちらがいいのかわかりません。'"
       :barAnswer="'継続的に事業を行うような場合や、その事業で生活を支えているような場合は顧問契約をおすすめします。<br>顧問契約を迫ることはせず、事業の内容や予算に合わせてアドバイスを行います。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'顧問契約は解約できますか？'"
      :barAnswer="'事業を縮小する、廃業する、自身で行う、他の税理士に依頼する、といった場合は顧問契約を解約することになります。<br>解約希望月の15日までにお伝えくだされば翌月から解約となります。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'顧問料の変更はありますか？'"
      :barAnswer="'毎年当事務所の基準により顧問料の見直しをしています。<br>事業の大幅な変更（売上や従業員数、事業の変更や追加など）があった場合など、変更のお願いを差し上げることがあります。'"/>
    <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'顧問料の値引きはできますか？'"
      :barAnswer="'面談の頻度や、お客様ご自身で作業してもらうといった工夫で顧問料の値引きができることがあります。'"/>
    <UiBarPlus    
      :type="'B'"
      :barNo="'Q'"
      :barText="'顧問契約をした場合、こちらでやることは何ですか？'"
      :barAnswer="'資料の収集と提出になります。<br>資料とはレシート、通帳のコピー、請求書といた事業のお金に関わるもののことです。定期的に提出していただき、当事務所で記帳と申告を行います。'"/>
          <UiBarPlus    
      :type="'A'"
      :barNo="'Q'"
      :barText="'どんな業種の開業も対応してくれますか？'"
      :barAnswer="'基本的にどんな業種でも大丈夫ですが、一部受けられない業種もありますので、お問い合わせの際に事業の概要を教えていただければ、対応できるかどうかすぐにご回答差し上げます。（当事務所の現在の顧問先業種一覧を参考にしてください）<br>開業に伴い許認可が必要な事業の場合は、提携する行政書士を紹介いたします。'"/>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarPlus from "@/components/Ui/BarPlus.vue";


export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiBarPlus,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
/deep/ul{
  padding-left: 20px;
  li{
    list-style-type: disc;
  }
}


</style>
