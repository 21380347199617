<template>
  <div class="ctrl">
    <CtrlCtrlHome />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlCtrlHome from "@/components/Ctrl/Home.vue";
export default {
  name: "CtrlHome",
  components: {
    CtrlCtrlHome,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
