<template>
  <div class="spot-request">
    <div class="wrap">
        <UiHeading :h="'1'" :type="'A'" :text="'スポットでのお仕事依頼'"/>
    <UiLine />

    <p>
      山田雄大税理士事務所では顧問契約ではなく、一回だけ仕事を依頼されたい方のお手伝いもしております。事業が小規模で確定申告だけ依頼したい個人事業主や会社経営者の方、事業はしていないが確定申告が必要な方はご相談ください。顧問契約が良いのか、年に一回だけが良いのか、など税理士との付き合い方についてもご相談ください。申告期限が近い場合の駆け込みの場合も可能な限り対応いたします。申告期限の直前ではなく事前にご相談いただければ、確定申告に必要な準備についても説明いたします。過去に事情があり確定申告をしておらず(無申告)、今から確定申告をしたい方にもご相談ください。
    </p>
</div>

</div>

</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "SpotRequest",
  components: {
    UiHeading,
    UiLine,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.photo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  img{
    width: 30%;
  }
}
</style>
