<template>
  <div class="ctrl wrap space-100">
    <el-row>
      <el-col><h1>管理画面</h1></el-col>
    </el-row>
    <div class="panel">
      <div class="log-out">
        <el-button @click="logout" type="info" plain
          >ログアウト</el-button
        >
      </div>

      <el-row>
        <el-col :span="8">
          <router-link to="/ctrl/post">
            <el-button type="danger" plain>記事一覧</el-button></router-link
          >
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl/sort">
            <el-button type="success" plain>記事並び替え</el-button></router-link
          >
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl/category">
            <el-button type="danger" plain>カテゴリ設定</el-button></router-link
          >
        </el-col>
        <el-col :span="8" style="padding-top:30px;">
          <router-link to="/ctrl/change-password">
            <el-button type="primary" plain>パスワード変更</el-button></router-link
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrlhome",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {
    logout() {
      this.$axios
        .get(`${this.rootUrl}/api/logout.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.push("/ctrl/login");
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 500px;
  background-color: #f5f5f5;
  padding: 50px 100px;
  margin-bottom: 50px;
  margin-top: 70px;
}
.el-button{
width: 90%;
}
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
.log-out{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  .el-button{
    width: 150px;
  }
}
</style>