<template>
  <div class="kaigyo">
    <div class="space-150 wrap">
      <KaigyoToEveryone />
    </div>

    <div class="space-200">
      <KaigyoToSuchPeople />
    </div>

    <div class="space-200">
      <KaigyoQuestion />
    </div>

    <div class="space-200 wrap">
      <HomeFlow />
    </div>

    <div class="space-150">
      <UiToContact />
      <!-- <UiToLine /> -->
    </div>

    <!-- <div class="wrap space-100">
      <HomeArticle />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KaigyoToEveryone from "@/components/Kaigyo/ToEveryone.vue";
import KaigyoToSuchPeople from "@/components/Kaigyo/ToSuchPeople.vue";
import KaigyoQuestion from "@/components/Kaigyo/Question.vue";
import HomeFlow from "@/components/Home/Flow.vue";
// import HomeArticle from "@/components/Home/Article.vue";
import UiToContact from "@/components/Ui/ToContact";
// import UiToLine from "@/components/Ui/ToLine.vue";

export default {
  name: "kaigyo",
  components: {
    KaigyoToEveryone,
    KaigyoToSuchPeople,
    KaigyoQuestion,
    HomeFlow,
    // HomeArticle,
    UiToContact,
    // UiToLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
