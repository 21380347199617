<template>
  <div class="spot-request">
    <UiHeading :h="'2'" :type="'B2'" :text="'所得税'" />

    <div class="wrap">
      <h3>■年一回の確定申告書の作成</h3>
      <UiTable1
        :target="'・事業所得があり確定申告をする必要があるが、顧問契約ではなく年一回で良い<br />・決算が近づいているが何も処理していない<br />・記帳は自分で行っているので確定申告書の作成だけ依頼したい<br />とお考えの方が対象です。当事務所において確定申告書と青色申告決算書（収支内訳書）の作成を行い、電子申告で確定申告書の提出まで行います。記帳代行料も含んでおりますので領収書や通帳のコピー等の資料を提出いただくだけで完了いたします。'"
        :tableTitle="'事業所得の確定申告書の作成'"
        :type="'B'"
      />

      <!-- <UiTable1
        :target="'不動産所得があり確定申告をする必要だが、顧問契約ではなく年一回で良い<br>・決算が近づいているが何も処理していない<br>・記帳は自分で行っているので確定申告書の作成だけ依頼したいと<br>お考えの方が対象です。<br>当事務所において確定申告書と青色申告決算書（収支内訳書）の作成を行い、電子申告で確定申告書の提出まで行います。<br>記帳代行料も含んでおりますので領収書や通帳のコピー等の書類の資料を提出いただくだけで完了いたします。'"
        :tableTitle="'不動産所得の確定申告書の作成'"
        :type="'A'"
      /> -->
      <!-- ↑修正何もしてない -->

      <UiTable2
        :target="'給料を2か所以上の事業所から受け取っており、確定申告をする必要がある方が対象です。<br>源泉徴収票を提出いただくだけで完了いたします。'"
        :tableTitle="'2か所給与の確定申告書の作成'"
        :price="'10,000円'"
      />

      <UiTable2
        :target="'土地・建物を売却し、利益（所得）が出た方が対象です。<br>税務署は土地・建物の所有者が変わっていることを把握しており、申告漏れが多いです。<br>様々な税法上の特例を適用することで税額を抑えることができる可能性があります。'"
        :tableTitle="'土地・建物の譲渡所得の確定申告書の作成'"
        :price="'通常の譲渡…譲渡所得金額の1％＋基本料金100,000円<br>特例適用の場合は別途追加料金を見積もり'"
      />

      <UiTable2
        :target="'保険金収入・仮想通貨やFX、アフィリエイト収入等、その他の所得がある場合に確定申告をされる方が対象です。'"
        :tableTitle="'その他の所得の確定申告書の作成（雑所得・一時所得等）'"
        :price="'別途見積もり'"
      />

      <h3>■控除を受けたい方</h3>
      <UiTable2
        :target="'個人で土地・建物を住宅ローンを使って取得した方が住宅借入金等特別控除を受ける場合（購入された初年度の方）が対象です。<br>土地や建物が共有名義の場合、複数の金融機関から借入をしている場合、一部を事業用にしている場合など、特殊な事情もご相談ください。<br>必要書類の説明をし、資料を集めていただき、お渡しいただければ完了いたします。'"
        :tableTitle="'住宅借入金等（住宅ローン）特別控除を受けたい方の初年度の確定申告書の作成'"
        :price="'50,000円<br>その他特殊事情がある方はこれに加えて別途見積もり'"
      />
      <UiTable2
        :target="'一年間で多額の医療費がかかり、医療費控除を受ける方が対象です。<br>医療費の領収書や市町村から送られる明細を整理していただき、提出いただくだけで完了いたします。<br>（整理されていない場合はお受けできません）'"
        :tableTitle="'医療費控除を受けたい方の確定申告書の作成'"
        :price="'10,000円'"
      />

            <h3>■所得税の確定申告をされていない方（無申告）</h3>
<UiTable2
        :target="'確定申告をする必要があったにもかかわらず事情により確定申告をされていない無申告の方のが対象です。<br>記帳代行料も含んでおりますので領収書や通帳のコピー等の資料を提出いただくだけで完了いたします。<br>過去数年分を作る必要がある方、領収書等の資料を紛失されている方、対応いたします。<br>税務調査が入り、多額の延滞税等のペナルティが発生する前にお早めにご相談されることをお勧めします。'"
        :tableTitle="'無申告の場合の過去の確定申告書の作成'"
        :price="'100,000円～（1年分）<br>無申告期間が数年にわたる場合や特殊事情については別途見積もり'"
      />

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiTable1 from "@/components/Ui/Table1.vue";
import UiTable2 from "@/components/Ui/Table2.vue";
export default {
  name: "SpotRequest",
  components: {
    UiHeading,
    UiTable1,
    UiTable2,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--green);
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
