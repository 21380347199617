<template>
  <div class="bar-list">
              <UiBar3 :good="good"
      v-for="barData in barList3"
      :key="barData.id"
      :type="barData.type"
      :barText="barData.barText"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar3 from "@/components/Ui/Bar3.vue";
export default {
  name: "UiBar3List",
  components: {
    UiBar3
  },
  props: {
  barList3: Array,
  good: Boolean,
  },
  data() {
    return {      
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>


</style>