<template>
  <div class="blog-post space-100">
    <div>
      <div
        v-for="categoryData in informationList"
        :key="categoryData.categoryId"
      >
        <h2>{{ categoryData.catName }}</h2>
        <div class="wrap">
          <div class="blog-title-cover">
            <div
              v-for="postData in categoryData.postList"
              :key="postData.postId"
            >
              <ul>
                <li>
                  <router-link
                    :to="`/category/blog/${categoryData.catUrl}/${postData.postId}`"
                    >{{ postData.title }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "blogpost",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl", "informationList"]),
  },
  watch: {},
  created() {
    this.$axios
      .get(`${this.rootUrl}/api/get-posts.php`)
      .then((response) => {
        console.log(response);
        // alert(response.data.status);
        if (response.data.status === 200) {
          // alert(response.data.message);
          // console.log('ok')
          this.title = response.data.title;
          this.text = response.data.text;
          this.category_id = response.data.category_id;
          this.sort = response.data.sort;
          // this.pageType = 1;
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.$axios
      .get(`${this.rootUrl}/api/get-categories.php`)
      .then((response) => {
        console.log(response);
        // alert(response.data.status);
        if (response.data.status === 200) {
          // alert(response.data.message);
          // console.log('ok')
          this.name = response.data.name;
          this.slug = response.data.slug;
          this.sort = response.data.sort;
          // this.pageType = 1;
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: 20px 0;
  background-color: var(--green);
  color: white;
  margin: 20px 0;
  @media screen and (max-width: 767px){
    padding: 20px;
    line-height: 1.2em;
  }
}
.blog-title-cover {
  font-size: 20px;
  width: 70%;
  margin: 0 auto 50px auto;
  @media screen and (max-width: 767px){
    width: 100%;
  }
  ul {
    li {
      list-style: none;
      margin: 0;
      padding-right: 20px;
      padding: 15px 20px 15px 0;
      text-indent: -1.4em;
      margin-left: 1.4em;

      &::before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background-color: var(--green);
        border-radius: 50%;
        position: relative;
        top: 1px;
        margin-right: 8px;
      }

      a {
        text-decoration: none;
        color: var(--black);
        // display: inline-block;
        position: relative;
      }
      a:hover {
        cursor: pointer;
        color: var(--green);
      }
    }
  }
}
</style>