<template>
  <div class="Ui-DetailButton">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
      <div class="cover">
        <div
          class="button"
          v-scroll-to="{
            el: '#app',
            container: 'body',
            duration: 750,
            easing: 'ease',
            offset: 0,
          }"
        >
          <p>{{ text }}</p>
          <div class="arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiDetailButton",
  components: {},
  props: {
    text: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.typeA {
  .cover {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      display: flex;
      align-items: center;
      p {
        font-weight: bold;
      }
    }
    .arrow {
      position: relative;
      display: inline-block;
      padding-left: 20px;
    }
    .arrow::before {
      content: "";
      width: 15px;
      height: 15px;
      border: 0px;
      border-top: solid 4px var(--green);
      border-right: solid 4px var(--green);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
    }
  }
  a {
    text-decoration: none;
    color: var(---black);
  }
}
.typeB {
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 50px;
      border: 2px solid var(--green);
      color: var(--green);

      &:hover {
        background: var(--green);
        color: white;
        cursor: pointer;
      }
      p {
        font-weight: 400;
      }
    }
  }
}
.typeC {
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 50px;
      border: 2px solid var(--blue);
      color: varvar(--blue);

      &:hover {
        background: var(--blue);
        color: white;
        cursor: pointer;
      }
      p {
        font-weight: 400;
      }
    }
  }
}
</style>