<template>
  <div class="zeimukomon-troubles">
    <div class="wrap">
      <UiHeading :h="'2'" :type="'A'" :text="`こんな事業主様は、<br class='sp-kaigyo'>ぜひ山田雄大税理士事務所をご検討ください`" />
      <UiLine />

      <UiBar3List :barList3="barList3" :good="true"/>
    </div>
<div class="row">
      <UiHeading :h="'2'" :type="'B'" :text="'このような事業主様は、<br>ぜひ山田雄大税理士事務所をご検討ください!'"/>
</div>   

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBar3List from "@/components/Ui/Bar3List.vue";
export default {
  name: "zeimukomon-consider",
  components: {
    UiHeading,
    UiLine,
    UiBar3List,
  },
  data() {
    return {
      barList3:[
        {
          id: 1,
          type: "A",
          barText:
            "確定申告ができないので税理士に全部任せたい",
        },
        {
          id: 2,
          type: "B",
          barText:
            "記帳代行を丸投げしたいがコストは押さえたい",
        },
        {
          id: 3,
          type: "A",
          barText:
            "なるべく顧問料を抑えたいので様々な提案をしてほしい",
        },
        {
          id: 4,
          type: "B",
          barText:
            "生活と事業が密着しているような小規模の事業者に強い税理士と付きあいたい",
        },
        {
          id: 5,
          type: "A",
          barText:
            "若い税理士に直接担当してほしい",
        },
        {
          id: 6,
          type: "B",
          barText:
            "いつでも気軽に税金や経理、お金の相談をしたい",
        },
        {
          id: 7,
          type: "A",
          barText:
            "パソコンやスマホが得意で、効率のいい税務サポートをしてほしい",
        },
        {
          id: 8,
          type: "B",
          barText:
            "ITが苦手でなので、これからの時代に合った新しいやり方を覚えたい",
        },
        {
          id: 9,
          type: "A",
          barText:
            "年商が少ないが今後事業拡大を考えている",
        },
        {
          id: 10,
          type: "B",
          barText:
            "税理士との間に上下関係が無く、気軽に相談でき、フラットに付き合いたい",
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

.request-box {
  display: flex;
  justify-content: space-between;
  .photo-cover {
    width: 50%;
    img {
      vertical-align: bottom;
      width: 100%;
    }
    .name {
      background-color: var(--black);
      color: white;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 0;

      span {
        margin-left: 10px;
      }
    }
  }
  p{
    width: 48%;
  }
}
.row{
  line-height: 2.7em;
  background-color: var(--green);
  padding: 50px 0;
  margin-top: 150px;
   @media screen and (max-width: 767px){
     line-height: 2.0em; 
       margin-top: 100px;    
   }
  }

</style>
