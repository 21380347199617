<template>
  <div class="layout-header">
    <div class="header">
      <div class="header-left">
        <div class="to-top" @click="top">
          <img
            :src="`${rootUrl}/img/yamada-yudai-logo01.png`"
            alt="ロゴ"
            class="logo"
          />
        </div>
      </div>

      <div class="header-right">
        <div class="tel-hours">
          <p class="tel-text">お気軽にお問い合わせください</p>
            <p class="tel"><span>052-700-3238</span><span class="hour">（平日：9時～17時）</span></p>
            
        </div>

        <div class="mail-contact">
          <router-link to="/contact">
            <img
              :src="`${rootUrl}/img/yamada-mail.png`"
              alt="メール問い合わせ"
            />
            <span>お問い合わせ</span></router-link
          >
        </div>
        <!-- <div class="line-contact">
          <a href="" target="_blank" rel="noopener noreferrer">
            <img :src="`${rootUrl}/img/yamada-yudai-Line.png`" alt="LINE" />
            <span>LINE</span></a
          >
        </div> -->

        <div class="hamburger">
          <UiDrawer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  position: fixed;
  z-index: 999;
  background-color: white;

  @media screen and (max-width: 767px) {
    height: 60px;
  }

  .header-left {
    width: 400px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px) and(max-width: 1150px){
      width: 300px;
    }
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-left: 5px;
        
      }

    .to-top {
      cursor: pointer;
    }
    .to-top:hover {
      opacity: 0.8;
    }
  }

  .header-right {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    @media screen and (max-width: 767px) {
      width: 30%;
      justify-content: flex-end;
      padding-right: 10px;
    }

          a {
            text-decoration: none;
            color: var(--black);
        }

        img {
            width: 35px;
            margin-right: 3px;
        }


    
    .tel-hours {
      color: var(--green);
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) and (max-width: 979px){
        width: 35%;
        margin-left: 35px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
      span{
        display: inline-block;
        vertical-align: middle;
      }

        .tel {
          font-size: 25px;
          vertical-align: middle;
        }
        .hour {
          font-size: 16px;
          @media screen and (min-width: 768px) and (max-width: 979px){
           display: none;
          }
        }
        .tel-text{
          @media screen and (min-width: 768px) and (max-width: 979px) {
            font-size: 11px;
            // margin-left: 10px;
          }
        }
      
    }

    .mail-contact,
    .line-contact {
      display: inline-block;
      transition: 0.3s;
      -webkit-transform: scale(1);
      transform: scale(1);

      span,img{
        vertical-align: middle;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .mail-contact:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    .line-contact {
      white-space: nowrap;
    }

    .line-contact:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    .hamburger{
      margin-right: 30px;
       @media screen and (min-width: 768px) and (max-width: 979px){
         margin-right: 10px;
       }
       @media screen and (max-width: 767px){
          margin-right: 5px;
       }
    }
  }
}
</style>
