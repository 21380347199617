<template>
  <div class="home-reason">
    <UiHeading
      :h="h"
      :type="'A'"
      :text="`山田雄大税理士事務所が<br class='sp-kaigyo'>選ばれる理由`"
    />
    <UiLine />

    <div class="reason-box">
    <div class="wrap" v-show="noteShow" style="text-align: center;">
          <p>このページでは、山田雄大税理士事務所が選ばれる理由について、詳しく説明しております。</p>
          <p>※クリックすると、詳細を見ることができます。</p>
    </div>
      <UiBarList :barList="barList"/>
    </div>

    <UiDetailButton
      v-show="button"
      :type="'B'"
      :path="`/company/vision/`"
      :text="'詳細を見てみる'"
      style="margin: 50px 0;"
    />


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiBarList from "@/components/Ui/BarList.vue";
import UiDetailButton from "../Ui/DetailButton.vue";
// import UiBox from "@/components/Ui/Box.vue";

export default {
  name: "HomeReason",
  components: {
    UiHeading,
    UiLine,
    UiBarList,
    UiDetailButton,
    // UiBox,
  },
  props:{
    button:Boolean,
    noteShow:Boolean,
    h:String, 
  },
  data() {
    return {
      barList: [
        {
          id: 1,
          type: "A",
          barNo: "01",
          barText:
            "年商2億円未満の小規模事業者に特化するからこそ可能なサポート力",
          jumpTo:"#reasonNo1",
        },
        {
          id: 2,
          type: "B",
          barNo: "02",
          barText:
            "税理士資格を保有する30代の所長が、連絡、相談を担当",
            jumpTo:"#reasonNo2",
        },
        {
          id: 3,
          type: "A",
          barNo: "03",
          barText:
            "お客様の事業内容や要望に合わせた、最適かつ柔軟な料金設定",
            jumpTo:"#reasonNo3",
        },
        {
          id: 4,
          type: "B",
          barNo: "04",
          barText:
            "あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）を中心とした地域密着型の迅速な対応",
            jumpTo:"#reasonNo4",
        },
        {
          id: 5,
          type: "A",
          barNo: "05",
          barText:
            "開業、設立、税理士変更などを全面的にバックアップし、本業に集中できるサポート力",
            jumpTo:"#reasonNo5",
        },
        {
          id: 6,
          type: "B",
          barNo: "06",
          barText:
            "クラウド会計の完全対応や最新のITを用いてお客様の負担を徹底的に軽減",
            jumpTo:"#reasonNo6",
        },
        {
          id: 7,
          type: "A",
          barNo: "07",
          barText:
            "元税務署職員だからこそできるリアルな税務対応",
            jumpTo:"#reasonNo7",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.service-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
