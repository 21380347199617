<template>
  <div class="ctrl-change-password space-100 wrap">
    <div>
      <el-row>
        <el-col><h1>パスワード変更</h1></el-col>
      </el-row>

    <div class="back-bottun">
          <el-button type="warning" plain @click="back"
            >戻る</el-button>
    </div>
      <div class="change-password-password-editor">
        <div class="pass-cover">
          <div class="pass-item">
            <el-input type="password" placeholder="" v-model="oldPass">
              <template slot="prepend">旧パスワード</template>
            </el-input>
          </div>
        </div>

        <div class="confirm">
          <div class="pass-cover">
            <div class="pass-item">
              <el-input type="password" placeholder="" v-model="newPass">
                <template slot="prepend">新パスワード</template>
              </el-input>
            </div>
          </div>
          <div class="pass-cover">
            <div class="pass-item">
              <el-input type="password" v-model="newPass2">
                <template slot="prepend">新パスワード（確認用）</template>
              </el-input>
            </div>
          </div>
        </div>

 
            <div @click="change" class="change-button">
              <el-button type="primary">変更</el-button>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrlCategory",
  components: {},
  props: {},
  data() {
    return { oldPass: "", newPass: "", newPass2: "" };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    change() {
      let params = new URLSearchParams();
      params.append("old_pass", this.oldPass);
      params.append("new_pass", this.newPass);
      params.append("new_pass2", this.newPass2);
      this.$axios
        .post(`${this.rootUrl}/api/changePassword.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            alert("パスワードを変更しました。");
            this.$router.go(-1);
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            if (res.data.status2 === 1) {
              //no action.
            } else {
              this.$router.push(`/ctrl/login`);
            }
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
.back-bottun{
  padding: 20px 50px;
}
.el-col {
  text-align: left;
}
.confirm {
  /deep/.el-input-group__prepend {
    background-color: #e9f4ff;
  }
}
/deep/.el-input-group__prepend {
  @media screen and (max-width: 787px) {
    font-size: 12px;
    padding: 0 5px;
    //  min-width: 120px;
  }
}
.pass-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  .pass-item {
    width: 90%;
    max-width: 900px;
    margin-bottom: 10px;
    @media screen and (max-width: 787px) {
      width: 100%;
    }
  }
}
.change-button{
  display: flex;
  justify-content: center;
  padding: 50px 0;
}
</style>