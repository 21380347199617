<template>
  <div class="company-vision-reason">
    <UiBar
      :barNo="'01'"
      :type="'A'"
      :barText="'年商2億円未満の小規模事業者に特化するからこそできるサポート力'"
    />
    <div class="description-area wrap">
      <p>
        小規模事業者だからこそ税理士事務所がやるべきこと、やれることがあり、それが事業者の生活に直結します。当事務所は小規模事業者に特化することで小規模事業者に合ったノウハウを提供することができます。年商200万円前後の方ともお付き合いさせていただいております。税理士に頼んでもいいのかな、頼むメリットはあるのかな、とお考えの方はどうぞお気軽にお問い合わせください。
      </p>

      <h3 class="blue">小規模事業者に特化している理由</h3>
      <p class="bottom-0">
        当事務所小規模事業者に特化しているのは以下の理由からです。
      </p>
      <ol class="blue number">
        <li>一人でも多くの人の事業のサポートをしたい</li>
        <li>事業をされている方と直接やり取りをしたい</li>
        <li>会社設立（法人化）など、事業を大きくするためのお手伝いをしたい</li>
        <li>小規模事業者は税金が生活に直結しているからやりがいがある</li>
      </ol>

      <p>
        税理士事務所の仕事は、人と人とのお付き合いであると考えています。機械的なお付き合いではなく、業務のステージに合わせて適切なアドバイスとサポートができるように、しっかりとコミュニケーションをとり、皆様の大切な「お金」というものを目に見えるようにすることを約束いたします。
      </p>

      <h3 class="blue">近年の小規模事業者の税金は危険がいっぱい</h3>
      <p>
        スモールビジネスや副業、ＦＸや仮想通貨といった投資もスマートフォン一台あれば始められるようになりました。「少しだけ○○の収入があるのですが確定申告をしたほうがいいですか」、そんな質問を最近頻繁にお問い合わせいただきます。
      </p>
      <p>
        もちろん一定の収入がある場合には確定申告をして税金を納める必要がありますが、それを知らずに税金を納めていない方が多くいらっしゃいます。
      </p>
      <p>
        税務署は基本的に全ての人の収入を把握できます。税務調査になり、税金を納めていないことが発覚した場合、確定申告をして税金を納めてください、と指導されます。
      </p>
      <p>
        遅れて確定申告をすると延滞税や加算税といったペナルティが課せられます。最近ではこのような小規模事業者に対する税務署の調査が厳しくなっています。実際に収入があるにも関わらず税金を納めていない人が大勢いるからです。放置し、突然税務署から連絡が来て、何とかしてほしい、といったご相談も増えています。
      </p>
      <p>
        まずはご自身が確定申告する必要があるかをぜひご相談ください。ご自身の納税の義務をしっかりと把握し、リスクを少しでも減らしましょう。
      </p>

      <p class="bottom-0" style="color: var(--blue)">
        当事務所が考えるスモールビジネスの一覧
      </p>
      <div class="small-business">
        <div class="business">
            <li>ネット通販</li>
            <li>せどり</li>
            <li>ウーバーイーツ</li>
            <li>ＳＮＳ等の広告収入</li>
            <li>アフィリエイト収入</li>
            <li>宅配便・軽貨物</li>
            <li>ＹｏｕＴｕｂｅｒ</li>
    
        </div>
        <div class="business2">

            <li>クラウドワークスやランサーズでの収入</li>
            <li>イラストレーター</li>
            <li>プログラミング</li>
            <li>文字起こし</li>
            <li>翻訳</li>
            <li>作曲</li>

        </div>
        <div class="business">

            <li>ハンドメイド雑貨等の販売</li>
            <li>絵画等の自作物の販売</li>
            <li>セミナー講師</li>
            <li>家庭教師</li>
            <li>習い事の講師</li>
            <li>野菜等の食品の販売</li>
        </div>
      </div>
    </div>
    <UiMiniToTopButton :type="'C'" :text="'上に戻る'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar from "@/components/Ui/Bar.vue";
import UiMiniToTopButton from "@/components/Ui/MiniToTopButton.vue";

export default {
  name: "CompanyVisionReason",
  components: {
    UiBar,
    UiMiniToTopButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.small-business {
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--blue);
  padding: 20px 0 20px 40px;
  li{
    margin-left: 1.4em;
    text-indent: -1.4em;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px 0;
  }

  .business {
    width: 28%;
    // padding: 20px 0 20px 40px;
    padding: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
    }
  }
  .business2 {
    width: 34%;
    padding: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
    }
  }
}
</style>
