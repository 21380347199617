<template>
  <div class="ctrl">
    <CtrlCtrlPost />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlCtrlPost from "@/components/Ctrl/Post.vue";
export default {
  name: "CtrlPost",
  components: {
    CtrlCtrlPost,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
