<template>
  <div class="zeimukomon-price">
      <UiHeading
        :h="'1'"
        :type="'A'"
        :text="'月額顧問料の料金表'"
      />
      <UiLine />

<ZeimukomonPriceCorporationPrice/>
<ZeimukomonPriceProprietorshipPrice class="space-100"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import ZeimukomonPriceCorporationPrice from "@/components/Zeimukomon/Price/CorporationPrice.vue";
import ZeimukomonPriceProprietorshipPrice from "@/components/Zeimukomon/Price/ProprietorshipPrice.vue";
export default {
  name: "zeimukomon-price",
  components: {
    UiHeading,
    UiLine,
    ZeimukomonPriceCorporationPrice,
   ZeimukomonPriceProprietorshipPrice,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.request-box {
  display: flex;
  justify-content: space-between;
  .photo-cover {
    width: 50%;
    img {
      vertical-align: bottom;
      width: 100%;
    }
    .name {
      background-color: var(--black);
      color: white;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 10px 0;

      span {
        margin-left: 10px;
      }
    }
  }
  p {
    width: 48%;
  }
}
</style>
