<template>
  <div class="home-aboutus">
    <UiHeading :h="'1'" :type="'A'" :text="`山田雄大税理士事務所に<br class='sp-kaigyo'> ついて`" />
    <UiLine />
    <div class="about-us-box">
      <img
        :src="`${rootUrl}/img/yamada-yudai-top01.jpg`"
        alt="事務所について"
        class="left"
      />
      <div class="right">
        <div class="text">
          <p>
           山田雄大税理士事務所は、あま市とその近隣エリア（稲沢市・清須市・津島市・愛西市・大治町・蟹江町・北名古屋市・弥富市）の年商２億円未満の小規模事業主を専門とした税理士事務所です。小規模事業者を専門とすることで、事業内容とご要望を的確に把握し、最適な料金をご提案し、きめ細かな税務対応をお約束します。会社設立と個人事業の開業をされる方のサポートにも力を入れています。当事務所の方針として、税理士である私がすべてのお客様の担当をしております。
          </p>
        </div>
        <div class="philosophy">
          <div class="cover">
            <ul class="sub">
              <li>山田雄大税理士事務所の事業理念</li>
            </ul>
            <div class="list-box">
              <ul>
                <li>年商2億円未満の小規模事業者に特化したきめ細かな対応</li>
                <li>
                  税理士資格を保有する30代の所長が対応・相談を担当
                </li>
                <li>お客様の事業内容や要望に合わせた最適かつ柔軟な料金設定</li>
                <li>
                  稲沢市とその隣接エリアを中心とした地域密着型の会って話せる税理士
                </li>
                <li>
                  開業、会社設立を全面的にバックアップし、本業に集中できるサポート力
                </li>
                <li>
                  クラウド会計の完全対応や最新のITを用いてお客様の負担を徹底的に軽減
                </li>
                <li>元税務署職員だからこそできるリアルな税務対応</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "HomeAboutus",
  components: {
    UiHeading,
    UiLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.about-us-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }

  .left {
    width: 48%;
    @media screen and (max-width: 767px){
      width: 100%;
    }
  }
  .right {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 30px;
    }

    .text {
    flex-basis: 38%;
    padding-bottom: 30px;
    }

    .philosophy {
      flex-basis: 60%;
      border-left: solid 6px var(--green);
        @media screen and (max-width: 767px){
          margin-top: 30px;
        }
      .cover {
        padding-left: 20px;
        .sub {
          color: var(--green);
          font-size: 18px;
          font-weight: 500;
          list-style-type: none;
          li:before {
            content: "■";
            width: 6px;
            height: 6px;
            margin-right: 4px;
          }
        }
        .list-box {
          margin-left: 22px;
          ul {
            list-style-type: disc;
          }
        }
      }
    }
  }
}
</style>
