<template>
  <div class="list-list">
              <UiList
      v-for="listData in ListItem"
      :key="listData.id"
      :type="listData.type"
      :listCategory="listData.listCategory"
      :listDetail="listData.listDetail"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiList from "@/components/Ui/List.vue";
export default {
  name: "UiBarList",
  components: {
    UiList
  },
  props: {
  ListItem: Array,
  },
  data() {
    return {      
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>


</style>