<template>
  <div class="home-service">
    <UiHeading :h="'2'" :type="'A'" :text="'お客様の声'" />
    <UiLine />
    <!-- <UiVoiceBox :fileNo="'1'" :companyText="'株式会社 &emsp;ケイアール<br />代表取締役&emsp;佐藤勇樹&emsp;様'" :imgName="'ケイアール様'" :imgpath="'okyakusama004.jpg'" :typeText="'建設業(個人事業主→法人成り)'" :detailText="' 山田先生とは個人事業でお仕事をしている時から顧問していただいています。<br />法人化する際には、税金の事以外にも行政書士の先生や会社のロゴなどを作成して下さる会社様を紹介していただくなど色々な面でサポートをしていただいています。<br />業務の面では書類の管理方法を教えて頂けた事により仕事の効率が格段に上がりました。<br />また、領収書などの書類はOneDriveで共有する事によりスムーズに提出が出来るようになりました。<br />頼ってばっかりですが今後ともよろしくお願い致します(^^)'"/> -->
    
    <UiVoiceBox :fileNo="'1'" :companyText="'株式会社 &emsp;素<br />代表取締役&emsp;鈴木隆太&emsp;様'" :imgName="'インターネット通販業（法人）'" :imgpath="'okyakusama001.jpg'" :typeText="'インターネット通販業（法人）'" :detailText="'知人から山田先生を紹介していただきました。<br>第一印象は、背が高く体育会系といった感じです。<br>こちらの話を真摯に聴いてくださるだけではなく、例えば趣味など、山田先生ご自身の話もしてくださります。こちらが話しやすくなるさりげない配慮が、相談のしやすさに繋がっているのだなと感じました。<br>ビジネスを始めたばかりや、事業が大きくなるにつれ、効果的に節税するにはどうすればいいのかなど疑問点が出てくると思いますが、そんな時に相談しやすい環境があるのは心強いです。<br>会計資料のやり取りはデータで、クラウドを介して行うので、ネット社会の昨今においてとても便利だと思います。紙の会計資料は簡単にデータ化でき、事業でも必要書類をデータ化して整理整頓できるようになったので助かっています。相見積もりを取るとわかりますが、顧問料も良心的です。<br>前任の税理士から変更という形で山田先生にお世話になることとなりましたが、山田先生にお願いできて良かったと感じております。'"/>
    <UiVoiceBox :fileNo="'2'" :companyText="'株式会社 &emsp;ISO<br>代表取締役&emsp;礒貝昌和&emsp;様'" :imgName="'礒貝昌和様'" :imgpath="'okyakusama002.jpg'" :typeText="'ハンバーガー店経営(個人事業主→法人成り)'" :detailText="'山田先生には、5年前に個人事業主として、名古屋でハンバーガー店を開業した時からずっとお世話になっています。<br>事業を始めた頃は、税金や確定申告の事など、本当に分からない事だらけでしたが、簡潔に分かりやすく説明していただきました。確定申告も安心してお任せ出来ました。悩んだ時も励まして頂き、夫婦で前向きに頑張って来られたと思います。<br>そんな私達も、この度法人成りすることになりました。法人化するかどうか、正直な所、大変悩みました。度々山田先生に相談しました。<br>社会保険や法人税、支払いは増えますが、私たちが今後どのような経営をして行きたいかをお伝えし、法人化してから税金の支払いのイメージなどをご教示頂きました。<br>お忙しい中でも、とても親身になって聞いてくださいました。そして、しっかり納得して法人化を決める事が出来ました。<br>山田先生には、これから会社を繁栄させていく中でも、心強いパートナーでいていただきたいと思っています。今後とも、宜しくお願い致します。'"/>
    <UiVoiceBox :fileNo="'3'" :companyText="'彩テック<br />日置稔一&emsp;様'" :imgName="'日置稔一様'" :imgpath="'okyakusama003.jpg'" :typeText="'塗装業（個人事業主）'" :detailText="'インターネットで税理士事務所を検索して山田先生を知り、問い合わせをしたのが顧問してもらったきっかけです。<br>個人事業の開業当初、私は税金についての知識が全くなく、不安に思っていましたが、一から分かりやすく説明していただき 立場にあった対応をして下さいます。また、税金以外の事でも親身に相談に乗っていただいています。<br>顧問料も相談の上決めることができ、安くしていただいていると感じており、大変助かっています。<br>これからも税金やお金のことを安心して山田先生にお任せできるので、塗装業に専念してやっていけそうです。<br>感謝しています。'"/>
 
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiLine from "@/components/Ui/Line.vue";
import UiVoiceBox from "@/components/Ui/VoiceBox.vue";

export default {
  name: "HomeVoice",
  components: {
    UiHeading,
    UiLine,
    UiVoiceBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
