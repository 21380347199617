<template>
  <div class="ctrl-post-detail space-100 wrap">
    <div>
      <el-row>
        <el-col>
          <!-- <h1>{{ postId }}</h1> -->
          <h1>記事編集</h1>
          </el-col
        >
      </el-row>

      <el-row>
        <el-col>
          <h3>【カテゴリ】</h3>
          <el-select v-model="postData.categoryId" placeholder="カテゴリ選択">
            <el-option
              v-for="categoryData in categoryList"
              :key="categoryData.categoryId"
              :label="categoryData.catName"
              :value="categoryData.categoryId"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <h3>【タイトル】</h3>
          <el-input
            placeholder="記事のタイトルを入力してください。"
            v-model="postData.title"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <h3>【本文】</h3>
        
        <TinyMceEditor :text.sync="postData.text"/>
          
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-col>
          <h3>【ソート順】</h3>
          <el-input
            placeholder="Please input"
            v-model="postData.sort"
          ></el-input>
        </el-col>
      </el-row> -->
    </div>

    <el-row class="back-button">
      <el-col :span="2" :offset="11" style="text-alghn: center"
        ><el-button type="danger" plain @click="register" class="toukou"
          >投稿</el-button
        ></el-col
      >
    </el-row>
    <el-row class="back-button">
      <el-col :span="4"
        ><el-button type="warning" plain @click="back">戻る</el-button></el-col
      >
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TinyMceEditor  from "@/components/Ui/TinyMceEditor.vue";
export default {
  name: "ctrlPostDetail",
  components: { 
    TinyMceEditor
     },
  props: {
    postId: String,
    categoryId: String,
  },
  data() {
    return {
      postData: {},
      // text:"",
      // categoryData: {},
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "categoryList"]),
  },
  watch: {
    postId: {
      handler(newValue) {
        this.postData = JSON.parse(
          JSON.stringify(this.$store.getters["postData"](newValue))
        );
      },
      immediate: true,
    },
    // categoryId: {
    //   handler(newValue) {
    //     this.categoryData = JSON.parse(
    //       JSON.stringify(this.$store.getters["categoryData"](newValue))
    //     );
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    register() {
      let params = new FormData();
      console.log(this.postData.text);
      // let params = new URLSearchParams();
      params.append("post_id", this.postId);
      params.append("title", this.postData.title);
      params.append("text", this.postData.text);
      params.append("category_id", this.postData.categoryId);
      params.append("sort", this.postData.sort);
      this.$axios
        .post(`${this.rootUrl}/api/reg-post.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            this.$store.dispatch("setPostList");
            alert(response.data.message);
            // console.log('ok')
            this.$router.go(-1);
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 26px;
}
h3 {
  margin-top: 40px;
  margin-bottom: 10px;
}
.back-button {
  margin: 50px 0;
}
.toukou {
  width: 150px;
}
</style>