<template>
  <div class="table">
    <table>
      <tr>
        <th class="title" colspan="3">{{tableTitle}}</th>
      </tr>
      <tr>
        <th class="vertical"><span>対象の方・お仕事の詳細</span></th>
        <td colspan="2" v-html="target" class="target">
          
        </td>
      </tr>
      <tr>
        <th rowspan="15" class="vertical"><span>料金（全て税別）</span></th>
        <td colspan="2" class="sub">基本料金</td>
      </tr>
      <tr>
        <td class="condition">500万円以下</td>
        <td class="price">20,000円</td>
      </tr>
      <tr>
        <td class="condition">1,000万円以下</td>
        <td class="price">30,000円</td>
      </tr>
      <tr>
        <td class="condition">2,000万円以下</td>
        <td class="price">40,000円</td>
      </tr>
      <tr>
        <td class="condition">3,000万円以下</td>
        <td class="price">50,000円</td>
      </tr>
      <tr>
        <td class="condition">5,000万円以下</td>
        <td class="price">60,000円</td>
      </tr>
      <tr>
        <td class="condition">7,000万円以下</td>
        <td class="price">70,000円</td>
      </tr>
      <tr>
        <td class="condition">1億円以下</td>
        <td class="price">80,000円</td>
      </tr>
            <tr>
        <td colspan="2" class="sub">特例の追加料金</td>
      </tr>
      <tr>
        <td class="condition">住宅取得資金贈与</td>
        <td class="price">20,000円</td>
      </tr>
      <tr>
        <td class="condition">配偶者控除</td>
        <td class="price">20,000円</td>
      </tr>
      <tr>
        <td class="condition">土地の評価<br class="sp-kaigyo">（一物件につき）</td>
        <td class="price">30,000円</td>
      </tr>
      <tr>
        <td class="condition">上場株式</td>
        <td class="price">10,000円×会社数</td>
      </tr>
      <tr>
        <td class="condition">非上場株式</td>
        <td class="price">別途見積もり</td>
      </tr>
      <tr>
        <td class="condition">相続時精<br class="sp-kaigyo">算課税手続き</td>
        <td class="price">別途見積もり</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Uitable3",
  components: {},
  props: {
    tableTitle:String,
    target:String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  margin: 50px 0;
  // table-layout: fixed;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: var(--green) solid 1px;
      @media screen and (max-width: 767px){
        display: block;

      }
    }
    th {
      background-color: rgb(223, 223, 223);
      color: var(--green);
      text-align: center;
      vertical-align: middle;
      width: 22%;
      font-size: 18px;
       @media screen and (max-width: 767px){
         width: 100%;
       }
    }
    // .vertical{
    //   @media screen and (max-width: 767px){
    //     // min-height: 8em;
    //     span{
    //     -ms-writing-mode: tb-rl;
    //     -webkit-writing-mode: vertical-rl;
    //     writing-mode: vertical-rl;
    //     }

    //   }
    // }
    td {
      padding: 10px 0 10px 15px;
      width: 39%;
      @media screen and (max-width: 767px){
         width: 100%;
                 text-align: left;
       }

    }
  }
  .title {
    background-color: var(--green);
    width: 100%;
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .price {
    text-align: right;
    padding-left: 0;
    padding-right: 20px;
  border-left: none;
        @media screen and (max-width: 767px){
          vertical-align: middle;
          border-left: var(--green) solid 1px;
          // text-align: center;
      }
}
.condition{
  border-right: none;
          @media screen and (max-width: 767px){
          vertical-align: middle;
          border-right: var(--green) solid 1px;
          // text-align: center;
      }
}
.target{
  padding: 20px; 
}
.sub{
        background-color: #cbe9e9;
      // color: var(--green);
      text-align: center;
      vertical-align: middle;
}
}
</style>