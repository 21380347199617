<template>
  <div class="ui-drawer">
    <div class="back-gray"
    :class="{ 'back-gray-show': openDrawer === true}"
    @click="switchDrawer">
    </div>
    <img
      @click="switchDrawer"
      :src="`${rootUrl}/img/drawer.png`"
      class="drawer-button"
      alt="ドロワーメニュー"
    />
    <div
      class="drawer-menu"
      :class="{
        'open-drawer': openDrawer === true,
        'close-drawer': openDrawer === false,
      }"
    >
      <div class="drawer-header">
        <img
          @click="switchDrawer"
          :src="`${rootUrl}/img/close.png`"
          class="close-button"
          alt="閉じるドロワーメニュー"
        />

      </div>
      <div class="drawer-body" @click="switchDrawer">
        <div class="drawer-item">
          <router-link to="/">トップ</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/company/">事務所・税理士紹介</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/company/vision/">選ばれる理由</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/kaigyo/">会社設立・開業</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/zeirishi-henko/">税理士の変更</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/spot/">スポットでのお仕事</router-link>
        </div>
        <!-- <div class="drawer-item">
          <router-link to="/category/blog/">お役立ち情報</router-link>
        </div> -->
        <div class="drawer-item">
          <router-link to="/partner/">提携業者の紹介</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/seminar/">セミナーの依頼</router-link>
        </div>
        <!-- <div class="drawer-item">
          <router-link to="/recruit/">求人・採用</router-link>
        </div> -->
        <div class="drawer-item">
          <router-link to="/contact/">お問い合わせ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 230px;
.drawer-button {
  width: 38px;
  cursor: pointer;
  @media screen and (max-width: 767px){
    width: 40px;
  }
}
.drawer-menu {
  transition: all 1s 0s ease;
  position: fixed;
  top: 0;
  background-color: white;
  padding: 5px 10px 50px 10px;
  box-sizing: border-box;
  z-index: 999;
  width: $width;
  .drawer-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    img.close-button {
      width: 20px;
      margin: 20px;
      cursor: pointer;
    }
  }
  .drawer-body {
    // background-color: var(--beige);
    .drawer-item {
      text-align: initial;
        border-top: 1px solid var(--green);
      
      padding: 18px 10px;
     
      &:last-child {
        border-bottom: 1px solid var(--green);
      }
      a {
        text-decoration: none;
        color: var(--black);
         &:hover{
          // background-color: var(--beige);
          // font-weight: bold;
          color: var(--green);
        }
      }
    }
  }
}
.open-drawer {
  transform: translateX(-$width);
  // right: translateX(-$width);
  right: -$width;
}
.close-drawer {
  transform: translateX($width);
  right: -$width;
  // right: 0-$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 995;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color:gray;
  opacity: 0.4;
}
.back-gray-show {
  display: initial;
}
</style>
